import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OtpInputComponent),
      multi: true
    }
  ]
})

export class OtpInputComponent implements ControlValueAccessor {
  @Input() public value: string;
  public otp: string = '';
  public input1Value: string = '';
  public input2Value: string = '';
  public input3Value: string = '';
  public input4Value: string = '';
  public input5Value: string = '';
  public input6Value: string = '';

  private onChange: (value: string) => void;
  private onTouched: () => void;

  public writeValue(value: string): void {
    this.value = value;
  }

  public registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  public setDisabledState(isDisabled: boolean): void { }

  onKeyUp(inputIndex: number, event: any) {
    const value = event.target.value;

    switch (inputIndex) {
      case 1:
        this.input1Value = value;
        if (value) { this.focusInput(2); }
        break;
      case 2:
        this.input2Value = value;
        this.focusInput(value ? 3 : 1);
        break;
      case 3:
        this.input3Value = value;
        this.focusInput(value ? 4 : 2);
        break;
      case 4:
        this.input4Value = value;
        this.focusInput(value ? 5 : 3);
        break;
      case 5:
        this.input5Value = value;
        this.focusInput(value ? 6 : 4);
        break;
      case 6:
        this.input6Value = value;
        if (!value) { this.focusInput(5); }
        break;
      default:
        break;
    }

    this.otp = this.input1Value + this.input2Value + this.input3Value + this.input4Value + this.input5Value + this.input6Value;

    this.onChange(this.otp);
  }


  focusInput(inputIndex: number) {
    const input = document.getElementById(`otp-input-${inputIndex}`);
    if (input) {
      input.focus();
    }
  }
}