<div class="card card-outline card-primary">
    <div class="card-header text-center">
        <a [routerLink]="['/']" class="h1"><img src="../../../assets/img/logo.png" /></a>
    </div>
    <div class="card-body">
        <p class="login-box-msg">
            Enter the <b>OTP</b> sent to your email
        </p>
        <form [formGroup]="otpPinForm" (ngSubmit)="submitOTP()">
            <div class="form-group text-center">
                <app-otp-input formControlName="OTPCode"></app-otp-input>
            </div>
            <p class="login-box-msg" *ngIf="!resendEnabled">
                Resend OTP in &nbsp;<b>{{ display }}</b>
            </p>
            <div class="row">
                <div class="col-12">
                    <button class="btn btn-success col-12" type="submit">Verify</button>
                </div>
                <div class="col-12 mt-2">
                    <button class="btn btn-primary col-12" (click)="resendOTP()" *ngIf="resendEnabled || loading">
                        <span *ngIf="!loading">Re-Send OTP</span>
                        <span *ngIf="loading">Please wait...</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
