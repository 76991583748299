import { Component, HostBinding, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '@services/api.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'login-box';
    public forgotPasswordForm: UntypedFormGroup;
    public isAuthLoading = false;
    loading!: boolean;
    isInputDetected: boolean = false;

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private apiService: ApiService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.renderer.addClass(
            document.querySelector('app-root'),
            'login-page'
        );
        this.forgotPasswordForm = new UntypedFormGroup({
            userName: new UntypedFormControl(null, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
        });
        this.aoiField();
    }

    aoiField() {
        // Activate or inactivate fields.
        this.isInputDetected = this.forgotPasswordForm.value.email != null && this.forgotPasswordForm.value.email.length > 0 ? true : false;
    }

    async forgotPassword() {
        this.forgotPasswordForm.markAllAsTouched();
        if (this.forgotPasswordForm.valid) {
            this.loading = true;
            await this.apiService.requestForgotPassword(this.forgotPasswordForm.value).subscribe((result: any) => {
                this.loading = false;
                if (result.responseCode == "200") {
                    this.toastr.success(result.responseMessage)
                    this.router.navigate(['/']);
                } else {
                    this.toastr.error(result.responseMessage)
                }
            });
        } else {
            this.loading = false;
            this.toastr.error("Please provide your email address.");
        }
    }

    ngOnDestroy(): void {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'login-page'
        );
    }
}
