import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  notifications =
    [
      { type: "info", title: "Info!", message: "Your request was completed successfully.", date: "2021-5-18 17:31:37", status: "Error" },
      { type: "info", title: "Info!", message: "Your request was completed successfully.", date: "2023-11-16 22:37:43", status: "Seen" },
      { type: "danger", title: "Danger!", message: "Your request was completed successfully.", date: "2022-4-17 16:30:36", status: "Not Seen" },
      { type: "info", title: "Info!", message: "Your request was completed successfully.", date: "2021-7-18 16:33:39", status: "Error" },
      { type: "info", title: "Info!", message: "Your request was completed successfully.", date: "2021-7-18 21:33:39", status: "Error" },
      { type: "success", title: "Success!", message: "Your request was completed successfully.", date: "2022-9-17 19:35:41", status: "Not Seen" },
      { type: "error", title: "Error!", message: "Your request was completed successfully.", date: "2022-7-17 20:33:39", status: "Not Seen" },
      { type: "warning", title: "warning!", message: "Your request was completed successfully.", date: "2023-4-16 20:30:36", status: "Seen" },
      { type: "warning", title: "warning!", message: "Your request was completed successfully.", date: "2023-11-16 19:37:43", status: "Seen" },
      { type: "danger", title: "Danger!", message: "Your request was completed successfully.", date: "2021-9-18 19:35:41", status: "Error" },
      { type: "danger", title: "Danger!", message: "Your request was completed successfully.", date: "2021-7-18 19:33:39", status: "Error" },
      { type: "warning", title: "warning!", message: "Your request was completed successfully.", date: "2023-4-16 16:30:36", status: "Seen" },
      { type: "warning", title: "warning!", message: "Your request was completed successfully.", date: "2022-3-17 20:29:35", status: "Not Seen" },
      { type: "info", title: "Info!", message: "Your request was completed successfully.", date: "2023-8-16 23:34:40", status: "Seen" },
      { type: "danger", title: "Danger!", message: "Your request was completed successfully.", date: "2023-3-16 17:29:35", status: "Seen" },
      { type: "error", title: "Error!", message: "Your request was completed successfully.", date: "2022-7-17 19:33:39", status: "Not Seen" },
      { type: "warning", title: "warning!", message: "Your request was completed successfully.", date: "2023-12-16 21:38:44", status: "Seen" },
      { type: "error", title: "Error!", message: "Your request was completed successfully.", date: "2022-11-17 17:37:43", status: "Not Seen" },
      { type: "danger", title: "Danger!", message: "Your request was completed successfully.", date: "2023-11-16 21:37:43", status: "Seen" },
      { type: "info", title: "Info!", message: "Your request was completed successfully.", date: "2022-13-17 22:39:45", status: "Not Seen" },
    ];

  constructor() { }

  ngOnInit() {

  }





}
