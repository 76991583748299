import { FilterPagination, PaginationResponse } from '@models/filter-pagination';
import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ChannelManager } from '@models/channel-manager';
import { BaseResponse } from '@models/response';
import { ApiService } from '@services/api.service';
import { Country } from '../../../models/country';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})
export class CountryComponent {
  countryList:Array<Country>  = [];
  pagination: FilterPagination = new FilterPagination(1, 10, 'name', 'DESC', '', '');
  paginationRes: PaginationResponse = new PaginationResponse();
  closeResult = '';
  public countryForm: UntypedFormGroup;
  public isLoading = false;
  hideSearch: boolean = true;
  startingSn: number;

  constructor(private apiService: ApiService, private renderer: Renderer2, private modalService: NgbModal, private toastr: ToastrService){

  }

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'country-list'
    );

    this.countryForm = new UntypedFormGroup({
      countryId: new UntypedFormControl(null),
      countryName: new UntypedFormControl('', Validators.required),
      countryCode: new UntypedFormControl('', Validators.required),
      nationality: new UntypedFormControl('', Validators.required),
      isActive: new UntypedFormControl('', Validators.required)
    });
    this.getCountryList();
  }

  hideOrShowSearch() {
    this.hideSearch = !this.hideSearch;
  }

  onPageChange(pageNo: number) {
    this.pagination.pageNumber = pageNo;
    this.getCountryList();
  }

  resetSearch() {
    this.pagination.isActive = '';
    this.pagination.searchTerm = '';
    this.getCountryList();
  }
  
  getCountryList(){    
    this.apiService.countryList(this.pagination).subscribe((result: BaseResponse) => {
      this.countryList = result.data;
      this.paginationRes = result.pagination;
      this.calculateStartingSn();
    })
  }

  calculateStartingSn(): void {
    this.startingSn = (this.pagination.pageNumber - 1) * this.pagination.pageSize + 1;
  }

  add(content){
    this.countryForm.reset();
    this.countryForm.patchValue({isActive: ''});
    this.openPopup(content)
  }

  edit(content, country) {
    this.countryForm.reset();
    this.countryForm.patchValue({
      countryId: country.countryId,
      countryName: country.countryName,
      countryCode: country.countryCode,
      nationality: country.nationality,
      isActive: country.isActive
    })
    this.openPopup(content);
	}

  openPopup(content){
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
  }

  getColorClass(status: string){
    if(status != null){
      return status == 'Y' ? 'text-success' : 'text-danger';
    }
  }

  closeModal(){
    this.modalService.dismissAll();
  }

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

  async saveCountry(){
    this.countryForm.markAllAsTouched();
    this.countryForm.get('name')?.markAsDirty();
    if(this.countryForm.valid){
      this.isLoading = true;
      if(this.countryForm.value.countryId != null){
        await this.apiService.countryUpdate(this.countryForm.value).subscribe((result: any) => {
          this.handleResponse(result);
        });
      } else {
        await this.apiService.countrySave(this.countryForm.value).subscribe((result: any) => {
          this.handleResponse(result);
        });
      }
      this.closeModal();
    }
  }

  handleResponse(result: any){
    this.isLoading = false;
    if(result.responseCode == "200"){
      this.toastr.success(result.responseMessage)
      this.getCountryList();
    } else {
      this.toastr.error(result.responseMessage)
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(
        document.querySelector('app-root'),
        'country-list'
    );
  }
}
