<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6 d-flex">
                <h1>{{ emailTemplateId == '' ? 'Create' : 'Edit' }} Email Template</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item active"><i class="fa fa-envelope"></i> {{ emailTemplateId == '' ? 'Create' : 'Edit' }} Email Template</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">

        <div class="card">
            <form [formGroup]="emailTemplateForm" appFocusFirstInvalidField
                (ngSubmit)="saveEmailTemplateForm()">
            <div class="card-body">
                <div class="form-group row">

                    <div class="col-sm-3">
                        <span class="text-danger">*</span>
                        <label for="templateName">Template Name</label>
                        <input type="text" class="form-control" placeholder="Template Name"
                            formControlName="templateName">
                        <div *ngIf="emailTemplateForm.controls['templateName'].dirty || emailTemplateForm.controls['templateName'].touched"
                            class="text-danger">
                            <span
                                *ngIf="emailTemplateForm.controls['templateName'].errors?.['required']">*Template name is required</span>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <span class="text-danger">*</span>
                        <label for="templateCode">Template Code</label>
                        <input type="text" class="form-control" placeholder="Template Code"
                            formControlName="templateCode" [readonly]="enableReadOnly"/>
                        <div *ngIf="emailTemplateForm.controls['templateCode'].dirty || emailTemplateForm.controls['templateCode'].touched"
                            class="text-danger">
                            <span
                                *ngIf="emailTemplateForm.controls['templateCode'].errors?.['required']">*Template Code is required</span>
                        </div>
                    </div>

                    <div class="col-sm-3">
                        <span class="text-danger">*</span>
                        <label for="emailFrom">Email From</label>
                        <input type="text" class="form-control" placeholder="Email From"
                            formControlName="emailFrom">
                        <div *ngIf="emailTemplateForm.controls['emailFrom'].dirty || emailTemplateForm.controls['emailFrom'].touched"
                            class="text-danger">
                            <span
                                *ngIf="emailTemplateForm.controls['emailFrom'].errors?.['required']">*Email from is required</span>
                        </div>
                    </div>

                    <div class="col-sm-3">
                        <span class="text-danger">*</span>
                        <label for="displayName">Display Name</label>
                        <input type="text" class="form-control" placeholder="Display Name"
                            formControlName="displayName">
                        <div *ngIf="emailTemplateForm.controls['displayName'].dirty || emailTemplateForm.controls['displayName'].touched"
                            class="text-danger">
                            <span
                                *ngIf="emailTemplateForm.controls['displayName'].errors?.['required']">*Display name is required</span>
                        </div>
                    </div>

                    <div class="col-sm-3">
                        <span class="text-danger">*</span>
                        <label for="subject">Subject</label>
                        <input type="text" class="form-control" placeholder="Subject"
                            formControlName="subject">
                        <div *ngIf="emailTemplateForm.controls['subject'].dirty || emailTemplateForm.controls['subject'].touched"
                            class="text-danger">
                            <span
                                *ngIf="emailTemplateForm.controls['subject'].errors?.['required']">*Subject is required</span>
                        </div>
                    </div>

                    <div class="col-sm-12 mt-3">
                        <span class="text-danger">*</span>
                        <label for="body">Body</label>
                        <ckeditor class="ckeditor" [editor]="Editor" [data]="htmlContent" formControlName="body"></ckeditor>
                        <div *ngIf="emailTemplateForm.controls['body'].dirty || emailTemplateForm.controls['body'].touched"
                            class="text-danger">
                            <span
                                *ngIf="emailTemplateForm.controls['body'].errors?.['required']">*Email body is required</span>
                        </div>
                    </div>

                    <div class="col-sm-3">
                        <span class="text-danger">*</span>
                        <label for="isActive">Is Active</label>
                        <select class="form-control" placeholder="Is Active" formControlName="isActive">
                            <option value="">-- Select --</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </select>
                        <div *ngIf="emailTemplateForm.controls['isActive'].dirty || emailTemplateForm.controls['isActive'].touched"
                            class="text-danger">
                            <span *ngIf="emailTemplateForm.controls['isActive'].errors?.['required']">*Select
                                data active status</span>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="card-footer">
                <button type="submit" class="btn btn-success"><i class="fa fa-save"></i> Save</button>
                <button type="button" class="btn btn-danger ml-3" (click)="onBackPressed()"><i class="fa fa-times"></i> Cancel</button>
            </div>
            <!-- /.card-body -->
            </form>
        </div>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->