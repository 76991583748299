<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6 d-flex">
                <h4>Units <span class="text-gray-600">({{dataCount}})</span></h4>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item active"><i class="fa fa-th"></i> Units</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card of-x-a p-3">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-primary" (click)="add(content)"><i
                                class="fa fa-plus"></i> Add
                            Unit</button>
                    </div>
                    <div class="col-sm-6">
                        <div class="flex float-sm-right">
                            <app-table-data-exporter tableId="dataTable"
                                fileName="role_setup"></app-table-data-exporter>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3" *ngIf="unitInfoList.length > 0">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body table-responsive p-0" style="max-height: 50vh; height: auto;">
                            <table id="dataTable" class="table table-head-fixed tbl-sm text-nowrap">
                                <thead>
                                    <tr>
                                        <th class="text-center w-5p" scope="col">S.N.</th>
                                        <th>Unit Name</th>
                                        <th>Rent Collector</th>
                                        <th class="text-center">Bedroom</th>
                                        <th class="text-center">Bathroom</th>
                                        <th class="text-center">Kitchen</th>
                                        <th class="text-center">Available to assign</th>
                                        <th class="text-center">Assigned To</th>
                                        <th class="text-center">Status</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let unitInfo of unitInfoList; index as i">
                                        <th scope="row">{{ startingSn + i }})</th>
                                        <td>{{unitInfo.name}}</td>
                                        <td>{{unitInfo.rent_collector_name}}</td>
                                        <td class="text-center">{{unitInfo.bedroom}}</td>
                                        <td class="text-center">{{unitInfo.bathroom}}</td>
                                        <td class="text-center">{{unitInfo.kitchen}}</td>
                                        <td
                                            class="text-center text-{{ unitInfo.availability == 0 ? 'success' : 'danger' }}">
                                            <i class="fa fa-circle"></i> {{ unitInfo.availability == 0 ? 'Available' :
                                            'Not Available' }}
                                        </td>
                                        <td class="text-center">{{unitInfo.tenant_name}}</td>
                                        <td class="text-center text-{{ unitInfo.status == 0 ? 'success' : 'danger' }}">
                                            <i class="fa fa-circle"></i> {{ unitInfo.status == 0 ? 'Active' :
                                            'InActive' }}
                                        </td>
                                        <td class="text-center">
                                            <button class="btn btn-outline text-primary pstitle-primary-right"
                                                pstitle="Edit unitInfo {{ unitInfo.name }}"
                                                (click)="edit(content, unitInfo)">
                                                <i class="fa fa-edit p-1"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>
            </div>

            <div *ngIf="unitInfoList == null || unitInfoList.length == 0">
                <h4 class="text-center p-4">No Data</h4>
            </div>
        </div>
    </div>
    <!-- /.card -->
</section>



<ng-template #content let-modal>
    <div class="modal fade show" tabindex="-1" role="dialog" style="display: block; padding-right: 15px;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">{{dialogActFor}} unit information</h4>
                    <i class="fa fa-times fa-2x" (click)="modal.dismiss('Cross click')"></i>
                </div>
                <form [formGroup]="unitInfoForm" appFocusFirstInvalidField>
                    <div class="modal-body">
                        <span>Fields marked with (<span class="text-danger">*</span>) is required</span>
                        <div class="form-group row">
                            <input type="hidden" formControlName="id">
                            <div class="col-sm-12 mt-4">
                                <psmat-input type="text" formControlName="name" label="Unit Name" controlName="name"
                                    [parentForm]="unitInfoForm" required="true" maxlength="40"></psmat-input>
                            </div>
                            <div class="col-sm-6 mt-4">
                                <psmat-input OnlyNumber="true" formControlName="bedroom" label="Bedroom"
                                    controlName="bedroom" [parentForm]="unitInfoForm" required="true"
                                    (onTextChange)="onDateChange('Start')"></psmat-input>
                            </div>
                            <div class="col-sm-6 mt-4">
                                <psmat-input OnlyNumber="true" formControlName="bathroom" label="Bathroom"
                                    controlName="bathroom" [parentForm]="unitInfoForm" required="true"
                                    (onTextChange)="onDateChange('End')"></psmat-input>
                            </div>
                            <div class="col-sm-6 mt-4">
                                <psmat-input onlyNumber="true" formControlName="kitchen" label="Kitchen"
                                    controlName="kitchen" [parentForm]="unitInfoForm" required="true"></psmat-input>
                            </div>
                            <div class="col-sm-6 mt-4">
                                <div class="ps-mat-input">
                                    <select id="rent_collector_id" name="rent_collector_id"
                                        formControlName="rent_collector_id">
                                        <option [ngValue]="null">- Select -</option>
                                        <option *ngFor="let owner of ownerList" [value]="owner.id">{{ owner.name }}
                                        </option>
                                    </select>
                                    <label for="rent_collector_id">Unit Owner</label>
                                </div>
                            </div>
                            <div class="col-sm-12 mt-4">
                                <psmat-textarea [rows]="4" formControlName="remarks" label="Other Information"
                                    controlName="remarks" [parentForm]="unitInfoForm" maxlength="255"></psmat-textarea>
                            </div>
                            <div class="col-sm-6 mt-4">
                                <div class="ps-mat-input">
                                    <select id="status" name="status" formControlName="status">
                                        <option value="">- Select -</option>
                                        <option value="0">Yes</option>
                                        <option value="1">No</option>
                                    </select>
                                    <label for="status">Is Active</label>
                                </div>
                                <div *ngIf="unitInfoForm.controls['status'].dirty || unitInfoForm.controls['status'].touched"
                                    class="text-danger">
                                    <span *ngIf="unitInfoForm.controls['status'].errors?.['required']">*Status is
                                        required</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-success" (click)="saveUnitInfo()"><i
                                class="fa fa-save"></i>
                            Save</button>
                        <button type="button" class="btn btn-danger" (click)="modal.close('Cross click')"><i
                                class="fa fa-times"></i> Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>