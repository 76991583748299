<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">Dashboard</h1>
            </div>
            <div class="col-sm-6">
                <!-- <ol class="breadcrumb float-sm-right">
                    <a [routerLink]="addPropertyPageLink"><button type="button" class="btn btn-primary ml-3"><i class="fa fa-home"></i> Add Property</button></a>
                </ol> -->
            </div>
        </div>
    </div>
</div>

<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-6" *ngFor="let cv of cardView; index as i">
                <!-- small box -->
                <div class="small-box bg-{{cv.color}}" [routerLink]="cv.name === 'Debtors' ? 'tenants' : cv.name.includes('Units') ? 'units' : ''">
                    <div class="inner">
                        <h3>{{cv.data}}</h3>

                        <p>{{cv.name}}</p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-{{cv.icon}}"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="row d-none" *ngIf="property">

            <div class="col-lg-8 col-sm-12">
                <div class="card">
                    <div class="card-body">
                        
                        <div class="card-title text-md mb-2">
                            <strong>My Properties</strong>
                        </div>
                        
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="text-center">S.N.</th>
                                <th>Name</th>
                                <th class="text-center">Unit/s</th>
                                <th class="text-center">Available Unit/s</th>
                                <th class="text-center">Tenants</th>
                                <th class="text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let prop of property; index as i">
                                <th class="text-center" scope="row">{{ initCount + i }})</th>
                                <td>{{ prop.name }}</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">{{ prop.tenants }}</td>
                                <td class="text-center text-{{ prop.status == 0 ? 'success' : 'danger' }}">
                                    {{ prop.status == 0 ? 'Active' :
                                    'InActive' }}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    </div>
                </div>
            </div>

        </div>


        <div class="row d-none">
            <div class="col-lg-6 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title">
                            Debtors
                        </div>
                        <div class="float-sm-right d-flex">
                            <button class="btn btn-outline-none pstitle-primary {{debtorsGraphType === 'bar' ? 'selected' : ''}}" type="button" pstitle="Show Bar Graph" (click)="changeDebtorsGraph('bar')"><i class="ion ion-stats-bars"></i></button>
                            <button class="btn btn-outline-none pstitle-danger {{debtorsGraphType === 'line' ? 'selected' : ''}}" type="button" pstitle="Show Line Chart" (click)="changeDebtorsGraph('line')"><i class="fa fa-angle-up"></i></button>
                            <button class="btn btn-outline-none pstitle-success {{debtorsGraphType === 'pie' ? 'selected' : ''}}" type="button" pstitle="Show Pie Chart" (click)="changeDebtorsGraph('pie')"><i class="ion ion-pie-graph"></i></button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="divChart">
                            <canvas id="debtorChart"></canvas>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title">
                            Rent
                        </div>
                        <div class="float-sm-right d-flex">
                            <button class="btn btn-outline-none pstitle-primary {{rentGraphType === 'bar' ? 'selected' : ''}}" type="button" pstitle="Show Bar Graph" (click)="changeRentGraph('bar')"><i class="ion ion-stats-bars"></i></button>
                            <button class="btn btn-outline-none pstitle-danger {{rentGraphType === 'line' ? 'selected' : ''}}" type="button" pstitle="Show Line Chart" (click)="changeRentGraph('line')"><i class="fa fa-angle-up"></i></button>
                            <button class="btn btn-outline-none pstitle-success {{rentGraphType === 'pie' ? 'selected' : ''}}" type="button" pstitle="Show Pie Chart" (click)="changeRentGraph('pie')"><i class="ion ion-pie-graph"></i></button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="divChart">
                            <canvas id="rentChart"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
