import { Component, EventEmitter, HostListener, Input, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'psmat-input',
  templateUrl: './psmat-input.component.html',
  styleUrls: ['./psmat-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PSMatInputComponent),
      multi: true
    }
  ]
})
export class PSMatInputComponent implements OnInit, ControlValueAccessor {
  @Input() type: string = 'text';
  @Input() controlName: string;
  @Input() label: string;
  @Input() value: any;
  @Input() maxlength: number = 65535;
  @Input() min: string;
  @Input() max: string;
  @Input() required: boolean;
  @Input() showRequiredMsg: boolean = true;
  @Input() readonly: boolean;
  @Input() onlyNumber: boolean;
  @Input() isDisabled: boolean;
  @Input() parentForm: UntypedFormGroup;
  isTypePassword = false;

  @Output() onKeyUp = new EventEmitter<KeyboardEvent>();
  @Output() onBlur = new EventEmitter<KeyboardEvent>();
  @Output() onKeyPress = new EventEmitter<string>();
  @Output() onTextChange = new EventEmitter<KeyboardEvent>();

  hasValue: boolean = false;
  onChange: any = () => { };
  onTouched: any = () => { };

  constructor() { }

  ngOnInit(): void {
    if (this.controlName && this.parentForm) {
      const formControl = this.parentForm.get(this.controlName);
      if (formControl && formControl.value) {
        this.value = formControl.value;
        this.hasValue = true;
      } else {
        this.hasValue = this.required;
      }
    }
  }

  writeValue(value: any): void {
    this.value = value;
    this.hasValue = !!value;
    this.onChange(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    if (this.required) {
      this.onTouched = fn;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }


  /* Event handlers Start */

  onKeyUpEvent(event: KeyboardEvent) {
    this.onKeyUp.emit(event);
  }

  onBlurEvent(event: KeyboardEvent) {
    this.onBlur.emit(event);
  }

  onKeyPressEvent(event: KeyboardEvent){
    setTimeout(() => {
      const inputValue = (event.target as HTMLInputElement).value;
      this.onKeyPress.emit(inputValue);
    }, 400);
  }
  
  toggleFieldTextType() {
    this.isTypePassword = this.isTypePassword ? false : true;
  }

  // onKeyPressEvent(event: KeyboardEvent) {
  //   setTimeout(() => {
  //     const inputElement = event.target as HTMLInputElement;
  //     if (inputElement) {
  //       const inputValue = inputElement.value;
  //       this.onKeyPress.emit(inputValue);
  //     } else {
  //       console.error('Input element not found.');
  //     }
  //   }, 400);
  // }



  onTextChangeEvent(event: KeyboardEvent) {
    this.onTextChange.emit(event);
  }

  /* Event handlers End */

  onInput(event: any) {
    const inputValue = event.target.value;
    this.hasValue = inputValue.length > 0 || this.required;
    this.value = inputValue;
    this.onChange(inputValue);
    this.onTouched();
  }
}
