<pf-dropdown hide-arrow>
    <div slot="button">
        <a class="nav-link">
            <i class="fas fa-ellipsis-v"></i>
        </a>
    </div>
    <div slot="menu">
        <span class="dropdown-item dropdown-header">EXPORT DATA AS</span>
        <div class="dropdown-divider"></div>
        <button class="btn text-primary w-1hper text-left" (click)="export('png')"><i class="fa fa-image"></i> PNG</button>
        <div class="dropdown-divider"></div>
        <button class="btn text-danger w-1hper text-left" (click)="export('pdf')"><i class="fa fa-file-pdf"></i> PDF</button>
        <div class="dropdown-divider"></div>
        <button class="btn text-success w-1hper text-left" (click)="export('xlsx')"><i class="fa fa-file-excel"></i> Excel</button>
        <div class="dropdown-divider"></div>
        <button class="btn text-success w-1hper text-left" (click)="export('csv')"><i class="fa fa-file-csv"></i> CSV</button>
        <div class="dropdown-divider"></div>
    </div>
</pf-dropdown>

<ng-template #tableDataExporter>
    <a (click)="export('png')" class="dropdown-item text-primary">
        <i class="fa fa-image mr-2"></i> PNG
    </a>
    <div class="dropdown-divider"></div>
</ng-template>

