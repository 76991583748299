import { Component, OnInit, Renderer2 } from '@angular/core';
import { ApiService } from '@services/api.service';
import { BaseResponse } from '@models/response';
import { PaginationResponse, PropertyListFilter } from '@models/filter-pagination';
import { PropertyInformation } from '@models/property-information';
import { Property } from '../../../models/property/model-property';
import { RequestProperty } from '../../../models/property/request-property';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'property-list',
  templateUrl: './property-list.component.html',
  styleUrls: ['./property-list.component.scss']
})
export class PropertyInformationListComponent implements OnInit {
  propertyInformationList:Array<PropertyInformation>  = [];
  pagination: PropertyListFilter = new PropertyListFilter(1, 10, 'propertyName', 'DESC', '', '', '', '');
  paginationRes: PaginationResponse = new PaginationResponse();
  hideSearch: boolean = true;
  startingSn: number;
  propertyCount: number = 0;
  propertyList: Array<Property> = [];

  constructor(private apiService: ApiService, private appService: AppService, private renderer: Renderer2){}

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'property-list'
    );
    this.getPropertyList();
  }
  

  hideOrShowSearch() {
    this.hideSearch = !this.hideSearch;
  }

  onPageChange(pageNo: number) {
    this.pagination.pageNumber = pageNo;
    this.getPropertyList();
  }

  resetSearch() {
    this.pagination.isActive = '';
    this.pagination.searchTerm = '';
    this.pagination.callPublicAPI = '';
    this.pagination.allowSyncData = '';
    this.getPropertyList();
  }

  getPropertyList(){
    const reqParam = new RequestProperty(this.appService.userId);
    this.apiService.propertyList(reqParam).subscribe((result: BaseResponse) => {
      this.propertyList = result.data;
      this.propertyCount = this.propertyList.length;
      // this.propertyInformationList = result.data;
      this.paginationRes = result.pagination;
      this.calculateStartingSn();
    })
  }
  
  calculateStartingSn(): void {
    this.startingSn = (this.paginationRes.pageNumber - 1) * this.paginationRes.pageSize + 1;
  }
}