export class ModelChannelManagerPaymentModeMap {
    channelManagerPropertyInformationId: number;
    channelManager: Array<ModelPaymentModeChannelManagers>;
    paymentModeMappingXML: string;

    constructor(channelManagerPropertyInformationId: number, channelManager: Array<ModelPaymentModeChannelManagers>, paymentModeMappingXML: string){
        this.channelManagerPropertyInformationId = channelManagerPropertyInformationId;
        this.channelManager = channelManager;
        this.paymentModeMappingXML = paymentModeMappingXML;
    }
}

export class ModelPaymentModeChannelManagers {
    channelManagerId: number;
    name: string;
    paymentModeMapping: Array<ModelPaymentModeMapping>;

    constructor(channelManagerId: number, name: string, paymentModeMapping: Array<ModelPaymentModeMapping>){
        this.channelManagerId = channelManagerId;
        this.name = name;
        this.paymentModeMapping = paymentModeMapping;
    }
}

export class ModelPaymentModeMapping {
    paymentModeMappingId: string;
    channelManagerPaymentModeId: string;
    channelManagerPaymentModeName: string;
    propertyPaymentModeId: string;
    propertyPaymentModeName: string;
    isDefault: string;
    isCustom: boolean = false;
    isActive: boolean = false;

    constructor(channelManagerPaymentModeId: string,
        channelManagerPaymentModeName: string,
        propertyPaymentModeId: string,
        propertyPaymentModeName: string,
        isDefault: string, isCustom: boolean, isActive: boolean){
            this.channelManagerPaymentModeId = channelManagerPaymentModeId;
            this.channelManagerPaymentModeName = channelManagerPaymentModeName;
            this.propertyPaymentModeId = propertyPaymentModeId;
            this.propertyPaymentModeName = propertyPaymentModeName;
            this.isDefault = isDefault;
            this.isCustom = isCustom;
            this.isActive = isActive;
    }
}
