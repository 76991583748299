<div class="card card-outline card-primary">
    <div class="card-header text-center">
        <a [routerLink]="['/']" class="h1"><img src="@assets/img/logo.png" /></a>
    </div>
    <div class="card-body">
        <p class="login-box-msg">
            Forgot your password?<br><span class="text-sm">No worries! Here you can easily retrieve a new one.</span>
        </p>
        <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()">
            <div class="form-group">
                <label for="propertyCode">Email</label>
                <input formControlName="userName" type="text" class="form-control" (keyup)="aoiField()"/>
            </div>
            <div class="row">
                <div class="col-12">
                    <pf-button [type]="'submit'" [block]="true" [disabled]="!isInputDetected || isInputDetected && forgotPasswordForm.invalid || loading">
                        <span *ngIf="!loading">Request new password</span>
                        <span *ngIf="loading">Requesting please wait...</span>
                    </pf-button>
                </div>
            </div>
        </form>
        <p class="mt-3 mb-1">
            <a [routerLink]="['/login']">Login</a>
        </p>
    </div>
</div>
