<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Profile</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">User Profile</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header p-2 d-flex">
                        <ul class="nav nav-pills">
                            <li class="nav-item">
                                <a class="nav-link pointer {{getTabActiveStat('profile')}}"
                                    (click)="setThisTabActive('profile')">Profile</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link pointer {{getTabActiveStat('changePassword')}}"
                                    (click)="setThisTabActive('changePassword')">Change Password</a>
                            </li>
                        </ul>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <div class="tab-content">
                            <div class="tab-pane {{getTabActiveStat('profile')}}" *ngIf="isTabActive('profile')">                                
                                <div class="card">
                                    <div class="card-body d-flex">                                                
                                        <div class="col">
                                            <h3 class="profile-username">
                                                {{ userProfile != null ? userProfile.name : 'N/A'}}
                                            </h3>
                                            <span><i class="fa fa-envelope"></i> {{ user != null ? user.email : 'N/A'}}</span>    
                                        </div>
                                        <div class="text-right">
                                            <button class="btn btn-{{isEditDisabled ? 'primary' : 'secondary'}}" title="Edit Profile Information" (click)="isEditDisabled = false"><i class="fa fa-edit"></i> Edit</button>
                                        </div>
                                    </div>
                                </div>
                                <form [formGroup]="userProfileForm" appFocusFirstInvalidField
                                    (ngSubmit)="updateUserDetails()">
                                    <div class="form-group row">
                                        <div class="col-sm-4 mt-4">
                                            <div
                                                class="ps-mat-input {{isEditDisabled ? 'ps-mat-input-disabled' : ''}}">
                                                <select name="title" formControlName="title">
                                                    <option value="">- Select -</option>
                                                    <option value="Mr">Mr</option>
                                                    <option value="Miss/Mrs">Miss/Mrs</option>
                                                    <option value="Mx/Misc">Mx/Misc</option>
                                                </select>
                                                <label for="title">Title</label>
                                            </div>
                                            <div *ngIf="userProfileForm.controls['title'].dirty || userProfileForm.controls['title'].touched"
                                                class="text-danger">
                                                <span
                                                    *ngIf="userProfileForm.controls['title'].errors?.['required']">*Title
                                                    is required</span>
                                            </div>
                                        </div>

                                        <div class="col-sm-4 mt-4">
                                            <psmat-input class="{{isEditDisabled ? 'ps-mat-input-disabled' : ''}}" type="text" formControlName="name" label="Name" controlName="name" [parentForm]="userProfileForm" maxlength="30"></psmat-input>
                                            <div *ngIf="userProfileForm.controls['name'].dirty || userProfileForm.controls['name'].touched"
                                                class="text-danger">
                                                <span
                                                    *ngIf="userProfileForm.controls['name'].errors?.['required']">*Name is
                                                    required</span>
                                                <span
                                                    *ngIf="userProfileForm.controls['name'].errors?.['minlength']">*Name must be at least 4 characters long.</span>
                                            </div>
                                        </div>

                                        <div class="col-sm-4 mt-4">
                                            <psmat-input class="{{isEditDisabled ? 'ps-mat-input-disabled' : ''}}" type="email" formControlName="email" label="Email" controlName="email" readonly="true" [parentForm]="userProfileForm" maxlength="30"></psmat-input>
                                            <div *ngIf="userProfileForm.controls['email'].dirty || userProfileForm.controls['email'].touched"
                                                class="text-danger">
                                                <span
                                                    *ngIf="userProfileForm.controls['email'].errors?.['required']">*Email is
                                                    required</span>
                                            </div>
                                        </div>

                                        <div class="col-sm-4 mt-4">
                                            <psmat-input OnlyNumber="true" class="{{isEditDisabled ? 'ps-mat-input-disabled' : ''}}" formControlName="contact" label="Contact" controlName="contact" [parentForm]="userProfileForm" maxlength="10"></psmat-input>
                                            <div *ngIf="userProfileForm.controls['contact'].invalid && (userProfileForm.controls['contact'].dirty || userProfileForm.controls['contact'].touched)"
                                                class="text-danger">
                                                <span
                                                    *ngIf="userProfileForm.controls['contact'].errors?.['required']">*Contact is required</span>
                                                <span
                                                    *ngIf="userProfileForm.controls['contact'].errors?.['minlength']">*Min
                                                    10 characters required.</span>
                                            </div>
                                        </div>

                                        <div class="col-sm-4 mt-4">
                                            <div
                                                class="ps-mat-input {{isEditDisabled ? 'ps-mat-input-disabled' : ''}}">
                                                <select name="title" formControlName="gender">
                                                    <option value="">- Select -</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                <label for="gender">Gender</label>
                                            </div>
                                            <div *ngIf="userProfileForm.controls['gender'].dirty || userProfileForm.controls['gender'].touched"
                                                class="text-danger">
                                                <span
                                                    *ngIf="userProfileForm.controls['gender'].errors?.['required']">*Select
                                                    gender</span>
                                            </div>
                                        </div>

                                        <div class="col-sm-4 mt-4">
                                            <psmat-input type="date" class="{{isEditDisabled ? 'ps-mat-input-disabled' : ''}}" formControlName="dob" label="DOB" [max]="minAllowedDob" controlName="dob" [parentForm]="userProfileForm"></psmat-input>
                                            <div *ngIf="userProfileForm.controls['dob'].invalid && (userProfileForm.controls['dob'].dirty || userProfileForm.controls['dob'].touched)"
                                                class="text-danger">
                                                <span
                                                    *ngIf="userProfileForm.controls['dob'].errors?.['required']">*DOB is required</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="!isEditDisabled">
                                        <button type="submit" class="btn btn-success"><i class="fa fa-save"></i>
                                            Save</button>
                                        <button type="button" class="btn btn-danger ml-3" (click)="cancelEdit()"><i
                                                class="fa fa-times"></i> Cancel</button>
                                    </div>
                                </form>
                            </div>

                            <div class="tab-pane {{getTabActiveStat('changePassword')}}"
                                *ngIf="isTabActive('changePassword')">
                                <form [formGroup]="changePasswordForm" (ngSubmit)="updatePassowrd()">
                                    <div class="form-group col ml--2 p-0">
                                        
                                        <div class="col-sm-3 mt-3">
                                            <psmat-input type="password" formControlName="current_password" label="Current Password"
                                                controlName="current_password" [parentForm]="changePasswordForm" required="true"></psmat-input>
                                                <div *ngIf="changePasswordForm.controls['current_password'].invalid && (changePasswordForm.controls['current_password'].dirty || changePasswordForm.controls['current_password'].touched)"
                                                    class="text-danger">
                                                    <span
                                                        *ngIf="changePasswordForm.controls['current_password'].errors?.['required']">*Old
                                                        password
                                                        is
                                                        required</span>
                                                    <span
                                                        *ngIf="changePasswordForm.controls['current_password'].errors?.['pattern']">*Old
                                                        password
                                                        is
                                                        invalid.</span>
                                                </div>
                                        </div>
                                        
                                        <div class="col-sm-3 mt-3">
                                            <psmat-input type="password" formControlName="new_password" label="New Password"
                                                controlName="new_password" [parentForm]="changePasswordForm" required="true"></psmat-input>
                                                
                                                <div *ngIf="changePasswordForm.controls['new_password'].dirty || changePasswordForm.controls['new_password'].touched"
                                                    class="text-danger">
                                                    <span
                                                        *ngIf="changePasswordForm.controls['new_password'].errors?.['required']">*New
                                                        password
                                                        is
                                                        required<br /></span>
                                                </div>
                                                <div
                                                    *ngIf="!changePasswordForm.controls['new_password'].errors?.['required'] && changePasswordForm.controls['new_password'].invalid">
                                                    <span
                                                        class="{{ containsPTRN('new_password', numPtrn) ? 'text-success' : 'text-danger' }}">*New
                                                        password
                                                        must contain at least one number.<br /></span>
                                                    <span
                                                        class="{{ containsPTRN('new_password', ucPtrn) && containsPTRN('new_password', lcPtrn) ? 'text-success' : 'text-danger' }}">*one
                                                        uppercase and a lowercase letter<br /></span>
                                                    <span
                                                        class="{{ contains8Char() ? 'text-success' : 'text-danger' }}">*At
                                                        least 8
                                                        characters<br /></span>
                                                    <span
                                                        class="{{ containsPTRN('new_password', spcPtrn) ? 'text-danger' : 'text-success' }}">*New
                                                        password
                                                        cannot contain whitespace<br /></span>
                                                    <span
                                                        class="{{ containsPTRN('new_password', spclPtrn) ? 'text-success' : 'text-danger' }}">*At
                                                        least 1 special character<br /></span>
                                                </div>
                                        </div>
                                        
                                        <div class="col-sm-3 mt-3">
                                            <psmat-input type="password" formControlName="confirmPassword" label="Confirm New Password"
                                                controlName="confirmPassword" [parentForm]="changePasswordForm" required="true"></psmat-input>
                                                <div *ngIf="changePasswordForm.controls['confirmPassword'].dirty || changePasswordForm.controls['confirmPassword'].touched"
                                                    class="text-danger">
                                                    <span
                                                        *ngIf="changePasswordForm.controls['confirmPassword'].errors?.['required']">*Confirm
                                                        new password
                                                        is
                                                        required<br /></span>
                                                    <span
                                                        *ngIf="!changePasswordForm.controls['confirmPassword'].errors?.['required'] && changePasswordForm.errors?.['passwordMatched']">*Confirm
                                                        new password do not match</span>
                                                </div>
                                        </div>
                                    </div>
                                    <div class="ml-2">
                                        <button type="submit" class="btn btn-success"><i class="fa fa-save"></i>
                                            Save</button>
                                        <button type="button" class="btn btn-danger ml-3" (click)="onBackPressed()"><i
                                                class="fa fa-times"></i> Cancel</button>
                                    </div>
                                </form>
                            </div>
                            <!-- /.tab-pane -->
                        </div>
                        <!-- /.tab-content -->
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.nav-tabs-custom -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>