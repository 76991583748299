import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BaseResponse } from '@models/response';
import { ModelTenant } from '@models/tenant/model-tenant';
import { ApiService } from '@services/api.service';
import { ModelReceivedRent } from '@models/rent/model-received-rent';
import { ModelRentInformation } from '@models/rentInformation/model-rent-information';
import { LedgerFilter } from '@models/ledger-filter';
import { ModelLedger } from '@models/response/model-ledger';
import { Utils } from '@utils/utils';

@Component({
  selector: 'ledger',
  templateUrl: './ledger.component.html',
  styleUrls: ['./ledger.component.scss']
})

export class LedgerComponent implements OnInit, OnDestroy{
  tenantList: Array<ModelTenant> = [];
  billList: Array<ModelLedger> = [];
  receiptList: Array<ModelReceivedRent> = [];
  rentInfoList: Array<ModelRentInformation> = [];
  ledgerFilter: LedgerFilter = new LedgerFilter('', null, '', '');
  public isLoading = false;
  currentDate: any;
  startingSn: number = 1;
  selectedTenantId: any;
  selTenantId: any;
  drTotal: number = 0;
  crTotal: number = 0;
  balanceTotal: number = 0;

  constructor(private apiService: ApiService, private renderer: Renderer2, private modalService: NgbModal, private toastr: ToastrService){}

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'bill-matching'
    );
    this.currentDate = Utils.getCurrentDate('MM-dd-yyyy');
    
    this.ledgerFilter['start_date'] = this.currentDate;
    this.ledgerFilter['end_date'] = this.currentDate;
    this.getTenantList();
  }

  onFieldValueChange(fieldName: string, event: any){
    this.ledgerFilter[fieldName] = event.target.value;
  }
  

  getRentInformation() {
    this.apiService.rentInformationList(this.selTenantId).subscribe((result: BaseResponse) => {
      this.rentInfoList = result.data;
    })
  }

  onDebtorSelected(event: any){
    const selectedValue = event.target.value;
    this.selTenantId = selectedValue;
    this.getRentInformation();
  }

  resetSearch() {
    this.ledgerFilter.tenant_id = '';
    this.ledgerFilter.rent_info_id = null;
    this.rentInfoList = [];
    this.billList = [];
    this.getTenantList();
  }
  
  getTenantList(){
    this.apiService.tenantList().subscribe((result: BaseResponse) => {
      this.tenantList = result.data;
    })
  }

  loadLedgers(){
    this.apiService.getLedgerWithFilter(this.ledgerFilter).subscribe((result: BaseResponse) => {
      if(result.code == 1){
        this.showDialog(result);
      } else {
        this.billList = result.data;
       this.drTotal = 0;
       this.crTotal = 0;
       this.balanceTotal = 0;
        this.billList.forEach(bill => {
          this.drTotal += parseFloat(bill.debit.toString());
          this.crTotal += parseFloat(bill.credit.toString());
        });
        this.balanceTotal = this.drTotal - this.crTotal;
      }
    })
  }

  getReceipts(){
    this.apiService.receiptList(this.selectedTenantId).subscribe((result: BaseResponse) => {
      this.receiptList = result.data;
    })
  }

  showDialog(result: any) {
    this.isLoading = false;
    if (result.code == 0) {
      this.toastr.success(result.message)
    } else {
      this.toastr.error(result.message)
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(
        document.querySelector('app-root'),
        'channel-manager-list'
    );
  }
}