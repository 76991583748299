import { Component, OnInit, OnDestroy, Renderer2, HostBinding } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn, FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Pattern } from '@utils/validators/patterns';
import { AppService } from './../../services/app.service';
import * as jsonData from '@assets/setting.json';
import { SysConfig } from '@models/system-config';
import { ActivatedRoute } from '@angular/router';
import { SMsg } from '../../utils/static/s-msg';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})

export class ChangePasswordComponent implements OnInit, OnDestroy {
  data: SysConfig = jsonData;
  public changePasswordForm: UntypedFormGroup;
  public isAuthLoading = false;
  fieldTextType: boolean;
  userName: string = "";
  userId: string = '';
  numPtrn = Pattern.NUM;
  ucPtrn = Pattern.UC;
  lcPtrn = Pattern.LC;
  spcPtrn = Pattern.SPACE;
  spclPtrn = Pattern.SPECIAL;
  emailPtrn = Pattern.EMAIL;
  usrNmPtrn = Pattern.USERNAME;

  constructor(
      private renderer: Renderer2,
      private toastr: ToastrService,
      private route: ActivatedRoute,
      private appService: AppService
  ) { }

  ngOnInit() {
      this.renderer.addClass(
          document.querySelector('app-root'),
          'login-page'
      );

      
      this.route.queryParams.subscribe(params => { this.userName = params['username']; });

      this.changePasswordForm = new UntypedFormGroup({
          newPassword: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=.*[a-zA-Z]).{8,}$/)]),
          password: new UntypedFormControl(null, Validators.required)
      }, { validators: [this.passwordMatchValidator] });
  }

  toggleFieldTextType() {
      this.fieldTextType = !this.fieldTextType;
  }

  async changePassword() {
      if (this.changePasswordForm.valid) {
          this.isAuthLoading = true;
          await this.appService.changePassword(this.changePasswordForm.value);
          this.isAuthLoading = false;
      } else {
          this.toastr.error(SMsg.FIELDS_EMP);
      }
  }

  changeTextToUppercase(field) {
      const obj = {};
      obj[field] = this.changePasswordForm.controls[field].value.toUpperCase();
      this.changePasswordForm.patchValue(obj);
  }
  
  
  passwordMatchValidator: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    const password = group.get('newPassword')?.value ?? '';    
    const cPassword = group.get('password')?.value ?? '';
    return password !== cPassword ? { passwordMatched: true } : null;
  }

  containsPTRN(controller: string, pattern: any):boolean{
    const pwVal:string = this.changePasswordForm.controls[controller].value;
    return Pattern.checkPattern(pwVal, pattern);
  }

  contains8Char():boolean{
    const pwVal:string = this.changePasswordForm.controls['password'].value;
    return pwVal != null && pwVal.length >= 8;
  }

  ngOnDestroy() {
      this.renderer.removeClass(
          document.querySelector('app-root'),
          'login-page'
      );
  }
}
