import { Component, OnInit, Renderer2 } from '@angular/core';
import { BaseResponse } from '@models/response';
import { AppService } from '../../services/app.service';
import { ModelTenant } from '../../models/tenant/model-tenant';
import { ApiService } from '../../services/api.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SMsg } from '../../utils/static/s-msg';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'tenants',
  templateUrl: './tenants.component.html',
  styleUrls: ['./tenants.component.scss']
})
export class TenantsComponent implements OnInit {
  hideSearch: boolean = true;
  public isLoading = false;
  closeResult = '';
  startingSn: number = 1;
  dataCount: number = 0;
  tenantList: Array<ModelTenant> = [];
  public tenantForm: UntypedFormGroup;
  selTenantId: number;
  selTenantName: string;
  minDateForIncrement: string;
  maxDateForIncrement: string;
  dialogFor: string = "Add";

  constructor(private apiService: ApiService, private appService: AppService, private dataService: DataService, private renderer: Renderer2, private modalService: NgbModal, private toastr: ToastrService){}

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'property-list'
    );
    
    this.tenantForm = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      name: new UntypedFormControl('', Validators.required),
      contact_no: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', Validators.required),
      gender: new UntypedFormControl('', Validators.required),
      address: new UntypedFormControl('', Validators.required),
      status: new UntypedFormControl('', Validators.required)
    });

    this.getTenantList();
  }

  public setTenant(tenant: ModelTenant) {
    this.dataService.setData(tenant);
  }

  getTenantList(){
    this.apiService.tenantList().subscribe((result: BaseResponse) => {
      this.tenantList = result.data;
      this.dataCount = this.tenantList.length;
    })
  }  

  add(content){
    this.dialogFor = "Add";
    this.tenantForm.reset();
    this.tenantForm.patchValue({gender: '', status: ''});
    this.openPopup(content)
  }

  edit(content, tenant) {
    this.dialogFor = "Edit";
    this.tenantForm.reset();
    this.tenantForm.patchValue({
      id: tenant.id,
      name: tenant.name,
      contact_no: tenant.contact_no,
      email:tenant.email,
      gender:tenant.gender,
      address:tenant.address,
      status:tenant.status
    })
    this.openPopup(content);
	}

  onDateChange(dateType: string, event: any){
    const changedVal = event.target.value;
    if(dateType === "Start"){
      this.minDateForIncrement = changedVal;
    } else {
      this.maxDateForIncrement = changedVal;
    }
  }

  async saveTenant(){
    this.tenantForm.markAllAsTouched();
    if(this.tenantForm.valid){
      this.isLoading = true;
      if(this.tenantForm.value.id != null){
        await this.apiService.tenantUpdate(this.tenantForm.value).subscribe((result: any) => {
          this.handleResponse(result);
        });
      } else {
        await this.apiService.tenantAdd(this.tenantForm.value).subscribe((result: any) => {
          this.handleResponse(result);
        });
      }
      this.closeModal();
    } else {
      const invalidFields: string[] = [];
      const controls = this.tenantForm.controls;
      for (const controlName in controls) {
        if (controls.hasOwnProperty(controlName)) {
          if (controls[controlName].invalid) {
            invalidFields.push(controlName);
          }
        }
      }

      this.toastr.error(SMsg.FIELDS_EMP + " -> "+ invalidFields);
    }
  }

  del(content, tenant){
    this.selTenantId = tenant.id;
    this.selTenantName = tenant.name;
    this.openPopup(content);
  }

  async deleteTenant(){
    await this.apiService.tenantDelete(this.selTenantId).subscribe((result: any) => {
      this.handleResponse(result);
    });
    this.closeModal();
  }

  handleResponse(result: any){
    this.isLoading = false;
    if(result.code == 0){
      this.toastr.success(result.message)
      this.getTenantList();
    } else {
      this.toastr.error(result.message)
    }
  }
 

  openPopup(content){
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
  }
  
  closeModal(){
    this.modalService.dismissAll();
  }
  
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}
}