import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  
  private data: any;

  constructor() { }
  setData(data: any) {
    localStorage.setItem('data', JSON.stringify(data));
  }
  
  getData() {
    const data = localStorage.getItem('data');
    if (data === null) {
      return null;
    }
    return JSON.parse(data);
  }
  
  removeData() {
    localStorage.removeItem('data');
  }
  
}
