<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6 d-flex">
                <h1>User List</h1>
                <button class="btn btn-primary ml-4" type="button" [routerLink]="['/user-management-create']"><i class="fa fa-plus"></i>
                    Add</button>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item active"><i class="fa fa-list"></i> User List</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <div class="container-fluid">
                    <div class="row">
                        <div>
                            <button class="btn text-{{ hideSearch ? '' : 'primary' }}" (click)="hideOrShowSearch()"
                                title="{{ hideSearch ? 'Show' : 'Hide' }} search fields"><i
                                    class="fa fa-filter"></i></button>
                        </div>
                        <div class="col">
                            <div class="row" *ngIf="!hideSearch">
                                <div class="ps-mat-input col-xs-3 col-sm-auto">
                                    <input id="table-complete-search" type="text" placeholder="Type..." class="form-control" name="searchTerm" [(ngModel)]="pagination.searchTerm" />
                                    <label for="search">Search</label>
                                </div>
                                    
                                <div class="ps-mat-input ml-2 w-1h5p col-xs-3 col-sm-auto">
                                    <select name="locked" [(ngModel)]="pagination.isLocked">
                                        <option value="">All</option>
                                        <option value="Y">Yes</option>
                                        <option value="N">No</option>
                                    </select>
                                    <label for="locked">Is Locked</label>
                                </div>

                                <div class="ps-mat-input ml-2 w-1h3p col-xs-3 col-sm-auto">
                                    <select name="status" [(ngModel)]="pagination.isActive">
                                        <option value="">All</option>
                                        <option value="Y">Active</option>
                                        <option value="N">InActive</option>
                                    </select>
                                    <label for="status">Status</label>
                                </div>
                                
                                <button class="btn btn-primary ml-4" type="submit" (click)="getUsersList()"><i
                                        class="fa fa-search"></i>
                                    Search</button>
                                <button class="btn btn-secondary ml-2" type="submit" (click)="resetSearch()"><i
                                        class="fa fa-retweet"></i>
                                    Reset</button>
                            </div>
                        </div>
                        <div class="text-right">
                            <app-table-data-exporter tableId="dataTable" fileName="users_list"></app-table-data-exporter>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div *ngIf="userList != null && userList.length > 0">
                    <table id="dataTable" class="table table-striped">
                        <thead>
                            <tr>
                                <th class="text-center" scope="col">S.N.</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th class="text-center" scope="col">Contact No</th>
                                <th class="text-center" scope="col">Gender</th>
                                <th class="text-center" scope="col">Status</th>
                                <th class="text-center" scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let user of userList; index as i">
                                <th class="text-center" scope="row">{{ startingSn + i }})</th>
                                <td>{{ getFullName(user) }}</td>
                                <td>{{ user.email }}</td>
                                <td class="text-center">{{ user.contactNo != null ? user.contactNo : 'N/A' }}</td>
                                <td class="text-center">{{ ucFirst(user.gender) }}</td>
                                <td class="text-center text-{{ user.isActive != 'N' ? 'success' : 'danger' }}"><i class="fa fa-circle"></i> {{ user.isActive != 'N' ? 'Active' : 'InActive' }}</td>
                                <td class="text-center">
                                    <a [routerLink]="['/view-user-info', user.userId]"><button type="button" class="btn btn-outline text-primary pstitle-primary-right" pstitle="View {{ getFullName(user) }} details"><i class="fa fa-eye"></i></button></a>
                                    <a [routerLink]="['/cm-user-property', user.userId]"><button type="button" class="btn btn-outline text-secondary pstitle-secondary-right" pstitle="Assigned properties for user {{ getFullName(user) }}"><i class="fa fa-hotel"></i></button></a>
                                    <a [routerLink]="['/cm-user-role', user.userId]"><button type="button" class="btn btn-outline text-success pstitle-success-right" pstitle="Roles of user {{ getFullName(user) }}"><i class="fa fa-user-secret"></i></button></a>
                                    <button type="button" class="btn btn-outline text-warning pstitle-warning-right" pstitle="Reset password for user {{ getFullName(user) }}" (click)="add(content, user.userId)"><i class="fa fa-key"></i></button>
                                    <button type="button" class="btn btn-outline text-{{user.isLocked === 'Y' ? 'success' : 'danger'}} pstitle-{{user.isLocked === 'Y' ? 'success' : 'danger'}}-right" pstitle="{{user.isLocked === 'Y' ? 'Unlock' : 'Lock'}} user {{ getFullName(user) }}" (click)="lockUnlockUser(user.userId, user.isLocked === 'Y' ? false : true)"><i class="fa fa-{{ user.isLocked === 'Y' ? 'lock-open' : 'lock' }}"></i></button>
                                    <a [routerLink]="['/user-management-edit', user.userId]"><button type="button" class="btn btn-outline text-primary pstitle-primary-right" pstitle="Edit {{ getFullName(user) }}'s details"><i class="fa fa-edit"></i></button></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="d-flex justify-content-between p-2">
                        <ngb-pagination [collectionSize]="paginationRes.totalRow" [maxSize]="5"
                            [(page)]="pagination.pageNumber" (pageChange)="onPageChange($event)"
                            [pageSize]="pagination.pageSize" [boundaryLinks]="true">
                        </ngb-pagination>

                        <select class="form-control form-select" style="width: auto" name="pageSize"
                            [(ngModel)]="pagination.pageSize" (ngModelChange)="getUsersList()">
                            <option [ngValue]="5">5</option>
                            <option [ngValue]="10">10</option>
                            <option [ngValue]="50">50</option>
                            <option [ngValue]="100">100</option>
                        </select>
                    </div>
                </div>
                
                <div *ngIf="userList == null || userList.length == 0">
                    <h4 class="text-center p-4">No Data</h4>
                </div>
            </div>
        </div>
    </div>
    <!-- /.card -->
</section>


<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Reset Password</h4>
        <i class="fa fa-times fa-2x" (click)="modal.dismiss('Cross click')"></i>
    </div>
    <form [formGroup]="resetPasswordForm" (ngSubmit)="saveNewPassword()">
        <div class="modal-body">
            <div class="form-group row">
                <div class="col-sm-12">
                    <div class="ps-mat-input col-sm-12">
                        <input type="password" class="form-control" formControlName="newPassword" required/>
                        <label for="search">New Password</label>
                    </div>
                    <div *ngIf="resetPasswordForm.controls['newPassword'].dirty || resetPasswordForm.controls['newPassword'].touched"
                        class="text-danger txt-sm">
                        <span *ngIf="resetPasswordForm.controls['newPassword'].errors?.['required']">*New password is required</span>
                    </div>
                </div>
                
                <div class="col-sm-12 mt-4">
                    <div class="ps-mat-input col-sm-12">
                        <input type="password" class="form-control" formControlName="password" required/>
                        <label for="search">Confirm New Password</label>
                    </div>
                    <div *ngIf="resetPasswordForm.controls['newPassword'].dirty || resetPasswordForm.controls['password'].touched"
                        class="text-danger txt-sm">
                        <span *ngIf="resetPasswordForm.controls['password'].errors?.['required']">*Confirm password is required<br></span>
                        <span *ngIf="!resetPasswordForm.controls['password'].errors?.['required'] && resetPasswordForm.errors?.['passwordMatched']">*Confirm password do not match</span>
                    </div>
                </div>

                <div class="col-sm-12 mt-4">
                    <div class="ps-mat-input">
                        <select name="title" formControlName="forceChangePassword">
                            <option value="">- Select -</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </select>
                        <label for="forceChangePassword">Is Force Log Out</label>
                    </div>
                    <div *ngIf="resetPasswordForm.controls['forceChangePassword'].dirty || resetPasswordForm.controls['forceChangePassword'].touched"
                        class="text-danger txt-sm">
                        <span *ngIf="resetPasswordForm.controls['forceChangePassword'].errors?.['required']">*Please select force log out status</span>
                    </div>
                </div>

            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-success"><i class="fa fa-save"></i> Save</button>
            <button type="button" class="btn btn-danger" (click)="modal.close('Cross click')"><i class="fa fa-times"></i> Cancel</button>
        </div>
    </form>
</ng-template>