<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6 d-flex">
                <h4>Monthlly Rent postings for {{selectedTenant.name}} <span
                        class="text-gray-600">({{dataCount}})</span></h4>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item active"><a routerLink="/tenants"><i class="fa fa-users"></i> Debtors</a>
                    </li>
                    <li class="breadcrumb-item active"><i class="fa fa-calendar"></i> Monthly Rent Postings</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card of-x-a p-3">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-primary" (click)="add(content)"><i class="fa fa-plus"></i> Add
                            New Posting</button>
                    </div>
                    <div class="col-sm-6">
                        <div class="flex float-sm-right">
                            <app-table-data-exporter tableId="dataTable"
                                fileName="monthly_rent_posting"></app-table-data-exporter>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3" *ngIf="rentPostingsList.length > 0">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body table-responsive p-0 tbl-h">
                            <table id="dataTable" class="table table-bordered table-head-fixed tbl-sm text-nowrap">
                                <thead>
                                    <tr>
                                        <th class="text-center w-5p" scope="col">S.N.</th>
                                        <th>Bill No</th>
                                        <th>Unit Name</th>
                                        <th class="text-center">Start Date</th>
                                        <th class="text-center">End Date</th>
                                        <th class="text-center">Monthly Rent</th>
                                        <!-- <th class="text-center">Electricity</th>
                                            <th class="text-center">Water</th>
                                            <th class="text-center">Other</th> -->
                                        <th class="text-center">Total Amount</th>
                                        <th class="text-center">Payment Status</th>
                                        <th class="text-center">Posting Date</th>
                                        <th class="text-center">Status</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let rentPosting of rentPostingsList; index as i">
                                        <th>{{ startingSn + i }})</th>
                                        <td>{{rentPosting.bill_no}}</td>
                                        <td>{{rentPosting.unit_name}}</td>
                                        <td class="text-center">{{rentPosting.start_date}}</td>
                                        <td class="text-center">{{rentPosting.end_date}}</td>
                                        <td class="text-right">{{rentPosting.monthly_rent}}</td>
                                        <!-- <td class="text-center">{{rentPosting.electricity}}</td>
                                            <td class="text-center">{{rentPosting.water}}</td>
                                            <td class="text-center">{{rentPosting.other}}</td> -->
                                        <td class="text-right pstitle-info-right pstitle-info-right-td"
                                            pstitle="Details ({{ 'Monthly Rent: '+rentPosting.monthly_rent + ',\nElectricity: '+rentPosting.electricity +',\n Water: ' + rentPosting.water +',\n Other: ' + rentPosting.other }})">
                                            {{rentPosting.total}}</td>
                                        <td
                                            class="text-center text-{{ rentPosting.payment_status == 0 ? 'success' : rentPosting.payment_status == 1 ? 'secondary' : 'danger' }}">
                                            <i class="pl-2 pr-2 fa fa-money-bill"></i> {{ rentPosting.payment_status == 0 ? 'Paid' : rentPosting.payment_status == 1 ? 'Partially Paid' : 'Pending'
                                            }}
                                        </td>
                                        <td class="text-center">{{rentPosting.date}}</td>
                                        <td
                                            class="text-center text-{{ rentPosting.status == 0 ? 'success' : 'danger' }}">
                                            <i class="fa fa-circle"></i> {{ rentPosting.status == 0 ? 'Active' :
                                            'InActive' }}
                                        </td>
                                        <td class="text-center">
                                            <button class="btn btn-outline text-primary pstitle-primary-right"
                                                pstitle="Edit rent posting of {{ rentPosting.date }}"
                                                (click)="edit(content, rentPosting)">
                                                <i class="fa fa-edit p-1"></i></button>

                                            <button class="btn btn-outline text-black pstitle-secondary-right"
                                                pstitle="Print bill for this rent posting {{ rentPosting.date }}"
                                                (click)="printThisBill(rentPosting.id)">
                                                <i class="fa fa-print p-1"></i></button>

                                            <button class="btn btn-outline text-danger pstitle-danger-right"
                                                pstitle="Delete rent posting of {{ rentPosting.date }}"
                                                (click)="del(delete, rentPosting)">
                                                <i class="fa fa-trash p-1"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>
            </div>

            <div *ngIf="rentPostingsList == null || rentPostingsList.length == 0">
                <h4 class="text-center p-4">No Data</h4>
            </div>
        </div>
    </div>
    <!-- /.card -->
</section>



<ng-template #content let-modal>
    <div class="modal fade show" tabindex="-1" role="dialog" style="display: block; padding-right: 15px;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">{{dialogActFor}} rent posting for
                        {{selectedTenant.name}}</h4>
                    <i class="fa fa-times fa-2x" (click)="modal.dismiss('Cross click')"></i>
                </div>
                <form [formGroup]="rentPostingForm" appFocusFirstInvalidField>
                    <div class="modal-body">
                        <span>Fields marked with (<span class="text-danger">*</span>) is required</span>
                        <div class="form-group row">
                            <div class="col-sm-12 mt-4">
                                <div class="ps-mat-input">
                                    <select name="rent_information_id" formControlName="rent_information_id"
                                        (change)="onOptionSelected($event)">
                                        <option value="null">- Select -</option>
                                        <option *ngFor="let rentInfo of rentInfoList" [value]="rentInfo.id">{{
                                            rentInfo.unit_name}}</option>
                                    </select>
                                    <label for="rent_information_id">Rent posting for</label>
                                </div>
                            </div>

                            <div class="col-sm-6 mt-4">
                                <psmat-input type="date" formControlName="start_date" label="Start Date"
                                    controlName="start_date" [parentForm]="rentPostingForm" [min]="minStartDate"
                                    [max]="maxEndDate" required="true"></psmat-input>
                            </div>
                            <div class="col-sm-6 mt-4">
                                <psmat-input type="date" formControlName="end_date" label="End Date"
                                    controlName="end_date" [parentForm]="rentPostingForm" [min]="minStartDate"
                                    [max]="maxEndDate" required="true"></psmat-input>
                            </div>
                            <div class="col-sm-6 mt-4">
                                <psmat-input onlyNumber="true" formControlName="monthly_rent" label="Monthly Rent"
                                    controlName="monthly_rent" [parentForm]="rentPostingForm"
                                    required="true"></psmat-input>
                            </div>
                            <div class="col-sm-6 mt-4">
                                <psmat-input onlyNumber="true" formControlName="electricity" label="Electricity"
                                    controlName="electricity" [parentForm]="rentPostingForm"></psmat-input>
                            </div>
                            <div class="col-sm-6 mt-4">
                                <psmat-input onlyNumber="true" formControlName="water" label="Water" controlName="water"
                                    [parentForm]="rentPostingForm"></psmat-input>
                            </div>
                            <div class="col-sm-6 mt-4">
                                <psmat-input onlyNumber="true" formControlName="other" label="Other" controlName="other"
                                    [parentForm]="rentPostingForm"></psmat-input>
                            </div>
                            <div class="col-sm-12 mt-4">
                                <psmat-textarea [rows]="4" formControlName="other_information" label="Remarks"
                                    controlName="other_information" [parentForm]="rentPostingForm"
                                    maxlength="255"></psmat-textarea>
                            </div>
                            <div class="col-sm-12 mt-4">
                                <psmat-input type="date" formControlName="date" label="Posting Date" controlName="date"
                                    [parentForm]="rentPostingForm" required="true" [min]="minStartDate"
                                    [max]="maxEndDate"></psmat-input>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-success" (click)="saveRentPosting(false)"><i
                                class="fa fa-save"></i>
                            Save</button>
                        <button type="submit" class="btn btn-success" (click)="saveRentPosting(true)"><i
                                class="fa fa-save"></i>
                            Save & <i class="fa fa-print"></i> Print</button>
                        <button type="button" class="btn btn-danger" (click)="modal.close('Cross click')"><i
                                class="fa fa-times"></i> Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #bill let-modal>
    <div class="modal modal-lg" tabindex="-1" role="document" style="display: block;">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title"></h4>
                    <i class="fa fa-times fa-2x" (click)="modal.dismiss('Cross click')"></i>
                </div>
                <div class="modal-body">
                    <div [innerHTML]="sanitizedHtmlContent">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" (click)="modal.dismiss('Cross click')" class="btn btn-secondary"><i
                            class="fa fa-times"></i> Close</button>
                    <button type="button" class="btn btn-primary"><i class="fa fa-print"></i> Print</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #delete let-modal>
    <div class="modal-header modal-xl bg-danger">
        <h4 class="modal-title" id="modal-basic-title">Tenant</h4>
        <i class="fa fa-times fa-2x" (click)="modal.dismiss('Cross click')"></i>
    </div>
    <div class="modal-body bg-danger">
        <h4>Are you sure to delete {{selTenantName}}?</h4>
    </div>
    <div class="modal-footer bg-danger">
        <button type="submit" class="btn btn-danger" (click)="deleteTenant()"><i class="fa fa-trash"></i> Sure</button>
        <button type="button" class="btn btn-success" (click)="modal.close('Cross click')"><i class="fa fa-times"></i>
            Cancel</button>
    </div>
</ng-template>