import { FilterPagination, PaginationResponse } from '@models/filter-pagination';
import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ChannelManager } from '@models/channel-manager';
import { BaseResponse } from '@models/response';
import { ModelTenant } from '../../../models/tenant/model-tenant';
import { ApiService } from '../../../services/api.service';
import { PaymentPostingFilter } from '../../../models/payment-posting-filter';
import { ModelBillMatching } from '../../../models/rent/model-bill-matching';
import { ModelReceivedRent } from '../../../models/rent/model-received-rent';
import { ReqModelBillMatch } from '../../../models/rent/req-model-bill-match';
import { ReqModelMatchedBills } from '../../../models/rent/req-model-matched-receipts';
import { ReqModelUnmatchReceipt } from '../../../models/rent/req-model-unmatch-receipt';
import { ModelMonthlyRentPosting } from '../../../models/rent/model-monthly-rent-posting';

@Component({
  selector: 'bill-matching',
  templateUrl: './bill-matching.component.html',
  styleUrls: ['./bill-matching.component.scss']
})
export class BillMatchingComponent implements OnInit, OnDestroy{
  tenantList: Array<ModelTenant> = [];
  billList: Array<ModelMonthlyRentPosting> = [];
  matchedBills: Array<ModelBillMatching> = [];
  receiptList: Array<ModelReceivedRent> = [];
  channelManagerList:Array<ChannelManager>  = [];
  payPostingFilter: PaymentPostingFilter = new PaymentPostingFilter('', '');
  pagination: FilterPagination = new FilterPagination(1, 5, 'name', 'DESC', '', '');
  paginationRes: PaginationResponse = new PaginationResponse();
  closeResult = '';
  public billForm: UntypedFormGroup;
  public isLoading = false;
  hideSearch: boolean = true;
  startingSn: number;
  selectedReceipt: any;
  selectedTenantId: any;
  selectedMPId: any;
  selectedRemainingBillAmt: any;
  inputValue: string;
  dataChanged: boolean = false;

  constructor(private apiService: ApiService, private renderer: Renderer2, private modalService: NgbModal, private toastr: ToastrService){

  }

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'bill-matching'
    );

    this.billForm = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      receipt_amount: new UntypedFormControl('', Validators.required),
      date: new UntypedFormControl('', Validators.required),
      other_information: new UntypedFormControl('', Validators.required),
      match_receipt_amount: new UntypedFormControl('', Validators.required),
      match_remain_amount: new UntypedFormControl('', Validators.required),
      matched_amount: new UntypedFormControl('', Validators.required)
    });
    this.getTenantList();
  }


  onOptionSelected(event: any) {
    const selectedValue = event.target.value;
    this.selectedReceipt = this.receiptList.find(receipt => receipt.id == selectedValue);
    
    this.selectedRemainingBillAmt = this.selectedReceipt.match_remain_amount;

    let newMatchAmt = this.selectedRemainingBillAmt - this.selectedReceipt.match_remain_amount;
    let remainedBillAmt = 0;
    if(this.selectedReceipt.match_remain_amount > this.selectedRemainingBillAmt){
      newMatchAmt = this.selectedRemainingBillAmt;
    } else {
      newMatchAmt = this.selectedReceipt.match_remain_amount;
    }
    remainedBillAmt = this.selectedRemainingBillAmt - newMatchAmt;

    if (this.selectedReceipt) {
      this.billForm.reset();
      this.billForm.patchValue({
        id: this.selectedReceipt.id,
        receipt_amount: this.selectedReceipt.received_amount,
        date: this.selectedReceipt.received_date,
        other_information: this.selectedReceipt.remarks,
        match_receipt_amount: this.selectedRemainingBillAmt,
        match_remain_amount: this.selectedReceipt.received_amount,
        matched_amount: newMatchAmt
      })

      this.onMatchAmountChange(newMatchAmt.toString());
    }
  }

  
  onMatchAmountChange(changeValue: string) {
    let newValue = parseFloat(changeValue);
    if(newValue > this.selectedReceipt.received_amount){
      newValue = this.selectedReceipt.received_amount;
      this.toastr.error("Sorry! you cannot match more than available bill amount")

    } else if(newValue > this.selectedRemainingBillAmt) {
      newValue = this.selectedRemainingBillAmt;
      this.toastr.error("Sorry! you cannot match more than remaining receipt amount")
    }

    if(this.selectedReceipt.match_remain_amount < newValue){
      newValue = this.selectedReceipt.match_remain_amount;
      this.toastr.error("Sorry! you cannot match more than remaining receipt amount")
    }

    const newRemainAmt = this.selectedReceipt.received_amount - newValue;
    
    if(newValue != 0){
      this.billForm.patchValue({
        match_receipt_amount: this.selectedRemainingBillAmt,
        // match_remain_amount: newRemainAmt,
        matched_amount: newValue
      })
    }
  }

  resetSearch() {
    this.payPostingFilter.tenant_id = '';
    this.payPostingFilter.is_bill_matched = '';
    this.getTenantList();
  }
  
  getTenantList(){
    this.apiService.tenantList().subscribe((result: BaseResponse) => {
      this.tenantList = result.data;
    })
  }

  loadMonthlyPostings(){
    this.apiService.getMonthlyPostingWithFilter(this.payPostingFilter).subscribe((result: BaseResponse) => {
      this.billList = result.data;
    })
  }

  add(content){
    this.billForm.reset();
    this.billForm.patchValue({status: ''});
    this.openPopup(content)
  }

  match(content, bill) {
    this.billForm.reset();
    this.selectedTenantId = bill.tenant_id;
    this.selectedRemainingBillAmt = bill.match_remain_amount;
    this.selectedMPId = bill.monthly_posting_id;
    this.billForm.patchValue({
      id: this.selectedMPId,
      match_receipt_amount: this.selectedRemainingBillAmt,
      match_remain_amount: bill.match_remain_amount,
      matched_amount: bill.matched_amount
    })
    this.getReceipts();
    this.getMatchedBills();
    this.openPopup(content);
	}

  getMatchedBills(){
    const reqParam = new ReqModelMatchedBills(this.selectedTenantId, this.selectedMPId);
    this.apiService.matchedReceiptList(reqParam).subscribe((result: BaseResponse) => {
      this.matchedBills = result.data;
    })
  }

  unmatch(bill){
    const reqParam = new ReqModelUnmatchReceipt(bill.bill_matching_id, this.selectedTenantId, this.selectedMPId, bill.received_rent_id);
    this.apiService.unMatchReceipt(reqParam).subscribe((result: BaseResponse) => {
      this.dataChanged = true;
      this.getReceipts();
      this.getMatchedBills();
      this.billForm.reset();
      this.matchedBills = result.data;
    })
  }

  getReceipts(){
    this.apiService.receiptList(this.selectedTenantId).subscribe((result: BaseResponse) => {
      this.receiptList = result.data;
    })
  }

  openPopup(content){
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
  }

  closeModal(){
    this.modalService.dismissAll();
    if(this.dataChanged){
      this.loadMonthlyPostings();
    }
  }

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

  async saveBillMatch(){
    this.billForm.markAllAsTouched();
    if(this.billForm.valid){
      this.isLoading = true;
      const selRentId = this.selectedReceipt.id;
      const matchedAmount = this.billForm.controls.matched_amount.value;

      const reqParam = new ReqModelBillMatch(this.selectedTenantId, this.selectedMPId, selRentId, matchedAmount);
      await this.apiService.addBillMatching(reqParam).subscribe((result: any) => {
        this.dataChanged = true;
        this.getReceipts();
        this.getMatchedBills();
        this.billForm.reset();
        this.handleResponse(result);
      });
    }
  }

  handleResponse(result: any) {
    this.isLoading = false;
    if (result.code == 0) {
      this.toastr.success(result.message)
    } else {
      this.toastr.error(result.message)
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(
        document.querySelector('app-root'),
        'channel-manager-list'
    );
  }
}