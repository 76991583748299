import { Component, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Chart, registerables } from 'chart.js';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ModelDashboardData } from '../../models/response/model-dashboard-data';
import { AppService } from '../../services/app.service';
import { CardView } from '../../models/response/model-card-view';
import { Property } from '../../models/response/model-property';
import { RL } from '../../utils/static/route-link';
Chart.register(...registerables);

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    dashboardData: ModelDashboardData;
    debtorsGraphType: string = "bar";
    rentGraphType: string = "bar";
    debtorChartInstance: Chart | null = null;
    rentChartInstance: Chart | null = null;
    chartOptions: any;
    cardView: Array<CardView> = [];
    property: Array<Property> = [];
    initCount: number = 1;
    addPropertyPageLink: RL = RL.PROP_INFO_ADD;

    constructor(private apiService: ApiService, private appService: AppService, private modalService: NgbModal, private renderer: Renderer2, private toastr: ToastrService) {
        this.getDashboardData();
    }

    ngOnInit(): void {
    }

    changeDebtorsGraph(graphType: string) {
        if (graphType === 'line') {
            this.chartOptions = {
                type: 'line',
                data: this.getDebtorGraphData(),
                options: {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            }
        } else if (graphType === 'pie') {
            this.chartOptions = {
                type: 'pie',
                data: this.getDebtorGraphData(),
                options: {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            }
        } else {
            this.chartOptions = {
                type: 'bar',
                data: this.getDebtorGraphData(),
                options: {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            }
        }
        this.createDebtorChart();
        this.debtorsGraphType = graphType;
    }

    changeRentGraph(graphType: string) {
        if (graphType === 'line') {
            this.chartOptions = {
                type: 'line',
                data: this.getRentGraphData(),
                options: {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            }
        } else if (graphType === 'pie') {
            this.chartOptions = {
                type: 'pie',
                data: this.getRentGraphData(),
                options: {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            }
        } else {
            this.chartOptions = {
                type: 'bar',
                data: this.getRentGraphData(),
                options: {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            }
        }
        this.createRentChart();
        this.rentGraphType = graphType;
    }

    createDebtorChart(): void {
        if (this.debtorChartInstance) {
            this.debtorChartInstance.destroy();
        }
        const chartCanvas = document.getElementById('debtorChart') as HTMLCanvasElement;
        const chartContext = chartCanvas.getContext('2d') as CanvasRenderingContext2D;
        this.debtorChartInstance = new Chart(chartContext, this.chartOptions);
    }

    createRentChart(): void {
        if (this.rentChartInstance) {
            this.rentChartInstance.destroy();
        }
        const chartCanvas = document.getElementById('rentChart') as HTMLCanvasElement;
        const chartContext = chartCanvas.getContext('2d') as CanvasRenderingContext2D;
        this.rentChartInstance = new Chart(chartContext, this.chartOptions);
    }

    getDebtorGraphData(): any {
        let data: string[] = [];
        this.cardView.forEach(cv => {
            data.push(cv.data);
        });

        return {
            labels: [
                'Total Debtors',
                'Active Debtors',
                'Inactive Debtors',
                'Removed Debtors'],
            datasets: [{
                label: 'Debtor Graph',
                data: data,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)'
                ],
                borderWidth: 1
            }]
        };
    }

    getRentGraphData(): any {
        return {
            labels: [
                'Lifetime Revenue',
                'Revenue Today'],
            datasets: [{
                label: 'Revenue Graph',
                data: [
                    this.dashboardData.lifeTimeRevenue,
                    this.dashboardData.revenueToday
                ],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)'
                ],
                borderWidth: 1
            }]
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.debtorsGraphType) {
            this.createDebtorChart();
        }
        if (changes.rentGraphType) {
            this.createRentChart();
        }
    }

    getDashboardData() {
        var userId = this.appService.userId;
        var roleId = this.appService.roleId;
        this.apiService.dashboardData(userId, roleId).subscribe((result: any) => {
            this.dashboardData = result.data;
            this.cardView = result.data.cardView;
            this.property = result.data.property;
            this.changeDebtorsGraph(this.debtorsGraphType);
            this.changeRentGraph(this.rentGraphType);
        })
    }

}
