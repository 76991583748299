<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6 d-flex">
                <h4>Debtors <span class="text-gray-600">({{dataCount}})</span></h4>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item active"><i class="fa fa-users"></i> Debtors</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card of-x-a p-3">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-primary" (click)="add(content)"><i
                                class="fa fa-user-plus"></i> Add
                            Debtor</button>
                    </div>
                    <div class="col-sm-6">
                        <div class="flex float-sm-right">
                            <app-table-data-exporter tableId="dataTable"
                                fileName="debtors"></app-table-data-exporter>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3" *ngIf="tenantList.length > 0">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body table-responsive p-0" style="max-height: 50vh; height: auto;">
                            <table id="dataTable" class="table table-head-fixed text-nowrap tbl-sm">
                                <thead>
                                    <tr>
                                        <th class="w-5p">S.N.</th>
                                        <th>Name</th>
                                        <th class="text-center w-1h3p">Contact</th>
                                        <th>Email</th>
                                        <th class="text-center">Status</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let tenant of tenantList; index as i">
                                        <th scope="row">{{ startingSn + i }})</th>
                                        <td>{{tenant.name}}</td>
                                        <td class="text-center">{{tenant.contact_no}}</td>
                                        <td>{{tenant.email}}</td>
                                        <td class="text-center text-{{ tenant.status == 0 ? 'success' : 'danger' }}">
                                            <i class="fa fa-circle"></i> {{ tenant.status == 0 ? 'Active' :
                                            'Inactive' }}
                                        </td>
                                        <td class="text-right w-1hp">
                                            <a routerLink="/rent-information/{{tenant.id}}" (click)="setTenant(tenant)">
                                                <button class="btn btn-outline text-secondary pstitle-secondary-right"
                                                    pstitle="Assign room of '{{ tenant.name }}'">
                                                    <i class="fa fa-home p-1"></i></button>
                                            </a>

                                            <a routerLink="/rent-posting/{{tenant.id}}" (click)="setTenant(tenant)">
                                                <button class="btn btn-outline text-normal pstitle-normal-right"
                                                    pstitle="Monthly rent posting for '{{ tenant.name }}'">
                                                    <i class="fa fa-file-import p-1"></i></button>
                                            </a>

                                            <a routerLink="/rent-received/{{tenant.id}}" (click)="setTenant(tenant)">
                                                <button class="btn btn-outline text-success pstitle-success-right"
                                                    pstitle="Rent received from '{{ tenant.name }}'">
                                                    <i class="fa fa-money-bill p-1"></i></button>
                                            </a>

                                            <a routerLink="/document/{{tenant.id}}" (click)="setTenant(tenant)">
                                                <button class="btn btn-outline text-warning pstitle-warning-right"
                                                    pstitle="Documents of '{{ tenant.name }}'">
                                                    <i class="fa fa-folder-open p-1"></i></button>
                                            </a>

                                            <button class="btn btn-outline text-primary pstitle-primary-right"
                                                pstitle="Edit '{{ tenant.name }}' profile"
                                                (click)="edit(content, tenant)">
                                                <i class="fa fa-edit p-1"></i></button>

                                            <!-- <button class="btn btn-outline text-success pstitle-success-right"
                                                    pstitle="View '{{ tenant.name }}' profile">
                                                    <i class="fa fa-eye p-1"></i></button> -->

                                            <button class="btn btn-outline text-danger pstitle-danger-right"
                                                pstitle="Delete '{{ tenant.name }}' profile"
                                                (click)="del(delete, tenant)">
                                                <i class="fa fa-trash p-1"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>
            </div>

            <div *ngIf="tenantList == null || tenantList.length == 0">
                <h4 class="text-center p-4">No Data</h4>
            </div>
        </div>
    </div>
    <!-- /.card -->
</section>



<ng-template #content let-modal>
    <div class="modal-header modal-xl">
        <h4 class="modal-title" id="modal-basic-title">{{dialogFor}} Debtor</h4>
        <i class="fa fa-times fa-2x" (click)="modal.dismiss('Cross click')"></i>
    </div>
    <form [formGroup]="tenantForm" appFocusFirstInvalidField>
        <div class="modal-body">
            <span>Fields marked with an astrisk (<span class="text-danger">*</span>) is required</span>
            <div class="form-group row">
                <input type="hidden" formControlName="id">
                <div class="col-sm-12 mt-4">
                    <psmat-input type="text" formControlName="name" label="Name" controlName="name"
                        [parentForm]="tenantForm" required="true"></psmat-input>
                </div>
                <div class="col-sm-12 mt-4">
                    <psmat-input OnlyNumber="true" formControlName="contact_no" label="Contact No"
                        controlName="contact_no" [parentForm]="tenantForm" required="true"></psmat-input>
                </div>
                <div class="col-sm-12 mt-4">
                    <psmat-input type="email" formControlName="email" label="Email" controlName="email"
                        [parentForm]="tenantForm" required="true"></psmat-input>
                </div>
                <div class="col-sm-12 mt-4">
                    <div class="ps-mat-input">
                        <select id="gender" name="gender" formControlName="gender">
                            <option value="">- Select -</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                            <option value="Not Specified">Not Specified</option>
                        </select>
                        <label for="gender">Gender</label>
                    </div>
                    <div *ngIf="tenantForm.controls['gender'].dirty || tenantForm.controls['gender'].touched"
                        class="text-danger">
                        <span *ngIf="tenantForm.controls['gender'].errors?.['required']">*Gender is required</span>
                    </div>
                </div>
                <div class="col-sm-12 mt-4">
                    <psmat-input type="text" formControlName="address" label="Address" controlName="address"
                        [parentForm]="tenantForm" required="true"></psmat-input>
                </div>
                <!-- <div class="col-sm-12 mt-4">
                    <div class="ps-mat-input col-sm-12">
                        <input type="file" class="form-control" formControlName="document"
                            placeholder="e.g.: Citizenship/Passport/National ID/etc." required />
                        <label for="search">Document</label>
                    </div>
                    <div *ngIf="tenantForm.controls['document'].dirty && tenantForm.controls['document'].touched"
                        class="text-danger">
                        <span *ngIf="tenantForm.controls['document'].errors?.['required']">*Document is
                            required</span>
                    </div>
                </div> -->

                <div class="col-sm-12 mt-4">
                    <div class="ps-mat-input">
                        <select id="status" name="status" formControlName="status">
                            <option value="">- Select -</option>
                            <option value="0">Active</option>
                            <option value="1">Inactive</option>
                        </select>
                        <label for="status">Status</label>
                    </div>
                    <div *ngIf="tenantForm.controls['status'].dirty || tenantForm.controls['status'].touched"
                        class="text-danger">
                        <span *ngIf="tenantForm.controls['status'].errors?.['required']">*Status is required</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-success" (click)="saveTenant()"><i class="fa fa-save"></i>
                Save</button>
            <button type="button" class="btn btn-danger" (click)="modal.close('Cross click')"><i
                    class="fa fa-times"></i> Cancel</button>
        </div>
    </form>
</ng-template>


<ng-template #delete let-modal>
    <div class="modal-header modal-xl bg-danger">
        <h4 class="modal-title" id="modal-basic-title">Tenant</h4>
        <i class="fa fa-times fa-2x" (click)="modal.dismiss('Cross click')"></i>
    </div>
    <div class="modal-body bg-danger">
        <h4>Are you sure to delete {{selTenantName}}?</h4>
    </div>
    <div class="modal-footer bg-danger">
        <button type="submit" class="btn btn-danger" (click)="deleteTenant()"><i class="fa fa-trash"></i> Sure</button>
        <button type="button" class="btn btn-success" (click)="modal.close('Cross click')"><i class="fa fa-times"></i>
            Cancel</button>
    </div>
</ng-template>