import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ChannelManagerUserFilter, PaginationResponse } from '@models/filter-pagination';
import { UserInformation } from '@models/userInformation';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ReqLockUnlockUser } from '../../../models/request/ReqLockUnLockUser';
import { Utils } from '../../../utils/utils';

@Component({
  selector: 'app-user-management-list',
  templateUrl: './user-management-list.component.html',
  styleUrls: ['./user-management-list.component.scss']
})
export class UserManagementListComponent implements OnInit, OnDestroy {
  userList: Array<UserInformation> = [];
  pagination: ChannelManagerUserFilter = new ChannelManagerUserFilter(1, 10, 'firstName', 'DESC', '', '', '');
  paginationRes: PaginationResponse = new PaginationResponse();
  public resetPasswordForm: UntypedFormGroup;
  hideSearch: boolean = true;
  jsonString: string = '';
  closeResult = '';
  startingSn: number;

  constructor(private apiService: ApiService, private modalService: NgbModal, private renderer: Renderer2, private toastr: ToastrService){}

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'user-management-list'
    );
    this.getUsersList();
    
    this.resetPasswordForm = new UntypedFormGroup({
      userID: new UntypedFormControl(null),
      newPassword: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl('', Validators.required),
      forceChangePassword: new UntypedFormControl('', Validators.required)
    }, { validators: [this.passwordMatchValidator] });
  } 

  ucFirst(data: string): string{
    return Utils.ucFirst(data);
  }
  
  
  passwordMatchValidator: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    const password = group.get('newPassword')?.value ?? '';    
    const cPassword = group.get('password')?.value ?? '';
    return password !== cPassword ? { passwordMatched: true } : null;
  }

  hideOrShowSearch() {
    this.hideSearch = !this.hideSearch;
  }

  onPageChange(pageNo: number) {
    this.pagination.pageNumber = pageNo;
    this.getUsersList();
  }

  resetSearch() {
    this.pagination.isActive = '';
    this.pagination.searchTerm = '';
    this.pagination.isLocked = '';
    this.getUsersList();
  }

  getUsersList(){
    this.apiService.channelManagerUserList(this.pagination).subscribe((result: any) => {
      this.userList = result.data;
      this.paginationRes = result.pagination;
      this.calculateStartingSn();
    })
  }  

  add(content, userId: string){
    this.resetPasswordForm.reset();
    this.resetPasswordForm.patchValue({
      userID: userId
    })
    this.openPopup(content)
  }
  
  openPopup(content){
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
  }  

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

  async saveNewPassword(){
    this.resetPasswordForm.get('newPassword')?.markAsDirty();
    this.resetPasswordForm.get('password')?.markAsDirty();
    this.resetPasswordForm.markAllAsTouched();
    if(this.resetPasswordForm.valid){
      await this.apiService.resetChannelManagerUserPassword(this.resetPasswordForm.value).subscribe((result: any) => {
        if(result.responseCode == "200"){
          this.modalService.dismissAll();
          this.toastr.success(result.responseMessage)
        } else {
          this.toastr.error(result.responseMessage)
        }
      });
    } else {
      this.toastr.error("Something went wrong.")
    }
  }

  async lockUnlockUser(userId: number, lockUser: boolean){
    const param = new ReqLockUnlockUser(userId, lockUser ? "Y" : "N");
    await this.apiService.lockUnlockUser(param).subscribe((result: any) => {
      if(result.responseCode == "200"){
        this.toastr.success(result.responseMessage)
        this.getUsersList();
      } else {
        this.toastr.error(result.responseMessage)
      }
    });
  }

  calculateStartingSn(): void {
    this.startingSn = (this.paginationRes.pageNumber - 1) * this.paginationRes.pageSize + 1;
  }
  
  public getFullName(userInfo: UserInformation): string {
    let fullName = userInfo.salutation + ' ' + userInfo.firstName;
    if (userInfo.middleName) {
      fullName += ' ' + userInfo.middleName;
    }
    fullName += ' ' + userInfo.lastName;
    return fullName.trim();
  }

  ngOnDestroy() {
    this.renderer.removeClass(
        document.querySelector('app-root'),
        'user-management-list'
    );
  }

}
