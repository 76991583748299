import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as jsonData from '@assets/setting.json';
import { AppService } from '../services/app.service';

@Injectable()
export class APIPrefixInterceptor implements HttpInterceptor {
  data: any = jsonData;

  constructor(private appService: AppService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.url.includes('setting.json')) {
      var baseUrl = this.data.serverUrl;
      if (!/^(http|https):/i.test(request.url) && !request.url.includes('setting.json')) {
        request = request.clone({ url: baseUrl + request.url });
      }

      const user_id = this.appService.userId;

      if (user_id) {
        let modifiedRequest: HttpRequest<any>;

        if (request.body instanceof FormData) {
          const formData = new FormData();
          formData.append('user_id', user_id);

          request.body.forEach((value, key) => {
            formData.append(key, value);
          });

          modifiedRequest = request.clone({ body: formData });
        } else if (typeof request.body === 'object') {
          const modifiedBody = { ...request.body, user_id };
          modifiedRequest = request.clone({ body: modifiedBody });
        } else {
          modifiedRequest = request;
        }

        return next.handle(modifiedRequest);
      }
    }
    return next.handle(request);
  }
}
