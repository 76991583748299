<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6 d-flex">
                <h1>Ledger</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item active"><i class="fa fa-list"></i> Ledger</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <div class="row">

                                <div class="col-sm-2">
                                    <div class="ps-mat-input">
                                        <select name="tenant_id" [(ngModel)]="ledgerFilter.tenant_id" (change)="onDebtorSelected($event)">
                                            <option value="" selected>- Select Debtor -</option>
                                            <option *ngFor="let tenant of tenantList" [value]="tenant.id">{{ tenant.name
                                                }}</option>
                                        </select>
                                        <label>Debtor</label>
                                    </div>
                                </div>


                                <div class="col-sm-2" *ngIf="rentInfoList.length > 0">
                                    <div class="ps-mat-input">
                                        <select name="rent_info_id" [(ngModel)]="ledgerFilter.rent_info_id">
                                            <option [ngValue]="null">- Select -</option>
                                            <option *ngFor="let rentInfo of rentInfoList" [value]="rentInfo.id">{{ rentInfo.unit_name }}</option>
                                        </select>
                                        <label for="rent_info_id">Room</label>
                                    </div>
                                </div>

                                <div class="col-sm-2">
                                    <psmat-input type="date" [value]="currentDate" (change)="onFieldValueChange('start_date', $event)" label="Start Date"></psmat-input>
                                </div>

                                <div class="col-sm-2">
                                    <psmat-input type="date" [value]="currentDate" (change)="onFieldValueChange('end_date', $event)" label="End Date"></psmat-input>
                                </div>


                                <button class="btn btn-primary btn-sm ml-4" type="submit"
                                    (click)="loadLedgers()"><i class="fa fa-search"></i>
                                    Search</button>
                                <button class="btn btn-secondary btn-sm ml-2" type="submit" (click)="resetSearch()"><i
                                        class="fa fa-retweet"></i>
                                    Reset</button>
                            </div>
                        </div>
                        <div class="text-right">
                            <app-table-data-exporter tableId="dataTable"
                                fileName="ledger_report"></app-table-data-exporter>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body table-responsive p-0 tbl-h">
                <div *ngIf="billList != null && billList.length > 0">
                    <table id="dataTable" class="table table-bordered table-head-fixed text-nowrap">
                        <thead>
                            <tr>
                                <th class="text-center w-5p" scope="col">S.N.</th>
                                <th class="text-center w-1h5p" scope="col">Date</th>
                                <th scope="col">Description</th>
                                <th class="text-center w-1h5p" scope="col">Dr.</th>
                                <th class="text-center w-1h5p" scope="col">Cr.</th>
                                <th class="text-center w-2hp" scope="col">Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let bill of billList; index as i">
                                <td>{{ startingSn + i }}</td>
                                <td class="text-center" scope="row">{{ bill.date }}</td>
                                <td>{{ bill.description != null ? bill.description : 'N/A' }}</td>
                                <td class="text-right">{{ bill.debit === null ? 0 : bill.debit }}</td>
                                <td class="text-right">{{ bill.credit === null ? 0 : bill.credit }}</td>
                                <td class="text-right">{{ bill.balance === null ? 0 : bill.balance }}</td>
                            </tr>
                            <tr>
                                <td colspan="3" class="text-right"><strong>Total: </strong></td>
                                <td class="text-right">NRS.{{drTotal}}</td>
                                <td class="text-right">NRS.{{crTotal}}</td>
                                <td class="text-right">NRS.{{balanceTotal}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div *ngIf="billList == null || billList.length == 0">
                    <h4 class="text-center p-4">No Data</h4>
                </div>
            </div>
        </div>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->