<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6 d-flex">
                <h4>All Users <span class="text-gray-600">({{dataCount}})</span></h4>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item active"><i class="fa fa-users"></i> Users</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card of-x-a p-3">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-primary" (click)="add(content)"><i
                                class="fa fa-user-plus"></i> Add
                            User</button>
                    </div>
                    <div class="col-sm-6">
                        <div class="flex float-sm-right">
                            <app-table-data-exporter tableId="dataTable" fileName="users"></app-table-data-exporter>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3" *ngIf="usersList.length > 0">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body table-responsive p-0" style="max-height: 50vh; height: auto;">
                            <table id="dataTable" class="table table-head-fixed text-nowrap tbl-sm">
                                <thead>
                                    <tr>
                                        <th class="w-5p">S.N.</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Contact</th>
                                        <th>Role</th>
                                        <th class="text-center">Status</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let user of usersList; index as i">
                                        <th scope="row">{{ startingSn + i }})</th>
                                        <td>{{user.name}}</td>
                                        <td>{{user.email}}</td>
                                        <td>{{user.contact}}</td>
                                        <td
                                            class="text-{{ user.role == 1 ? 'success' : user.role == 2 ? 'primary' : 'warning' }}">
                                            <i class="fa fa-user"></i> {{ user.role == 1 ? 'Admin' : user.role == 2 ? 'Owner' : 'Debtor' }}
                                        </td>
                                        <td class="text-center text-{{ user.status == 0 ? 'success' : 'danger' }}">
                                            <i class="fa fa-circle"></i> {{ user.status == 0 ? 'Active' :
                                            'Inactive' }}
                                        </td>
                                        <td class="text-center w-1hp">
                                            <button class="btn btn-outline text-primary pstitle-primary-right"
                                                pstitle="Edit '{{ user.name }}' profile" (click)="edit(content, user)">
                                                <i class="fa fa-edit p-1"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>
            </div>

            <div *ngIf="usersList == null || usersList.length == 0">
                <h4 class="text-center p-4">No Data</h4>
            </div>
        </div>
    </div>
    <!-- /.card -->
</section>



<ng-template #content let-modal>
    <div class="modal-header modal-xl">
        <h4 class="modal-title" id="modal-basic-title">{{dialogFor}} User</h4>
        <i class="fa fa-times fa-2x" (click)="modal.dismiss('Cross click')"></i>
    </div>
    <form [formGroup]="userForm" appFocusFirstInvalidField>
        <div class="modal-body">
            <span>Fields marked with an astrisk (<span class="text-danger">*</span>) is required</span>
            <div class="form-group row">

                <div class="col-sm-12 mt-4">
                    <psmat-input type="text" formControlName="name" label="Name" controlName="name"
                        [parentForm]="userForm" required="true"></psmat-input>
                </div>

                <div class="col-sm-12 mt-4">
                    <psmat-input type="email" formControlName="email" label="Email" controlName="email"
                        [parentForm]="userForm" required="true" [readonly]="dialogFor == 'Edit'"></psmat-input>
                </div>

                <div class="col-sm-6 mt-4">
                    <psmat-input OnlyNumber="true" formControlName="contact" label="Contact" controlName="contact"
                        [parentForm]="userForm" maxlength="15"></psmat-input>
                </div>


                <div class="col-sm-6 mt-4" *ngIf="dialogFor != 'Edit'">
                    <psmat-input type="password" formControlName="password" label="Password" controlName="password"
                        [parentForm]="userForm" required="true"></psmat-input>
                    <div
                        *ngIf="!userForm.controls['password'].errors?.['required'] && userForm.controls['password'].invalid">
                        <span class="{{ containsPTRN('password', numPtrn) ? 'text-success' : 'text-danger' }}">*Password
                            must contain at least one number.<br /></span>
                        <span
                            class="{{ containsPTRN('password', ucPtrn) && containsPTRN('password', lcPtrn) ? 'text-success' : 'text-danger' }}">*one
                            uppercase and a lowercase letter<br /></span>
                        <span class="{{ contains8Char() ? 'text-success' : 'text-danger' }}">*At
                            least 8
                            characters<br /></span>
                        <span class="{{ containsPTRN('password', spcPtrn) ? 'text-danger' : 'text-success' }}">*Password
                            cannot contain whitespace<br /></span>
                        <span class="{{ containsPTRN('password', spclPtrn) ? 'text-success' : 'text-danger' }}">*At
                            least 1 special character<br /></span>
                    </div>
                </div>

                <div class="col-sm-6 mt-4">
                    <div class="ps-mat-input">
                        <select id="gender" name="gender" formControlName="gender">
                            <option value="">- Select -</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </select>
                        <label for="gender"><span class="text-danger">*</span> Gender</label>
                    </div>
                    <div *ngIf="userForm.controls['gender'].dirty || userForm.controls['gender'].touched"
                        class="text-danger">
                        <span *ngIf="userForm.controls['gender'].errors?.['required']">*Select
                            gender</span>
                    </div>
                </div>

                <div class="col-sm-6 mt-4">
                    <psmat-input type="date" formControlName="dob" label="DOB" [max]="minAllowedDob" controlName="dob"
                        [parentForm]="userForm"></psmat-input>
                </div>

                <div class="col-sm-6 mt-4">
                    <div class="ps-mat-input">
                        <select id="role" name="role" formControlName="role">
                            <option [ngValue]="null">- Select -</option>
                            <option *ngFor="let role of roleList" [value]="role.id">{{ role.name }}</option>
                        </select>
                        <label for="role">Role</label>
                    </div>
                    <div *ngIf="userForm.controls['role'].dirty || userForm.controls['role'].touched"
                        class="text-danger">
                        <span *ngIf="userForm.controls['role'].errors?.['required']">*Role is required<br /></span>
                    </div>
                </div>
                <div class="col-sm-6 mt-4">
                    <div class="ps-mat-input">
                        <select id="status" name="status" formControlName="status">
                            <option value="">- Select -</option>
                            <option value="0">Yes</option>
                            <option value="1">No</option>
                        </select>
                        <label for="status">Is Active</label>
                    </div>
                    <div *ngIf="userForm.controls['status'].dirty || userForm.controls['status'].touched"
                        class="text-danger">
                        <span *ngIf="userForm.controls['status'].errors?.['required']">*Status is
                            required</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-success" (click)="saveUser()"><i class="fa fa-save"></i>
                Save</button>
            <button type="button" class="btn btn-danger" (click)="modal.close('Cross click')"><i
                    class="fa fa-times"></i> Cancel</button>
        </div>
    </form>
</ng-template>