import { FilterPagination, PaginationResponse } from '@models/filter-pagination';
import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BaseResponse } from '../../../models/response';
import { ModelTenant } from '../../../models/tenant/model-tenant';
import { ApiService } from '../../../services/api.service';
import { ModelBillMatching } from '../../../models/rent/model-bill-matching';
import { ModelReceivedRent } from '../../../models/rent/model-received-rent';
import { ReqModelBillMatch } from '../../../models/rent/req-model-bill-match';
import { ReqModelMatchedBills } from '../../../models/rent/req-model-matched-receipts';
import { ReqModelUnmatchReceipt } from '../../../models/rent/req-model-unmatch-receipt';
import { ModelRentInformation } from '../../../models/rentInformation/model-rent-information';
import { LedgerFilter } from '../../../models/ledger-filter';
import { ModelRentStatus } from '../../../models/rent/model-rent-status';
import { Utils } from '../../../utils/utils';
import { ModelRentStatusFilter } from '../../../models/rent/model-rent-status-filter';
import { ModelUser } from '../../../models/user/model-user';
import { ModelRentInfoFilter } from '../../../models/rent/model-rent-info-filter';

@Component({
  selector: 'rent-status',
  templateUrl: './rent-status.component.html',
  styleUrls: ['./rent-status.component.scss']
})
export class RentStatusComponent implements OnInit, OnDestroy {
  tenantList: Array<ModelTenant> = [];
  rentStatusList: Array<ModelRentStatus> = [];
  matchedBills: Array<ModelBillMatching> = [];
  receiptList: Array<ModelReceivedRent> = [];
  rentInfoList: Array<ModelRentInformation> = [];
  rentStatusFilter: ModelRentStatusFilter = new ModelRentStatusFilter(0, 0, 0, '', '');
  rentInfoFilter: ModelRentInfoFilter = new ModelRentInfoFilter(0, 0, 0);
  ownerList: Array<ModelUser> = [];
  pagination: FilterPagination = new FilterPagination(1, 5, 'name', 'DESC', '', '');
  paginationRes: PaginationResponse = new PaginationResponse();
  closeResult = '';
  public billForm: UntypedFormGroup;
  public isLoading = false;
  currentDate: any;
  hideSearch: boolean = true;
  startingSn: number = 1;
  selectedReceipt: any;
  selectedTenantId: any;
  selRentInfoId: any;
  selectedMPId: any;
  selTenantId: any;
  selectedRemainingBillAmt: any;
  inputValue: string;
  billTotal: number = 0;
  receiveTotal: number = 0;
  balanceTotal: number = 0;

  constructor(private apiService: ApiService, private renderer: Renderer2, private modalService: NgbModal, private toastr: ToastrService) {

  }

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'bill-matching'
    );
    this.currentDate = Utils.getCurrentDate('MM-dd-yyyy');
    
    this.rentStatusFilter['start_date'] = this.currentDate;
    this.rentStatusFilter['end_date'] = this.currentDate;
    this.getOwners();
    this.getTenantList();
    this.getRentInformation();
  }

  onFieldValueChange(fieldName: string, event: any) {
    this.rentStatusFilter[fieldName] = event.target.value;
  }
  
  getOwners(){
    this.apiService.ownerList().subscribe((result: BaseResponse) => {
      this.ownerList = result.data;
    })
  } 

  getRentInformation() {
    if(this.selTenantId != null && this.selTenantId != undefined && this.selTenantId != 0){
      this.apiService.rentInformationList(this.selTenantId).subscribe((result: BaseResponse) => {
        this.rentInfoList = result.data;
      })
    } else {
      this.apiService.getAllRentInformationList(this.rentInfoFilter).subscribe((result: BaseResponse) => {
        this.rentInfoList = result.data;
      })
    }
  }

  onDebtorSelected(event: any) {
    const selectedValue = event.target.value;
    this.selTenantId = selectedValue;
    this.getRentInformation();
  }

  resetSearch() {
    this.rentStatusFilter.tenant_id = 0;
    this.rentStatusFilter.rent_collector_id = 0;
    this.rentStatusFilter.rent_info_id = 0;
    this.rentInfoList = [];
    this.rentStatusList = [];
    this.getTenantList();
  }

  getTenantList() {
    this.apiService.tenantList().subscribe((result: BaseResponse) => {
      this.tenantList = result.data;
    })
  }

  loadLedgers() {
    this.apiService.getRentStatusWithFilter(this.rentStatusFilter).subscribe((result: BaseResponse) => {
      if (result.code == 1) {
        this.showDialog(result);
      } else {
        this.rentStatusList = result.data;
        this.billTotal = 0;
        this.receiveTotal = 0;
        this.balanceTotal = 0;
        this.rentStatusList.forEach(bill => {
          this.billTotal += parseFloat(bill.total.toString());
          this.receiveTotal += parseFloat(bill.matched_amount.toString());
          this.balanceTotal += parseFloat(bill.match_remain_amount.toString());
        });
      }
    })
  }

  getReceipts() {
    this.apiService.receiptList(this.selectedTenantId).subscribe((result: BaseResponse) => {
      this.receiptList = result.data;
    })
  }

  showDialog(result: any) {
    this.isLoading = false;
    if (result.code == 0) {
      this.toastr.success(result.message)
    } else {
      this.toastr.error(result.message)
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'channel-manager-list'
    );
  }
}