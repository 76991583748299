<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6 d-flex">
                <h4>Payment Methods <span class="text-gray-600">({{dataCount}})</span></h4>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item active"><a routerLink="/tenants"><i class="fa fa-users"></i> Settings</a>
                    </li>
                    <li class="breadcrumb-item active"><i class="fa fa-money-bill"></i> Payment Method</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card of-x-a p-3">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-primary" (click)="add(content)"><i class="fa fa-plus"></i>
                            Add
                            Payment Method</button>
                    </div>
                    <div class="col-sm-6">
                        <div class="flex float-sm-right">
                            <app-table-data-exporter tableId="dataTable"
                                fileName="payment_method"></app-table-data-exporter>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="row mt-3" *ngIf="paymentMethods.length > 0">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body table-responsive p-0" style="max-height: 50vh; height: auto;">
                            <table id="dataTable" class="table table-head-fixed tbl-sm text-nowrap">
                                <thead>
                                    <tr>
                                        <th class="text-center w-5p" scope="col">S.N.</th>
                                        <th>Name</th>
                                        <th class="text-center w-5p">Required Additional Fields</th>
                                        <th class="text-center">Status</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let payMethod of paymentMethods; index as i">
                                        <th scope="row">{{ startingSn + i }})</th>
                                        <td>{{payMethod.name}}</td>
                                        <td
                                            class="text-center text-{{ payMethod.req_other_fields == 0 ? 'success' : 'danger' }}">
                                            <i class="fa fa-circle"></i> {{ payMethod.req_other_fields == 0 ? 'Yes' :
                                            'No' }}
                                        </td>
                                        <td
                                            class="text-center text-{{ payMethod.status == 0 ? 'success' : 'danger' }}">
                                            <i class="fa fa-circle"></i> {{ payMethod.status == 0 ? 'Active' :
                                            'InActive' }}
                                        </td>
                                        <td class="text-center">
                                            <button class="btn btn-outline text-primary pstitle-primary-right"
                                                pstitle="Edit payment method {{ payMethod.name }}"
                                                (click)="edit(content, payMethod)">
                                                <i class="fa fa-edit p-1"></i></button>

                                            <button class="btn btn-outline text-danger pstitle-danger-right"
                                                pstitle="Delete payment method {{ payMethod.name }}"
                                                (click)="del(delete, payMethod)">
                                                <i class="fa fa-trash p-1"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>
            </div>

            <div *ngIf="paymentMethods == null || paymentMethods.length == 0">
                <h4 class="text-center p-4">No Data</h4>
            </div>
        </div>
    </div>
    <!-- /.card -->
</section>



<ng-template #content let-modal>
    <div class="modal fade show" tabindex="-1" role="dialog" style="display: block; padding-right: 15px;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">{{dialogActFor}} payment method</h4>
                    <i class="fa fa-times fa-2x" (click)="modal.dismiss('Cross click')"></i>
                </div>
                <form [formGroup]="payMethodForm" appFocusFirstInvalidField>
                    <div class="modal-body">
                        <span>Fields marked with (<span class="text-danger">*</span>) is required</span>
                        <div class="form-group row">
                            <div class="col-sm-12 mt-4">
                                <psmat-input type="text" formControlName="name" label="Name" controlName="name"
                                    [parentForm]="payMethodForm" required="true" maxlength="40"></psmat-input>
                            </div>
                            <div class="col-sm-6 mt-4">
                                <div class="ps-mat-input">
                                    <select name="req_other_fields" formControlName="req_other_fields">
                                        <option value="null">- Select -</option>
                                        <option value="0">Yes</option>
                                        <option value="1">No</option>
                                    </select>
                                    <label for="req_other_fields"><span class="text-danger">*</span> Require Other
                                        Fields</label>
                                </div>
                                <div *ngIf="payMethodForm.controls['req_other_fields'].dirty || payMethodForm.controls['req_other_fields'].touched"
                                    class="text-danger">
                                    <span *ngIf="payMethodForm.controls['req_other_fields'].errors?.['required']">*This
                                        field is
                                        required</span>
                                </div>
                            </div>
                            <div class="col-sm-6 mt-4">
                                <div class="ps-mat-input">
                                    <select name="status" formControlName="status">
                                        <option value=''>- Select -</option>
                                        <option value="0">Yes</option>
                                        <option value="1">No</option>
                                    </select>
                                    <label for="status"><span class="text-danger">*</span> Is Active</label>
                                </div>
                                <div *ngIf="payMethodForm.controls['status'].dirty || payMethodForm.controls['status'].touched"
                                    class="text-danger">
                                    <span *ngIf="payMethodForm.controls['status'].errors?.['required']">*Status is
                                        required</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-success" (click)="savePayMethod()"><i
                                class="fa fa-save"></i>
                            Save</button>
                        <button type="button" class="btn btn-danger" (click)="modal.close('Cross click')"><i
                                class="fa fa-times"></i> Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #delete let-modal>
    <div class="modal-header modal-xl bg-danger">
        <h4 class="modal-title" id="modal-basic-title">Tenant</h4>
        <i class="fa fa-times fa-2x" (click)="modal.dismiss('Cross click')"></i>
    </div>
    <div class="modal-body bg-danger">
        <h4>Are you sure to delete {{selTenantName}}?</h4>
    </div>
    <div class="modal-footer bg-danger">
        <button type="submit" class="btn btn-danger" (click)="deletePayMethod()"><i class="fa fa-trash"></i>
            Sure</button>
        <button type="button" class="btn btn-success" (click)="modal.close('Cross click')"><i class="fa fa-times"></i>
            Cancel</button>
    </div>
</ng-template>