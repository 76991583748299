import { Component, OnInit, Renderer2 } from '@angular/core';
import { BaseResponse } from '@models/response';
import { PaginationResponse } from '@models/filter-pagination';
import { AppService } from '../../../services/app.service';
import { ApiService } from '../../../services/api.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ModelRentInformation } from '../../../models/rentInformation/model-rent-information';
import { ActivatedRoute } from '@angular/router';
import { ModelRentPosting } from '../../../models/rentPosting/model-rent-posting';
import { SMsg } from '../../../utils/static/s-msg';
import { Utils } from '../../../utils/utils';
import { ModelReceivedRent } from '../../../models/rent/model-received-rent';
import { ModelTenant } from '../../../models/tenant/model-tenant';
import { DataService } from '../../../services/data.service';
import { ModelPaymentMethod } from '../../../models/paymentMethod/model-payment-method';
import { ModalPrintComponent } from '../../modal/modal-print/modal-print.component';

@Component({
  selector: 'rent-received',
  templateUrl: './rent-received.component.html',
  styleUrls: ['./rent-received.component.scss']
})

export class RentReceivedComponent implements OnInit {
  selectedTenant: ModelTenant | any;
  paginationRes: PaginationResponse = new PaginationResponse();
  hideSearch: boolean = true;
  public isLoading = false;
  closeResult = '';
  startingSn: number = 1;
  dataCount: number = 0;
  rentPostingsList: Array<ModelRentPosting> = [];
  paymentMethods: Array<ModelPaymentMethod> = [];
  rentInfoList: Array<ModelRentInformation> = [];
  receivedRentList: Array<ModelReceivedRent> = [];
  public rentReceivedForm: UntypedFormGroup;
  selTenantId: any;
  selRentInfoId: any;
  isPayMethodCheque: boolean;
  isPayMethodWallet: boolean;
  selTenantName: string;
  currentDate: any;
  minStartDate: string;
  maxEndDate: string;
  balanceAmount: any;
  loggedInUserName: string;
  dialogActFor: string = "Add";
  htmlContent: any;

  constructor(private apiService: ApiService, private route: ActivatedRoute, private dataService: DataService, private appService: AppService, private renderer: Renderer2, private modalService: NgbModal, private toastr: ToastrService) {
    this.selTenantId = this.route.snapshot.paramMap.get('id');
    this.loggedInUserName = appService.name;
    this.selectedTenant = dataService.getData();
  }

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'property-list'
    );
    this.currentDate = Utils.getCurrentDate('MM-dd-yyyy');

    this.rentReceivedForm = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      rent_information_id: new UntypedFormControl('', Validators.required),
      tenant_id: new UntypedFormControl(this.selTenantId),
      received_amount: new UntypedFormControl('', Validators.required),
      paid_by: new UntypedFormControl('', Validators.required),
      payment_method_id: new UntypedFormControl('', Validators.required),
      cheque_bank_name: new UntypedFormControl(''),
      cheque_no: new UntypedFormControl(''),
      cheque_date: new UntypedFormControl(''),
      wallet_id: new UntypedFormControl(''),
      received_by: new UntypedFormControl('', Validators.required),
      remarks: new UntypedFormControl(''),
      received_date: new UntypedFormControl(0, Validators.required)
    });

    this.getReceivedRentInformation();
    this.getRentInformation();
    this.getRentPostings();
  }

  getReceivedRentInformation() {
    this.apiService.receivedRentInformationList(this.selTenantId).subscribe((result: BaseResponse) => {
      this.receivedRentList = result.data;
      this.dataCount = this.receivedRentList.length;
      this.getPaymentMethodList();
    })
  }

  getPaymentMethodList(){
    this.apiService.getPaymentMethods().subscribe((result: BaseResponse) => {
      this.paymentMethods = result.data;
    });
  }

  getRentInformation() {
    this.apiService.rentInformationList(this.selTenantId).subscribe((result: BaseResponse) => {
      this.rentInfoList = result.data;
    })
  }

  getRentPostings() {
    this.apiService.getTenantRentPostings(this.selTenantId).subscribe((result: BaseResponse) => {
      this.rentPostingsList = result.data;
    })
  }

  add(content) {
    this.dialogActFor = "Add";
    this.balanceAmount = 0;
    this.rentReceivedForm.reset();
    this.rentReceivedForm.patchValue({
      received_by: this.loggedInUserName,
      received_date: this.currentDate
    })
    this.openPopup(content)
  }

  onOptionSelected(event: any) {
    const selectedValue = event.target.value;
    if(selectedValue){
      this.selRentInfoId = selectedValue;
      this.getBalanceAmount();
    } else {
      this.rentReceivedForm.reset();
    }
  }

  onPaymentMethodSelected(event: any) {
    this.isPayMethodCheque = false;
    this.isPayMethodWallet = false;
    // const selectedValue = event.target.value;
    const selectedValue = this.rentReceivedForm.controls['payment_method_id'].value;
  
    if (selectedValue !== null && selectedValue !== undefined) {
      const selectedPaymentMethod = this.paymentMethods.find(pm => pm.id == selectedValue);
      if(selectedPaymentMethod){
        if (selectedPaymentMethod.name === "Cheque" && selectedPaymentMethod.req_other_fields == 0) {
          this.isPayMethodCheque = true;
          this.isPayMethodWallet = false;
          this.assignValidator(['cheque_bank_name', 'cheque_no', 'cheque_date'], true);
          this.assignValidator(['wallet_id'], false);
        } else if (selectedPaymentMethod.name === "Online" && selectedPaymentMethod.req_other_fields == 0) {
          this.isPayMethodCheque = false;
          this.isPayMethodWallet = true;
          this.assignValidator(['wallet_id'], true);
          this.assignValidator(['cheque_bank_name', 'cheque_no', 'cheque_date'], false);
        } else {
          this.assignValidator(['wallet_id', 'cheque_bank_name',  'cheque_no', 'cheque_date'], false);
        }
      } else {
        this.assignValidator(['wallet_id', 'cheque_bank_name',  'cheque_no', 'cheque_date'], false);
      }
    }
  }


  assignValidator(controls: Array<string>, action: boolean){
    controls.forEach(cntrl => {
      if(action){
        this.rentReceivedForm.controls[cntrl].setValidators([Validators.required]);
      } else {
        this.rentReceivedForm.controls[cntrl].clearValidators();
      }
      this.rentReceivedForm.controls[cntrl].updateValueAndValidity();
    });
  }
  

  getBalanceAmount(){
    this.balanceAmount = 0;
    this.apiService.balanceAmountForRC(this.selTenantId, this.selRentInfoId).subscribe((result: BaseResponse) => {
      if(result.data != null){
        this.balanceAmount = result.data.balance_amount;
      }
    })
  }

  edit(content, rentInfo) {
    this.dialogActFor = "Edit";
    this.rentReceivedForm.reset();
    this.selRentInfoId = rentInfo.id;
    this.getBalanceAmount();
    this.rentReceivedForm.patchValue({
      id: rentInfo.id,
      rent_information_id: rentInfo.rent_information_id,
      tenant_id: rentInfo.tenant_id,
      received_amount: rentInfo.received_amount,
      paid_by: rentInfo.paid_by,
      payment_method_id: rentInfo.payment_method_id,
      cheque_no: rentInfo.cheque_no,
      cheque_bank_name: rentInfo.cheque_bank_name,
      cheque_date: rentInfo.cheque_date,
      wallet_id: rentInfo.wallet_id,
      received_by: rentInfo.received_by,
      remarks: rentInfo.remarks,
      received_date: rentInfo.received_date
    })
    this.onPaymentMethodSelected(rentInfo.payment_method_id);
    this.openPopup(content);
  }

  async printThisBill(rentReceivedId) {
    this.isLoading = true;
    await this.apiService.printReceivedRent(rentReceivedId).subscribe((result: any) => {
      if (result.code == 0) {
        this.htmlContent = result.data.billHtml;
        const dialogRef = this.modalService.open(ModalPrintComponent);
        dialogRef.componentInstance.htmlContent = this.htmlContent;
      } else {
        this.handleResponse(result);
      }
      this.isLoading = false;
    });
  }

  async saveReceivedRent(printInvoice: boolean) {
    this.rentReceivedForm.markAllAsTouched();
    if (this.rentReceivedForm.valid) {
      this.isLoading = true;
      if (this.rentReceivedForm.value.id != null) {
        await this.apiService.receivedRentUpdate(this.rentReceivedForm.value).subscribe((result: any) => {
          if (result.code == 0) {
            this.htmlContent = result.data.billHtml;
            if (printInvoice && this.htmlContent != "") {
              const dialogRef = this.modalService.open(ModalPrintComponent);
              dialogRef.componentInstance.htmlContent = this.htmlContent;
            }
          }
          this.handleResponse(result);
        });
      } else {
        const formData = { ...this.rentReceivedForm.value, tenant_id: this.selTenantId };
        await this.apiService.receivedRentAdd(formData).subscribe((result: any) => {
          if (result.code == 0) {
            this.htmlContent = result.data.billHtml;
            if (printInvoice && this.htmlContent != "") {
              const dialogRef = this.modalService.open(ModalPrintComponent);
              dialogRef.componentInstance.htmlContent = this.htmlContent;
            }
          }
          this.handleResponse(result);
        });
      }
      this.closeModal();
    } else {
      const invalidFields: string[] = [];
      const controls = this.rentReceivedForm.controls;
      for (const controlName in controls) {
        if (controls.hasOwnProperty(controlName)) {
          if (controls[controlName].invalid) {
            invalidFields.push(controlName);
          }
        }
      }

      this.toastr.error(SMsg.FIELDS_EMP + " -> "+ invalidFields);
    }
  }

  del(content, tenant) {
    this.selTenantId = tenant.id;
    this.selTenantName = tenant.name;
    this.openPopup(content);
  }

  async deleteTenant() {
    await this.apiService.tenantDelete(this.selTenantId).subscribe((result: any) => {
      this.handleResponse(result);
    });
    this.closeModal();
  }

  handleResponse(result: any) {
    this.isLoading = false;
    if (result.code == 0) {
      this.toastr.success(result.message)
      this.getReceivedRentInformation();
      this.getRentPostings();
    } else {
      this.toastr.error(result.message)
    }
  }


  openPopup(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  closeModal() {
    this.isPayMethodCheque = false;
    this.isPayMethodWallet = false;
    this.rentReceivedForm.reset();
    this.modalService.dismissAll();
  }

  getFaIcon(payMethod: string): string{
    return payMethod === "Cash" ? "fa-money-bill" : payMethod === "Cheque" ? "fa-money-check" : "fa-globe";
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}