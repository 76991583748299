import { Pattern } from '@utils/validators/patterns';

export class Utils {
    /**
     * isNullOrEmpty
     */
    static isNullOrEmpty(value: string): boolean {
        return value == null || value === '';
    }

    /**
     * isNotNullOrEmpty
     */
    static isNotNullOrEmpty(value: any): boolean {
        if (value === null || value === undefined) { return false; }
        if (typeof value === 'string' || Array.isArray(value)) { return value.length > 0; }
        if (typeof value === 'object') { return Object.keys(value).length > 0; }
        return true;
    }

    /**
     * isNull
     */
    static isNull(value: string): boolean {
        return value == null;
    }

    static getCurrentDate(format: string): any {
        const currentDate = new Date();
        const formattedDate = this.formatDate(currentDate, format);
        return formattedDate;
    }

    static getMaxAllowedDOB(minAllowedDob: number | null) : any{
        const currentDate = new Date();
        currentDate.setFullYear(currentDate.getFullYear() - (minAllowedDob != null ? minAllowedDob : 10));
        const dateYearsAgo = this.formatDate(currentDate, 'MM-dd-yyyy');
        return dateYearsAgo;
    }

    private static formatDate(date: Date, format: string): string {
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        };

        const formattedParts: string[] = [];

        if (format.includes('yyyy')) {
            formattedParts.push(date.getFullYear().toString());
        }

        if (format.includes('MM')) {
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            formattedParts.push(month);
        }

        if (format.includes('dd')) {
            const day = date.getDate().toString().padStart(2, '0');
            formattedParts.push(day);
        }

        return formattedParts.join('-');
    }

    static getEndDateFrom(startDate: string): any {
        const startDateObject = new Date(startDate);
        const endDate = new Date(startDateObject);
        endDate.setMonth(endDate.getMonth() + 1);
        const newEndDate = new Date(endDate);
        const formattedEndDate = this.formatDate(newEndDate, "yyyy-MM-dd");
        return formattedEndDate;
    }

    /**
     * isEmpty
     */
    static isEmpty(value: string): boolean {
        return value === '';
    }

    static ucFirst(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    static generatePassword(length: number): string {
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let password = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            password += charset.charAt(randomIndex);
        }
        return password;
    }


    contains8Char(value: string): boolean {
        return value != null && value.length >= 8;
    }


    containsPTRN(value: string, pattern: any): boolean {
        return Pattern.checkPattern(value, pattern);
    }

}