import { Component, HostBinding, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { Pattern } from '@utils/validators/patterns';
import { ApiService } from '@services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SKey } from '../../utils/static/s-str';

@Component({
    selector: 'recover-password',
    templateUrl: './recover-password.component.html',
    styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'login-box';
    public recoverPasswordForm: UntypedFormGroup;
    public isAuthLoading = false;
    numPtrn = Pattern.NUM;
    ucPtrn = Pattern.UC;
    lcPtrn = Pattern.LC;
    spcPtrn = Pattern.SPACE;
    spclPtrn = Pattern.SPECIAL;
    username: string = '';
    token: string = '';

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private apiService: ApiService,
        private router: Router,
        private route: ActivatedRoute,
        private formbuilder: FormBuilder
    ) { }

    ngOnInit(): void {
        this.renderer.addClass(
            document.querySelector('app-root'),
            'login-page'
        );

        let username: any = this.route.snapshot.paramMap.get('username');
        let token: any = this.route.snapshot.paramMap.get(SKey.TOKEN);
        if (username != null && token != null) {
          this.username = username;
          this.token = token;
        } else {
            this.router.navigate(['/']);
        }

        this.recoverPasswordForm = this.formbuilder.group(
            {
                password: ["", Validators.required],
                confirmPassword: ["", Validators.required]
            },
            {
                validator: this.ConfirmedValidator('password', 'confirmPassword')
            });

        this.recoverPasswordForm = new UntypedFormGroup({
            userName: new UntypedFormControl(this.username),
            password: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=.*[a-zA-Z]).{8,}$/)]),
            confirmPassword: new UntypedFormControl(null, Validators.required),
            token: new UntypedFormControl(this.token)
        }, { validators: [this.passwordMatchValidator] });
    }

    async recoverPassword() {
        this.recoverPasswordForm.markAllAsTouched();
        if(this.recoverPasswordForm.valid){
            await this.apiService.recoverPassword(this.recoverPasswordForm.value).subscribe((result: any) => {
                if (result.responseCode == "200") {
                    this.toastr.success(result.responseMessage)
                    this.router.navigate(['/']);
                } else {
                    this.toastr.error(result.responseMessage)
                }
            });
        }
    }


    ConfirmedValidator(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];
            if (
                matchingControl.errors &&
                !matchingControl.errors.confirmedValidator
            ) {
                return;
            }
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ confirmedValidator: true });
            } else {
                matchingControl.setErrors(null);
            }
        };
    }

    passwordMatchValidator: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
        const password = group.get('password')?.value ?? '';
        const cPassword = group.get('confirmPassword')?.value ?? '';
        return password !== cPassword ? { passwordMatched: true } : null;
    }

    containsPTRN(controller: string, pattern: any): boolean {
        const pwVal: string = this.recoverPasswordForm.controls[controller].value;
        return Pattern.checkPattern(pwVal, pattern);
    }

    contains8Char(): boolean {
        const pwVal: string = this.recoverPasswordForm.controls['password'].value;
        return pwVal != null && pwVal.length >= 8;
    }

    ngOnDestroy(): void {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'login-page'
        );
    }
}
