export class CollectionReportFilter{
    rent_collector_id: number | null;
    payment_method_id: number | null;
    start_date: string | null;
    end_date: string | null;

    constructor(rentCollectorId: number, paymentMethodId: number, start_date: string, end_date: string){
        this.rent_collector_id = rentCollectorId;
        this.payment_method_id = paymentMethodId;
        this.start_date = start_date;
        this.end_date = end_date;
    }
}