export class ModelRentInfoFilter{
    tenant_id: number | null;
    rent_collector_id: number | null;
    rent_info_id: number | null;

    constructor(tenantId: number, rentCollectorId: number, rent_info_id: number){
        this.tenant_id = tenantId;
        this.rent_collector_id = rentCollectorId;
        this.rent_info_id = rent_info_id;
    }
}