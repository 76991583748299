import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BaseResponse } from '@models/response';
import { EP } from '../store/ep';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  setting:any=null;
  constructor(private http:HttpClient) { 

  }

userDetailsById(id: string): Observable<BaseResponse> {
    return this.http.get(EP.CMU_GET + id).pipe(
        map((body: any) => {
            return body
        })
    );
}

getUserProfile(): Observable<BaseResponse> {
    return this.http.get(EP.USR_PROFILE_GET).pipe(
        map((body: any) => {
            return body
        })
    );
}

updateUserProfile(body: any): Observable<BaseResponse> {
    return this.http.post(EP.USR_PROFILE_UPD, body).pipe(
        map((body: any) => {
            return body
        })
    );
}

userInformationCreate(body: any): Observable<BaseResponse> {
  body.email = body.userName;
  return this.http.post(EP.CMU_ADD, body).pipe(map((body: any) => {
          return body
      })
  );
}

userInformationUpdate(body: any): Observable<BaseResponse> {
  body.email = body.userName;
  return this.http.post(EP.CMU_UPD, body).pipe(map((body: any) => {
          return body
      })
  );
}

changePassword(body: any): Observable<BaseResponse> {
  return this.http.post(EP.CMU_CHANGE_PW, body).pipe(map((body: any) => {
          return body
      })
  );
}

}
