import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';

@Injectable({
  providedIn: 'root'
})
export class TempAuthGuardGuard implements CanActivate, CanActivateChild {
  constructor(private appService: AppService) {}

  canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
  ):
      | Observable<boolean | UrlTree>
      | Promise<boolean | UrlTree>
      | boolean
      | UrlTree {
      return this.getTempToken();        
  }

  canActivateChild(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
  ):
      | Observable<boolean | UrlTree>
      | Promise<boolean | UrlTree>
      | boolean
      | UrlTree {
      return this.canActivate(next, state);
  }

  async getTempToken() {
      if(this.appService.tempToken != null && this.appService.tempToken != ''){
        return true;
      } else {
          this.appService.logout();
          return false;
      }
  }
}
