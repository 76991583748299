export class ReqModelUnmatchReceipt {
    match_id: number;
    tenant_id: number;
    monthly_posting_id: number;
    received_rent_id: number;

    constructor(match_id: number, tenant_id: number, monthly_posting_id: number, received_rent_id: number) {
        if (match_id != null) { this.match_id = match_id; }
        if (tenant_id != null) { this.tenant_id = tenant_id; }
        if (monthly_posting_id != null) { this.monthly_posting_id = monthly_posting_id; }
        if (received_rent_id != null) { this.received_rent_id = received_rent_id; }
    }
}