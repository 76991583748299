/**
 * This class SStr is for storing any static string
*/
export class SStr{
    public static USERNAME: string = "username";
    public static PASSWORD: string = "password";
    public static PROP_CODE: string = "Property Code";
    public static PROP_NAME: string = "Property Name";
    public static PBLK_IP_ADR: string = "Public IP Address";
    public static CL_PBLK_API: string = "Call Public API";
    public static ALW_SYNC_DATA: string = "Allow Sync Data";
    public static STAUS: string = "Status";
    public static ACTION: string = "Action";
    public static ACTIVE: string = "Active";
    public static IN_ACTIVE: string = "In Active";
    public static ENABLED: string = "Enabled";
    public static DISABLED: string = "Disabled";
}

/**
 * This class SKey is the session value key
*/
export class SKey{
    public static TMP_TOKEN: string = "temptoken";
    public static TOKEN: string = "token";
    public static ID: string = "Id";
    public static UID: string = "UserId";
    public static PROP_ID: string = "PropertyId";
    public static ROLE_ID: string = "RoleId";
    public static PROFILE: string = "profile";
}