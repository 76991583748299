import { Component, OnInit, Renderer2 } from '@angular/core';
import { BaseResponse } from '@models/response';
import { AppService } from '../../services/app.service';
import { ModelTenant } from '../../models/tenant/model-tenant';
import { ApiService } from '../../services/api.service';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SMsg } from '../../utils/static/s-msg';
import { ActivatedRoute } from '@angular/router';
import { Pattern } from '@utils/validators/patterns';
import { DataService } from '../../services/data.service';
import { Utils } from '../../utils/utils';
import { ModelRole } from '../../models/role/model-role';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  hideSearch: boolean = true;
  public isLoading = false;
  closeResult = '';
  startingSn: number = 1;
  dataCount: number = 0;
  usersList: Array<ModelTenant> = [];
  roleList: Array<ModelRole> = [];
  public userForm: UntypedFormGroup;
  minAllowedDob: any;
  selTenantId: number;
  selTenantName: string;
  minDateForIncrement: string;
  maxDateForIncrement: string;
  dialogFor: string = "Add";
  numPtrn = Pattern.NUM;
  ucPtrn = Pattern.UC;
  lcPtrn = Pattern.LC;
  spcPtrn = Pattern.SPACE;
  spclPtrn = Pattern.SPECIAL;
  emailPtrn = Pattern.EMAIL;
  usrNmPtrn = Pattern.USERNAME;

  constructor(private apiService: ApiService, private appService: AppService, private dataService: DataService, private renderer: Renderer2, private modalService: NgbModal, private toastr: ToastrService) { }

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'property-list'
    );
    this.minAllowedDob = Utils.getMaxAllowedDOB(null);

    this.userForm = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      name: new UntypedFormControl(null, Validators.required),
      email: new UntypedFormControl(null, Validators.required),
      password: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=.*[a-zA-Z]).{8,}$/)]),
      contact: new UntypedFormControl(null, Validators.required),
      gender: new UntypedFormControl('', Validators.required),
      dob: new UntypedFormControl('', Validators.required),
      role: new UntypedFormControl('', Validators.required),
      status: new UntypedFormControl('', Validators.required)
    });

    this.userForm.controls['id'].valueChanges.subscribe(userId => {
      if (userId == null || userId == '') {
        this.userForm.addControl('password', new UntypedFormControl('', [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=.*[a-zA-Z]).{8,}$/)]));
      } else {
        this.userForm.removeControl('password');
      }
    });

    this.getRoleList();
    this.getUsersList();
  }


  getRoleList() {
    this.apiService.roleList().subscribe((result: BaseResponse) => {
      this.roleList = result.data;
    })
  }

  public setTenant(tenant: ModelTenant) {
    this.dataService.setData(tenant);
  }

  getUsersList() {
    this.apiService.getUsers().subscribe((result: BaseResponse) => {
      this.usersList = result.data;
      this.dataCount = this.usersList.length;
    })
  }

  add(content) {
    this.dialogFor = "Add";
    this.userForm.reset();
    this.userForm.patchValue({ status: '' });
    this.openPopup(content)
  }

  edit(content, data) {
    this.dialogFor = "Edit";
    this.userForm.reset();
    this.userForm.patchValue({
      id: data.id,
      name: data.name,
      email: data.email,
      contact: data.contact,
      gender: data.gender,
      dob: data.dob,
      role: data.role,
      status: data.status
    })
    this.openPopup(content);
  }

  onDateChange(dateType: string, event: any) {
    const changedVal = event.target.value;
    if (dateType === "Start") {
      this.minDateForIncrement = changedVal;
    } else {
      this.maxDateForIncrement = changedVal;
    }
  }

  async saveUser() {
    this.userForm.markAllAsTouched();
    if (this.userForm.valid) {
      this.isLoading = true;
      if (this.userForm.value.id != null) {
        await this.apiService.updateUser(this.userForm.value).subscribe((result: any) => {
          this.handleResponse(result);
        });
      } else {
        await this.apiService.addUser(this.userForm.value).subscribe((result: any) => {
          this.handleResponse(result);
        });
      }
      this.closeModal();
    } else {
      const invalidFields: string[] = [];
      const controls = this.userForm.controls;
      for (const controlName in controls) {
        if (controls.hasOwnProperty(controlName)) {
          if (controls[controlName].invalid) {
            invalidFields.push(controlName);
          }
        }
      }

      this.toastr.error(SMsg.FIELDS_EMP + " -> " + invalidFields);
    }
  }

  del(content, tenant) {
    this.selTenantId = tenant.id;
    this.selTenantName = tenant.name;
    this.openPopup(content);
  }

  async deleteTenant() {
    await this.apiService.tenantDelete(this.selTenantId).subscribe((result: any) => {
      this.handleResponse(result);
    });
    this.closeModal();
  }

  handleResponse(result: any) {
    this.isLoading = false;
    if (result.code == 0) {
      this.toastr.success(result.message)
      this.getUsersList();
    } else {
      this.toastr.error(result.message)
    }
  }

  containsPTRN(controller: string, pattern: any): boolean {
    const pwVal: string = this.userForm.controls[controller].value;
    return Pattern.checkPattern(pwVal, pattern);
  }

  contains8Char(): boolean {
    const pwVal: string = this.userForm.controls['password'].value;
    return pwVal != null && pwVal.length >= 8;
  }

  openPopup(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}