<div class="modal" tabindex="-1" role="document" style="display: block;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title"></h4>
                <i class="fa fa-times fa-2x" (click)="closeModal()"></i>
            </div>
            <div class="modal-body p-0 m-0">
                <div [innerHTML]="sanitizedHtmlContent">
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="printBill()" class="btn btn-primary"><i class="fa fa-print"></i> Print</button>
                <button type="button" (click)="closeModal()" class="btn btn-secondary"><i class="fa fa-times"></i> Close</button>
            </div>
        </div>
    </div>
</div>