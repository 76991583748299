import { Component, OnInit, Renderer2, OnDestroy, HostBinding } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { AppService } from '../../services/app.service';
import { ToastrService } from 'ngx-toastr';
import { Utils } from '../../utils/utils';
import { Pattern } from '@utils/validators/patterns';
import { ModelRole } from '../../models/role/model-role';
import { ApiService } from '../../services/api.service';
import { BaseResponse } from '../../models/response';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'register-box';

    public registerForm: UntypedFormGroup;
    public isAuthLoading = false;
    public isGoogleLoading = false;
    public isFacebookLoading = false;
    minAllowedDob: any;
    roleList: Array<ModelRole> = [];
    numPtrn = Pattern.NUM;
    ucPtrn = Pattern.UC;
    lcPtrn = Pattern.LC;
    spcPtrn = Pattern.SPACE;
    spclPtrn = Pattern.SPECIAL;
    emailPtrn = Pattern.EMAIL;
    usrNmPtrn = Pattern.USERNAME;

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private apiService: ApiService
    ) { }

    ngOnInit() {
        this.renderer.addClass(
            document.querySelector('app-root'),
            'register-page'
        );
        this.minAllowedDob = Utils.getMaxAllowedDOB(null);
        this.registerForm = new UntypedFormGroup({
            name: new UntypedFormControl(null, Validators.required),
            email: new UntypedFormControl(null, Validators.required),
            password: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=.*[a-zA-Z]).{8,}$/)]),
            confirm_password: new UntypedFormControl(null, Validators.required),
            contact: new UntypedFormControl(null, [Validators.required]),
            gender: new UntypedFormControl("", [Validators.required]),
            dob: new UntypedFormControl(null, [Validators.required]),
            role: new UntypedFormControl("", [Validators.required])
        }, { validators: [this.passwordMatchValidator] });
        this.getRoleList();
    }

    getRoleList() {
        this.apiService.roleList().subscribe((result: BaseResponse) => {
            this.roleList = result.data;
        })
    }

    passwordMatchValidator: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
        const password = group.get('password')?.value ?? null;
        const cPassword = group.get('confirm_password')?.value ?? '';
        return password !== cPassword ? { passwordMatched: true } : null;
    }

    containsPTRN(controller: string, pattern: any): boolean {
        const pwVal: string = this.registerForm.controls[controller].value;
        return Pattern.checkPattern(pwVal, pattern);
    }

    contains8Char(): boolean {
        const pwVal: string = this.registerForm.controls['password'].value;
        return pwVal != null && pwVal.length >= 8;
    }

    async registerByAuth() {
        this.registerForm.markAllAsTouched();
        if (this.registerForm.valid) {
            this.isAuthLoading = true;
            this.registerForm.patchValue({ id: this.appService.userId });
            await this.appService.registerByAuth(this.registerForm.value);
            this.isAuthLoading = false;
        } else {
            this.toastr.error('Form is not valid!');
        }
    }

    ngOnDestroy() {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'register-page'
        );
    }
}
