import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BaseResponse } from '@models/response';
import { ModelTenant } from '@models/tenant/model-tenant';
import { ApiService } from '@services/api.service';
import { ModelReceivedRent } from '@models/rent/model-received-rent';
import { ModelRentInformation } from '@models/rentInformation/model-rent-information';
import { LedgerFilter } from '@models/ledger-filter';
import { ModelLedger } from '@models/response/model-ledger';
import { Utils } from '@utils/utils';
import { ModelUser } from '../../../models/user/model-user';
import { CollectionReportFilter } from '../../../models/collection-report-filter';
import { ModelCollectionReport } from '../../../models/rent/model-collection-report';
import { ModelPaymentMethod } from '../../../models/paymentMethod/model-payment-method';

@Component({
  selector: 'collection-report',
  templateUrl: './collection-report.component.html',
  styleUrls: ['./collection-report.component.scss']
})
export class CollectionReportComponent implements OnInit, OnDestroy {
  tenantList: Array<ModelTenant> = [];
  receiptList: Array<ModelReceivedRent> = [];
  rentInfoList: Array<ModelRentInformation> = [];
  collectionList: Array<ModelCollectionReport> = [];
  ownerList: Array<ModelUser> = [];
  paymentMethods: Array<ModelPaymentMethod> = [];
  collectionRepFilter: CollectionReportFilter = new CollectionReportFilter(0, 0, '', '');
  public isLoading = false;
  currentDate: any;
  startingSn: number = 1;
  selectedTenantId: any;
  selTenantId: any;
  drTotal: number = 0;
  crTotal: number = 0;
  totalAmount: number = 0;

  constructor(private apiService: ApiService, private renderer: Renderer2, private modalService: NgbModal, private toastr: ToastrService) { }

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'bill-matching'
    );
    this.currentDate = Utils.getCurrentDate('MM-dd-yyyy');

    this.collectionRepFilter['start_date'] = this.currentDate;
    this.collectionRepFilter['end_date'] = this.currentDate;
    this.getOwners();
    this.getPaymentMethodList();
  }

  onFieldValueChange(fieldName: string, event: any) {
    this.collectionRepFilter[fieldName] = event.target.value;
  }

  getPaymentMethodList() {
    this.apiService.getPaymentMethods().subscribe((result: BaseResponse) => {
      this.paymentMethods = result.data;
    });
  }

  getOwners() {
    this.apiService.ownerList().subscribe((result: BaseResponse) => {
      this.ownerList = result.data;
    })
  }

  getRentInformation() {
    this.apiService.rentInformationList(this.selTenantId).subscribe((result: BaseResponse) => {
      this.rentInfoList = result.data;
    })
  }

  onDebtorSelected(event: any) {
    const selectedValue = event.target.value;
    this.selTenantId = selectedValue;
    this.getRentInformation();
  }

  resetSearch() {
    this.collectionRepFilter.rent_collector_id = 0;
    this.collectionRepFilter.payment_method_id = 0;
    this.rentInfoList = [];
    this.collectionList = [];
  }


  loadCollectionReport() {
    this.apiService.getCollectionReportWithFilter(this.collectionRepFilter).subscribe((result: BaseResponse) => {
      if (result.code == 1) {
        this.showDialog(result);
      }
      this.collectionList = result.data;
      this.totalAmount = 0;
      this.collectionList.forEach(data => {
        const receivedAmount = parseFloat(data.received_amount.toString());
        if (typeof receivedAmount === 'number' && !isNaN(receivedAmount)) {
          this.totalAmount += receivedAmount;
        }
      });
    })
  }

  getReceipts() {
    this.apiService.receiptList(this.selectedTenantId).subscribe((result: BaseResponse) => {
      this.receiptList = result.data;
    })
  }

  showDialog(result: any) {
    this.isLoading = false;
    if (result.code == 0) {
      this.toastr.success(result.message)
    } else {
      this.toastr.error(result.message)
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'channel-manager-list'
    );
  }
}