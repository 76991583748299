import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from '@/app-routing.module';
import { AppComponent } from '@/app.component';
import { MainComponent } from '@modules/main/main.component';
import { LoginComponent } from '@modules/login/login.component';
import { HeaderComponent } from '@modules/main/header/header.component';
import { FooterComponent } from '@modules/main/footer/footer.component';
import { MenuSidebarComponent } from '@modules/main/menu-sidebar/menu-sidebar.component';
import { BlankComponent } from '@pages/blank/blank.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from '@pages/profile/profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegisterComponent } from '@modules/register/register.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { MessagesComponent } from '@modules/main/header/messages/messages.component';
import { NotificationsComponent } from '@modules/main/header/notifications/notifications.component';

import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { UserComponent } from '@modules/main/header/user/user.component';
import { ForgotPasswordComponent } from '@modules/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from '@modules/recover-password/recover-password.component';
import { LanguageComponent } from '@modules/main/header/language/language.component';
import { MainMenuComponent } from '@pages/main-menu/main-menu.component';
import { SubMenuComponent } from '@pages/main-menu/sub-menu/sub-menu.component';
import { MenuItemComponent } from '@components/menu-item/menu-item.component';
import { ControlSidebarComponent } from '@modules/main/control-sidebar/control-sidebar.component';
import { StoreModule } from '@ngrx/store';
import { authReducer } from '@store/auth/reducer';
import { uiReducer } from '@store/ui/reducer';
import { ProfabricComponentsModule } from '@profabric/angular-components';
import { defineCustomElements } from '@profabric/web-components/loader';
import { SidebarSearchComponent } from '@components/sidebar-search/sidebar-search.component';
import { APIPrefixInterceptor } from '@guards/apiprefix.interceptor';
import { TokenInterceptor } from '@guards/token.interceptor';
import { APIResponseInterceptor } from '@guards/apiresponse.interceptor';
import { NgbAccordionModule, NgbModule, NgbDropdownModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { PrettyjsonPipe } from '@pipes/prettyjson.pipe';
import { PropertyInformationListComponent } from '@pages/property/property-list/property-list.component';
import { PropertyInformationCreateComponent } from '@pages/property/property-create/property-create.component';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { LoadingInterceptor } from '@guards/loading.interceptor';
import { UserManagementCreateComponent } from '@pages/user-management/user-management-create/user-management-create.component';
import { UserManagementListComponent } from '@pages/user-management/user-management-list/user-management-list.component';
import { OtpPinComponent } from '@modules/otp-pin/otp-pin.component';
import { OtpInputComponent } from '@components/otp-input/otp-input.component';
import { NotificationComponent } from '@pages/notification/notification.component';
import { DateagoPipe } from '@pipes/dateago.pipe';
import { OnlyNumber } from '@utils/onlynumber.directive';
import { FocusFirstInvalidFieldDirective } from '@utils/directives/focus-first-invalid-field.directive';
import { PstitleDirective } from '@utils/directives/pstitle.directive';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { EmailTemplateCreateComponent } from '@pages/settings/email-template/email-template-create/email-template-create.component';
import { EmailTemplateListComponent } from '@pages/settings/email-template/email-template-list/email-template-list.component';
import { ExportAsModule } from 'ngx-export-as';
import { TableDataExporterComponent } from '@components/table-data-exporter/table-data-exporter.component';
import { NoDataComponent } from '@components/no-data/no-data.component';
import { CountryComponent } from '@pages/settings/country/country.component';
import { PaymentModeComponent } from '@pages/property/payment-mode/payment-mode.component';
import { RoleSetupComponent } from '@pages/settings/role-setup/role-setup.component';
import { ChangePasswordComponent } from '@modules/change-password/change-password.component';
import { ViewUserInfoComponent } from '@pages/user-management/view-user-info/view-user-info.component';
import { PSMatInputComponent } from '@components/psmat-input/psmat-input.component';
import { PSMatTextareaComponent } from '@components/psmat-textarea/psmat-textarea.component';
import { TenantsComponent } from '@pages/tenants/tenants.component';
import { PSFileDropzoneComponent } from '@components/psfile-dropzone/psfile-dropzone.component';
import { RentInformationComponent } from '@pages/rent-information/rent-information.component';
import { RentPostingComponent } from '@pages/rent-posting/rent-posting.component';
import { RentReceivedComponent } from '@pages/rent/rent-received/rent-received.component';
import { BillMatchingComponent } from '@pages/rent/bill-matching/bill-matching.component';
import { DocumentComponent } from '@pages/document/document/document.component';
import { LedgerComponent } from '@pages/rent/ledger/ledger.component';
import { RentStatusComponent } from '@pages/rent/rent-status/rent-status.component';
import { UnitsComponent } from '@pages/units/units.component';
import { PaymentMethodsComponent } from '@pages/settings/payment-methods/payment-methods.component';
import { ModalPrintComponent } from '@pages/modal/modal-print/modal-print.component';
import { PSMultiSelectComponent } from '@components/psmulti-select/psmulti-select.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { UsersComponent } from './pages/users/users.component';
import { CollectionReportComponent } from './pages/rent/collection-report/collection-report.component';

defineCustomElements();
registerLocaleData(localeEn, 'en-EN');

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        BlankComponent,
        ProfileComponent,
        RegisterComponent,
        DashboardComponent,
        MessagesComponent,
        NotificationsComponent,
        UserComponent,
        ForgotPasswordComponent,
        RecoverPasswordComponent,
        LanguageComponent,
        MainMenuComponent,
        SubMenuComponent,
        MenuItemComponent,
        ControlSidebarComponent,
        SidebarSearchComponent,
        PrettyjsonPipe,
        PropertyInformationListComponent,
        PropertyInformationCreateComponent,
        SpinnerComponent,
        UserManagementListComponent,
        UserManagementCreateComponent,
        OtpPinComponent,
        OtpInputComponent,
        NotificationComponent,
        DateagoPipe,
        OnlyNumber,
        FocusFirstInvalidFieldDirective,
        PstitleDirective,
        EmailTemplateCreateComponent,
        EmailTemplateListComponent,
        TableDataExporterComponent,
        NoDataComponent,
        CountryComponent,
        PaymentModeComponent,
        RoleSetupComponent,
        ChangePasswordComponent,
        ViewUserInfoComponent,
        PSMatInputComponent,
        PSMatTextareaComponent,
        TenantsComponent,
        PSFileDropzoneComponent,
        RentInformationComponent,
        RentPostingComponent,
        RentReceivedComponent,
        BillMatchingComponent,
        DocumentComponent,
        LedgerComponent,
        RentStatusComponent,
        UnitsComponent,
        PaymentMethodsComponent,
        ModalPrintComponent,
        PSMultiSelectComponent,
        UsersComponent,
        CollectionReportComponent
    ],
    imports: [
        BrowserModule,
        NgMultiSelectDropDownModule.forRoot(),
        StoreModule.forRoot({ auth: authReducer, ui: uiReducer }),
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 4000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        }),
        ProfabricComponentsModule,
        NgbModule,
        NgbDropdownModule,
        NgbAccordionModule,
        NgbTypeaheadModule,
        FormsModule,
        CKEditorModule,
        ExportAsModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: APIResponseInterceptor,
            multi: true
        }, {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: APIPrefixInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }