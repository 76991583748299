import { Component, OnInit, Renderer2 } from '@angular/core';
import { BaseResponse } from '@models/response';
import { PaginationResponse } from '@models/filter-pagination';
import { AppService } from '../../services/app.service';
import { ApiService } from '../../services/api.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ModelRentInformation } from '../../models/rentInformation/model-rent-information';
import { ActivatedRoute } from '@angular/router';
import { ModelTenant } from '../../models/tenant/model-tenant';
import { DataService } from '../../services/data.service';
import { PropertyUnits } from '../../models/units/model-units';
import { ModelFilterUnit } from '../../models/units/model-filter-units';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ListItem } from 'ng-multiselect-dropdown/multiselect.model';

@Component({
  selector: 'rent-information',
  templateUrl: './rent-information.component.html',
  styleUrls: ['./rent-information.component.scss']
})
export class RentInformationComponent implements OnInit {
  selectedTenant: ModelTenant | any;
  paginationRes: PaginationResponse = new PaginationResponse();
  hideSearch: boolean = true;
  public isLoading = false;
  closeResult = '';
  startingSn: number = 1;
  dataCount: number = 0;
  selectedUnits: any = [];
  rentInfoList: Array<ModelRentInformation> = [];
  filterUnits = new ModelFilterUnit(null, null, 0, 0);
  unitInfoList: Array<PropertyUnits> = [];
  public rentInfoForm: UntypedFormGroup;
  selTenantId: any;
  selTenantName: string;
  minStartDate: string;
  maxEndDate: string;
  dialogActFor: string = "Add";
  dropdownSettings = {};

  constructor(private apiService: ApiService, private route: ActivatedRoute, private dataService: DataService, private appService: AppService, private renderer: Renderer2, private modalService: NgbModal, private toastr: ToastrService){
    this.selTenantId = this.route.snapshot.paramMap.get('id');
    this.selectedTenant = dataService.getData();
  }

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'property-list'
    );
    
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 3,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No data',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
    };
    
    this.rentInfoForm = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      tenant_id: new UntypedFormControl(this.selTenantId),
      unit_ids: new UntypedFormControl('', Validators.required),
      unit_id: new UntypedFormControl(''),
      start_date: new UntypedFormControl('', Validators.required),
      end_date: new UntypedFormControl('', Validators.required),
      monthly_rent: new UntypedFormControl(0, Validators.required),
      electricity: new UntypedFormControl(0),
      water: new UntypedFormControl(0),
      other: new UntypedFormControl(0),
      other_information: new UntypedFormControl(0),
      increment_date: new UntypedFormControl('', Validators.required),
      status: new UntypedFormControl(0, Validators.required)
    });

    this.getRentInformation();
    this.getUnitInformation();
  }

  getUnitInformation(){
    this.apiService.getAllUnitsWithFilter(this.filterUnits).subscribe((result: BaseResponse) => {
      this.unitInfoList = result.data;
    })
  }  

  getRentInformation(){
    this.apiService.rentInformationList(this.selTenantId).subscribe((result: BaseResponse) => {
      this.rentInfoList = result.data;
      this.dataCount = this.rentInfoList.length;
    })
  }  

  onDateChange(dateType: string){
    if(dateType === "Start"){
      this.minStartDate = this.rentInfoForm.controls.start_date.value;
    } else {
      this.maxEndDate = this.rentInfoForm.controls.end_date.value;
    }
  }

  add(content){
    this.selectedUnits = [];
    this.dialogActFor = "Add";
    this.rentInfoForm.reset();
    this.rentInfoForm.patchValue({status: ''});
    this.openPopup(content)
  }

  edit(content, rentInfo) {
    this.dialogActFor = "Edit";
    this.rentInfoForm.reset();
    this.selectedUnits = [{'id': rentInfo.unit_id, 'name': rentInfo.unit_name}];
    this.rentInfoForm.patchValue({
      id: rentInfo.id,
      tenant_id: rentInfo.tenant_id,
      start_date: rentInfo.start_date,
      end_date: rentInfo.end_date,
      monthly_rent: rentInfo.monthly_rent,
      electricity: rentInfo.electricity,
      water: rentInfo.water,
      other: rentInfo.other,
      other_information: rentInfo.other_information,
      increment_date: rentInfo.increment_date,
      status: rentInfo.status,
    })
    this.openPopup(content);
	}

  async saveRentInfo(){
    this.rentInfoForm.markAllAsTouched();
    this.rentInfoForm.get('name')?.markAsDirty();
    this.rentInfoForm.patchValue({ tenant_id: this.selTenantId });
    var unit_ids = this.selectedUnits.map(data=> data.id);
    this.rentInfoForm.patchValue({ unit_id: unit_ids });
    if(this.rentInfoForm.valid){
      this.isLoading = true;
      if(this.rentInfoForm.value.id != null){
        await this.apiService.rentInfoUpdate(this.rentInfoForm.value).subscribe((result: any) => {
          this.handleResponse(result);
        });
      } else {
        const formData = { ...this.rentInfoForm.value, tenant_id: this.selTenantId };  
        await this.apiService.rentInfoAdd(formData).subscribe((result: any) => {
          this.handleResponse(result);
        });
      }
      this.closeModal();
    }
  }

  del(content, tenant){
    this.selTenantId = tenant.id;
    this.selTenantName = tenant.name;
    this.openPopup(content);
  }

  async deleteTenant(){
    await this.apiService.tenantDelete(this.selTenantId).subscribe((result: any) => {
      this.handleResponse(result);
    });
    this.closeModal();
  }

  handleResponse(result: any){
    this.isLoading = false;
    if(result.code == 0){
      this.toastr.success(result.message)
      this.getRentInformation();
      this.getUnitInformation();
      this.rentInfoForm.reset();
    } else {
      this.toastr.error(result.message)
    }
  }
 

  openPopup(content){
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
  }
  
  closeModal(){
    this.modalService.dismissAll();
  }
  
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}
}