<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6 d-flex">
                <h1>Bill Matching</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item active"><i class="fa fa-list"></i> Bill Matching</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">                              
                            <div class="row">
                                
                            <div class="col-sm-4">
                                <div class="ps-mat-input">
                                    <select name="tenant_id" [(ngModel)]="payPostingFilter.tenant_id">
                                        <option value="" selected>- Select Debtor -</option>
                                        <option *ngFor="let tenant of tenantList" [value]="tenant.id">{{ tenant.name }}</option>
                                    </select>
                                    <label>Debtor</label>
                                </div>
                            </div>

                                <div class="ps-mat-input ml-2 w-1h3p col-xs-3 col-sm-auto">
                                    <select name="is_bill_matched" [(ngModel)]="payPostingFilter.is_bill_matched">
                                        <option value="">All</option>
                                        <option value="0">Unmatched</option>
                                        <option value="1">Matched</option>
                                    </select>
                                    <label for="is_bill_matched">Type</label>
                                </div>
                                
                                <button class="btn btn-primary btn-sm ml-4" type="submit" (click)="loadMonthlyPostings()"><i
                                        class="fa fa-search"></i>
                                    Search</button>
                                <button class="btn btn-secondary btn-sm ml-2" type="submit" (click)="resetSearch()"><i
                                        class="fa fa-retweet"></i>
                                    Reset</button>
                            </div>
                        </div>
                        <div class="text-right">
                            <app-table-data-exporter tableId="dataTable" fileName="property_list"></app-table-data-exporter>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body table-responsive p-0 tbl-h">
                <div *ngIf="billList != null && billList.length > 0">
                    <table id="dataTable" class="table table-bordered table-head-fixed text-nowrap">
                        <thead>
                            <tr>
                                <th class="text-center" scope="col">Date</th>
                                <th scope="col">Bill Number</th>
                                <th scope="col">Bill Amount</th>
                                <th scope="col">Description</th>
                                <th scope="col">Receipt Number</th>
                                <th scope="col">Matched Amount</th>
                                <th scope="col">Remaining Amount</th>
                                <th class="text-center" scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let bill of billList; index as i">
                                <th class="text-center" scope="row">{{ bill.posting_date }}</th>
                                <td>{{ bill.bill_no }}</td>
                                <td>{{ bill.bill_amount }}</td>
                                <td>{{ bill.description != null ? bill.description : 'N/A' }}</td>
                                <td>{{ bill.receipt_numbers }}</td>
                                <td>{{ bill.matched_amount === null ? 0 : bill.matched_amount }}</td>
                                <td>{{ bill.match_remain_amount === null ? '' : bill.match_remain_amount }}</td>
                                <td class="text-center">
                                    <button class="btn btn-{{ bill.matched_amount > 0 ? 'primary' : bill.match_remain_amount == 0 ? 'secondary' : 'success' }}" (click)="match(content, bill)">
                                        <i class="fa fa-{{ bill.matched_amount > 0 ? 'retweet' : 'equals' }} p-1"></i>
                                        {{ bill.matched_amount > 0 || bill.match_remain_amount == 0 ? 'Re-Match' : 'Match' }}
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- <div class="d-flex justify-content-between p-2">
                        <ngb-pagination [collectionSize]="paginationRes.totalRow" [maxSize]="5"
                            [(page)]="pagination.pageNumber" (pageChange)="onPageChange($event)"
                            [pageSize]="pagination.pageSize" [boundaryLinks]="true">
                        </ngb-pagination>

                        <select class="form-control form-select" style="width: auto" name="pageSize"
                            [(ngModel)]="pagination.pageSize">
                            <option [ngValue]="5">5</option>
                            <option [ngValue]="10">10</option>
                            <option [ngValue]="50">50</option>
                            <option [ngValue]="100">100</option>
                        </select>
                    </div> -->
                </div>
                
                <div *ngIf="billList == null || billList.length == 0">
                    <h4 class="text-center p-4">No Data</h4>
                </div>
            </div>
        </div>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->


<ng-template #content let-modal>
    <div class="modal fade show" tabindex="-1" role="dialog" style="display: block; padding-right: 15px;">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">Match Bill</h4>
                    <i class="fa fa-times fa-2x" (click)="modal.dismiss('Cross click')"></i>
                </div>
                <form [formGroup]="billForm" appFocusFirstInvalidField>
                    <div class="modal-body">
                        <span>Fields marked with an astrisk (<span class="text-danger">*</span>) is required</span>
                        <div class="form-group row">
                            <div class="col-sm-6 mt-4">
                                <div class="ps-mat-input">
                                    <select name="id" formControlName="id" (change)="onOptionSelected($event)">
                                        <option value="">- Select -</option>
                                        <option *ngFor="let receipt of receiptList" [value]="receipt.id">{{ "Rec No: " + receipt.receipt_no + " | Date: " + receipt.received_date + " | Amount: " + receipt.received_amount }}</option>
                                    </select>
                                    <label for="id">Bill Number</label>
                                </div>
                            </div>
                           
                            <div class="col-sm-3 mt-4">
                                <psmat-input onlyNumber="true" formControlName="receipt_amount" label="Bill Amount" controlName="receipt_amount" [parentForm]="billForm" readonly="true"></psmat-input>
                            </div>
                            <div class="col-sm-3 mt-4">
                                <psmat-input type="date" formControlName="date" label="Bill Date" controlName="date" [parentForm]="billForm" readonly="true"></psmat-input>
                            </div>
                            <div class="col-sm-12 mt-4">
                                <psmat-textarea [rows]="4" formControlName="other_information" label="Bill Description" controlName="other_information" [parentForm]="billForm" maxlength="255" readonly="true"></psmat-textarea>
                            </div>
                            <div class="col-sm-4 mt-4">
                                <psmat-input onlyNumber="true" formControlName="match_receipt_amount" label="Remaining Receipt Amount" controlName="match_receipt_amount" [parentForm]="billForm" readonly="true"></psmat-input>
                            </div>
                            <div class="col-sm-4 mt-4">
                                <psmat-input onlyNumber="true" formControlName="match_remain_amount" label="Remaining Bill Amount" controlName="match_remain_amount" [parentForm]="billForm" readonly="true"></psmat-input>
                            </div>
                            <div class="col-sm-4 mt-4">
                                <psmat-input onlyNumber="true" formControlName="matched_amount" label="Match Amount" controlName="matched_amount" [parentForm]="billForm" required="true" (onKeyPress)="onMatchAmountChange($event)"></psmat-input>
                            </div>
                        </div>
                        
                    <div class="col-sm-12 text-right mb-2 mr-0 pr-0">
                        <button type="submit" class="btn btn-success" (click)="saveBillMatch()">
                            <i class="fa fa-save"></i> Save
                        </button>
                    </div>

                        <div>
                            <div *ngIf="matchedBills != null && matchedBills.length > 0">
                                <table id="dataTable" class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th class="text-center txt-sm" scope="col">Receipt Date</th>
                                            <th class="txt-sm" scope="col">Receipt Number</th>
                                            <th class="txt-sm" scope="col">Receipt Amount</th>
                                            <th class="txt-sm" scope="col">Bill Date</th>
                                            <th class="txt-sm" scope="col">Bill Number</th>
                                            <th class="txt-sm" scope="col">Bill Amount</th>
                                            <th class="txt-sm" scope="col">Matched Amount</th>
                                            <th class="txt-sm" scope="col">User</th>
                                            <th class="text-center txt-sm" scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let mBill of matchedBills; index as i">
                                            <td class="td-sm text-center txt-md">{{ mBill.receipt_date }}</td>
                                            <td class="td-sm txt-md">{{ mBill.receipt_no }}</td>
                                            <td class="td-sm txt-md">{{ mBill.receipt_amount }}</td>
                                            <td class="td-sm txt-md">{{ mBill.posting_date }}</td>
                                            <td class="td-sm txt-md">{{ mBill.bill_no }}</td>
                                            <td class="td-sm txt-md">{{ mBill.matched_amount === null ? 0 : mBill.matched_amount }}</td>
                                            <td class="td-sm txt-md">{{ mBill.matched_amount === null ? 0 : mBill.matched_amount }}</td>
                                            <td class="td-sm txt-md">{{ mBill.user }}</td>
                                            <td class="td-sm text-center txt-md">
                                                <button class="btn btn-danger btn-sm txt-md" (click)="unmatch(mBill)">
                                                    Unmatch
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger btn-sm" (click)="closeModal()"><i
                                class="fa fa-times"></i> Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>