import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[pstitle]'
})

export class PstitleDirective {
  @Input() pstitle: string;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.el.nativeElement.setAttribute('pstitle', this.pstitle);
  }

}