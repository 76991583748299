<div class="card card-outline card-primary">
    <div class="card-header text-center">
        <a [routerLink]="['/']"><img class="aln-center" src="../../../assets/img/logo.png" /></a>
    </div>
    <div class="card-body">
        <form [formGroup]="loginForm" (keyup.enter)="loginByAuth()" (ngSubmit)="loginByAuth()">
            
            <div>
                <psmat-input type="email" formControlName="email" label="Email" controlName="email" [parentForm]="loginForm"></psmat-input>
            </div>
            
            <div class="mt-4">
                <psmat-input type="password" formControlName="password" label="Password" controlName="password" [parentForm]="loginForm"></psmat-input>
            </div>

            <div class="row mt-4">
                <div class="col-7">
                    <div class="icheck-primary">
                        <input type="checkbox" id="remember" formControlName="remember" />
                        <label for="remember"> Remember Me </label>
                    </div>
                </div>
                <div class="col-5">
                    <pf-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">
                        Login
                    </pf-button>
                </div>
            </div>
        </form>

        <p class="mt-2 mb-0 text-center">
            <!-- <a [routerLink]="['/forgot-password']" class="col-6">Forgot my password?</a> -->
            <a [routerLink]="['/register']">Register Now</a>
        </p>
    </div>
    <!-- /.login-card-body -->
    <div class="card-footer">
        <p class="text-center fs-6 lh1 mb-0">
            <span class="fs-6">Copyright © {{data.copyrightYear}} Sarvanam Software</span><br />
            <span class="fs-6">Version {{data.versionNumber}}</span>
        </p>
    </div>
</div>