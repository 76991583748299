/*
    Class EP
    API End Points
*/
export class EP {
    /* Authorize */
    public static LOGIN: string = "login";
    public static SIGNUP: string = "signup";
    public static FORGOT_PW: string = "Authorize/ForgotPassword";
    public static CHANGE_PW: string = "user/changePassword";
    public static VERIFY_PW_TKN: string = "Authorize/VerifyPasswordToken";
    public static RESET_PW: string = "Authorize/ResetPassword";
    public static RE_SEND_OTP: string = "Authorize/ResendOTP";
    public static VERIFY_OTP: string = "Authorize/ValidateOTP";

    /* Dashboard */
    public static ADMIN_DASHBOARD: string = "adminDashboard/"; // Get admin dashboard
    public static OWNER_DASHBOARD: string = "ownerDashboard/"; // Get owner dashboard
    public static TENANT_DASHBOARD: string = "tenantDashboard/"; // Get tenant dashboard

    /* Users */
    public static ALL_USERS: string = "users"; // Get users list
    public static ALL_OWNER_USERS: string = "owners"; // Get owner users list

    /* Tenant */
    public static ALL_TENANT: string = "tenants"; // Get tenant list
    public static GET_TENANT: string = "tenant/"; // Get tenant of id    
    public static ADD_TENANT: string = "tenant"; // Add new tenant
    public static UPD_TENANT: string = "tenant/update"; // Update tenant of id
    public static DEL_TENANT: string = "tenant/delete/"; // Delete tenant of id

    /* Document */
    public static ALL_DOC: string = "images"; // Get images list
    public static ALL_DOC_OF_TENANT: string = "images/"; // Get images list
    public static GET_DOC: string = "image/"; // Get image of id    
    public static SHOW_IMG: string = "showImage/"; // Get image of name    
    public static ADD_DOC: string = "image"; // Add new image
    public static UPD_DOC: string = "image/update"; // Update image of id
    public static DEL_DOC: string = "image/delete/"; // Delete image of id

    /* Document Type */
    public static ALL_DOC_TYPE: string = "docTypes"; // Get documents list
    public static GET_DOC_TYPE: string = "docType/"; // Get document of id    
    public static ADD_DOC_TYPE: string = "docType"; // Add new document
    public static UPD_DOC_TYPE: string = "docType/update"; // Update document of id
    public static DEL_DOC_TYPE: string = "docType/delete/"; // Delete document of id

    /* Rent Info */
    public static ALL_RENT_INFO: string = "rentInfos"; // Get rent info list
    public static DEBTOR_RENT_INFO: string = "rentInfos/"; // Get debtor rent info list
    public static RENT_INFOS: string = "rentInfos"; // Get rent info list
    public static GET_RENT_INFO: string = "rentInfo/"; // Get rent info of id    
    public static ADD_RENT_INFO: string = "rentInfo"; // Add new rent info
    public static UPD_RENT_INFO: string = "rentInfo/update"; // Update rent info of id
    public static DEL_RENT_INFO: string = "rentInfo/delete/"; // Delete rent info of id

    /* Received Rent Info */
    public static ALL_REC_RENT_INFO: string = "receivedRents"; // Get received rent info list
    public static DEBTOR_REC_RENT_INFO: string = "receivedRents/"; // Get received rent info list -> this api requires: receivedRents/tenantId/rentCode = receivedRents/1/RC_00001_...
    public static GET_REC_RENT_INFO: string = "receivedRent/"; // Get received rent info of id    
    public static PRINT_RENT_RECEIVED: string = "receivedRentBill/"; // Print rent received bill of id  
    public static ADD_REC_RENT_INFO: string = "receiveRent"; // Add new received rent info
    public static UPD_REC_RENT_INFO: string = "receivedRent/update"; // Update received rent info of id
    public static DEL_REC_RENT_INFO: string = "receivedRent/delete/"; // Delete received rent info of id

    /* Reports */
    public static COLLECTION_REPORT: string = "collectionReport"; // Get collection report unit owner wise
    public static LEDGER: string = "ledger"; // Get ledger details of tenant for room
    public static RENT_STATUS: string = "rentStatus"; // Get ledger details of tenant for room

    /* Payment Method */
    public static ALL_PAY_METHOD: string = "payMethods"; // Get all payment methods
    public static GET_PAY_METHOD: string = "payMethod/"; // Get all payment methods
    public static ADD_PAY_METHOD: string = "payMethod"; // Add new payment methods
    public static UPD_PAY_METHOD: string = "payMethod/update"; // Update payment methods
    public static DEL_PAY_METHOD: string = "payMethod/delete/"; // Delete payment methods

    /* Rent Posting */
    public static ALL_RENT_POSTING: string = "allMonthlyRentPostings"; // Get rent posting info list
    public static ALL_MONTHLY_POSTINGS: string = "allMonthlyRentPostings"; // Get rent posting info list
    public static MONTHLY_POSTINGS: string = "monthlyRentPostings"; // Get rent posting info list
    public static GET_MONTHLY_POSTINGS: string = "monthlyRentPostings"; // Get rent posting info list
    public static TENANT_RENT_POSTING: string = "monthlyRentPostings/"; // Get rent posting info list
    public static GET_RENT_POSTING: string = "monthlyRentPosting/"; // Get rent posting info of id    
    public static PRINT_RENT_POSTING: string = "monthlyRentPostingBill/"; // Print rent posting info of id    
    public static ADD_RENT_POSTING: string = "monthlyRentPosting"; // Add new rent posting info
    public static UPD_RENT_POSTING: string = "monthlyRentPosting/update"; // Update rent posting info of id
    public static DEL_RENT_POSTING: string = "monthlyRentPosting/delete/"; // Delete rent posting info of id

    /* Rent Posting */
    public static ADD_BILL_MATCHING: string = "billMatching"; // Add new Bill matching
    public static GET_MATCHED_BILLS: string = "allMatchedPostings"; // Get all matched Bills
    public static DEL_BILL_MATCHING: string = "billMatching/delete"; // Add new Bill matching
    public static BILL_UN_MATCH: string = "billUnMatch"; // Bill unmatch


    /* Balance Amount View */
    public static ALL_BALANCE_AMT: string = "balance"; // Get balance amount view info list
    public static ALL_TENANT_BALANCE_AMT: string = "balance/"; // Get balance amount view info list
    public static BALANCE_AMT_FOR_RC: string = "balance/"; // Get balance amount view info of id -> this api requires: balance/tenantId/rentCode = balance/1/RC_00001_...

    /* Property */
    public static ALL_PROPERTY: string = "allproperty"; // Get property list
    public static GET_PROPERTY: string = "property/"; // Get property of id    
    public static ADD_PROPERTY: string = "property"; // Add new property
    public static UPD_PROPERTY: string = "property/update"; // Update property of id

    /* Property Address */
    public static GET_ADRS: string = "address/"; // Get property of id    
    public static GET_PROP_ADRS: string = "propertyAddress/"; // Get property of id    
    public static ADD_PROP_ADRS: string = "address"; // Add new property
    public static UPD_PROP_ADRS: string = "address/update"; // Update property of id

    /* Property Units */
    public static GET_ALL_UNITS: string = "units"; // Get/Post method for property units of id
    public static GET_PROP_UNITS: string = "propertyUnits/"; // Get property units of id
    public static ADD_PROP_UNIT: string = "unit"; // Add property unit
    public static ADD_PROP_UNITS: string = "propertyUnits"; // Add property units
    public static UPD_PROP_UNIT: string = "unit/update"; // Update property units of id
    public static UPD_PROP_UNITS: string = "propertyUnits/update"; // Update property units of id





    
    /* Axis Room Booking */
    public static ARB_LIST: string = "AxisRoomBooking/List"; // Axis Room Booking List
    public static ARB_GET: string = "AxisRoomBooking/"; // Axis Room Booking of Id
    
    /* Axis Room Booking Update */
    public static ARBU_NEW: string = "AxisRoomBookingUpdate"; // Axis Room Booking Update to add new room booking
    public static ARBU_UPD: string = "AxisRoomBookingUpdate/Update"; // Axis Room Booking update to update booking
    
    /* Axis Room Category Mapping */
    public static ARCM_NEW: string = "AxisRoomCategoryMapping"; // Add new Axis Room Category Mapping
    public static ARCM_GET_ALL: string = "AxisRoomCategoryMapping"; // Get Axis Room Category Mappings
    public static ARCM_UPD: string = "AxisRoomCategoryMapping/Update"; // Update Axis Room Category Mapping
    public static ARCM_GET: string = "AxisRoomCategoryMapping/"; // Get Axis Room Category Mapping. Required id
    
    /* Axis Room Setting */
    public static ARS_NEW: string = "AxisRoomSetting"; // Add new Axis Room Setting
    public static ARS_UPD: string = "AxisRoomSetting/Update"; // Update Axis Room Setting
    public static ARS_GET: string = "AxisRoomSetting/"; // Get Axis Room Setting. Required id
    public static ARS_LIST: string = "AxisRoomSetting/List"; // Get All Axis Room Setting
    
    /* Channel Manager */
    public static CM_ADD: string = "ChannelManager"; // Add new channel manager
    public static CM_UPD: string = "ChannelManager/Update"; // Update channel manager
    public static CM_GET: string = "ChannelManager/"; // Get channel manager. Requires Id
    public static CM_LIST: string = "ChannelManager/List"; // Get All channel manager
    public static CM_DB_DATA: string = "ChannelManagerDashBoard"; // Get All channel manager dashboard data
    
    /* Channel Manager Mapping */
    public static CMM_ADD: string = "ChannelManagerMapping"; // Add new channel manager Mapping
    public static CMM_GET: string = "ChannelManagerMapping/"; // Get channel manager Mapping. Requires Id
    
    /* Channel Manager Property Hotel */
    public static CMPH_ADD: string = "ChannelManagerPropertyHotel"; // Add new channel manager property hotel
    public static CMPH_UPD: string = "ChannelManagerPropertyHotel/Update"; // Update channel manager property hotel
    public static CMPH_GET: string = "ChannelManagerPropertyHotel/Single/"; // Get Signle channel manager property hotel by Id
    public static CMPH_GET_ALL: string = "ChannelManagerPropertyHotel/"; // Get Signle channel manager property hotel. Requires Id
    
    /* Channel Manager Property Information */
    public static CMPI_ADD: string = "ChannelManagerPropertyInformation"; // Add new channel manager property information
    public static CMPI_UPD: string = "ChannelManagerPropertyInformation/Update"; // Update channel manager property information
    public static CMPI_LIST: string = "ChannelManagerPropertyInformation/List"; // List all channel manager property information
    public static CMPI_GEN_SALT: string = "ChannelManagerPropertyInformation/GenerateSalt"; // Generate channel manager property information salt
    public static CMPI_GEN_TKN: string = "ChannelManagerPropertyInformation/GenerateToken"; // Generate channel manager property information token
    public static CMPI_GET_THIS: string = "ChannelManagerPropertyInformation/GetByChannelManagerId/"; // Generate channel manager property information token

    /* Market Segment Mapping */
    public static MSM_ADD: string = "MarketSegmentMapping"; // Add new market segment map information
    public static MSM_GET: string = "MarketSegmentMapping/"; // Get market segment map information
    public static MSM_UPD: string = "MarketSegmentMapping/Update"; // Update market segment map information
    public static MSM_LIST: string = "MarketSegmentMapping/List"; // Get all the list of market segment mappings

    /* Payment Mode Mapping */
    public static PMM_ADD: string = "PaymentModeMapping"; // Add new payment mode map information
    public static PMM_GET: string = "PaymentModeMapping/"; // Get payment mode map information
    
    /* Channel Manager User */
    public static CMU_ADD: string = "ChannelManagerUser"; // Add new channel manager user
    public static CMU_UPD: string = "ChannelManagerUser/Update"; // Update channel manager user
    public static CMU_GET: string = "ChannelManagerUser/"; // Get channel manager user. Requires Id
    public static CMU_LIST: string = "ChannelManagerUser/List"; // Get all channel manager user
    public static CMU_PROP_LI_GET: string = "ChannelManagerUserProperty/List/"; // Get all channel manager user
    public static CMU_PROP_ADD: string = "ChannelManagerUserProperty"; // Get all channel manager user
    public static CMU_RESET_PW: string = "ChannelManagerUser/ResetUserPassword"; // Reset channel manager user password
    public static CMU_CHANGE_PW: string = "ChannelManagerUser/ChangeUserPassword"; // Change channel manager user password
    public static CMU_FORCE_CHANGE_PW: string = "ChannelManagerUser/ForceChangeUserPassword"; // Change channel manager user password
    public static CMU_ASIN_ROLE: string = "ChannelManagerUser/AssignUserRight"; // Assign channel manager user rights
    public static CMU_LOCK_UNLOCK: string = "ChannelManagerUser/LockUnlockUser"; // Lock or Unlock channel manager user
    
    /* Email Template */
    public static EMAIL_TMLT_ADD: string = "EmailTemplate"; // Add new email template
    public static EMAIL_TMLT_UPD: string = "EmailTemplate/Update"; // Update email template
    public static EMAIL_TMLT_GET: string = "EmailTemplate/"; // Get email template. Requires Id
    public static EMAIL_TMLT_LIST: string = "EmailTemplate/List"; // Get email template
    
    /* Meal Plan Mapping */
    public static MPM_ADD: string = "MealPlanMapping"; // Add new meal plan mapping
    public static MPM_GET: string = "MealPlanMapping/"; // Get meal plan mapping. Requires Id
    
    /* Pull Booking */
    public static PULL_BOOKING: string = "PullBooking"; // Get all bookings
    
    /* Rate Type Mapping */
    public static RTM_ADD: string = "RateTypeMapping"; // Add new rate type mapping
    public static RTM_GET: string = "RateTypeMapping/"; // Get rate type mapping. Requires Id
    
    /* Reservation */
    public static RESERVATION_ADD: string = "Reservation"; // Add new reservation
    public static RESERVATION_UPD: string = "Reservation/Update"; // Update reservation
    public static RESERVATION_GET: string = "Reservation/"; // Get reservation. Requires Id
    
    /* User */
    public static USR_PROFILE: string = "user/"; // Get user profile
    public static USERS: string = "users"; // Get all users list
    public static ADD_USER: string = "user"; // Add user
    public static UPD_USER: string = "user/update"; // Add user
    public static USR_PROFILE_GET: string = "User/EditProfile"; // Get user profile
    public static USR_PROFILE_UPD: string = "user/update"; // Update user profile

    /* Role Setup */
    public static ROLE_ADD: string = "role"; // Add new user role
    public static ROLE_UPDATE: string = "role/update"; // Update user role.
    public static ROLE_GET: string = "role/"; // Get user role. Requires Id
    public static ROLE_LIST: string = "roles"; // Get user role.
    
    /* User Role */
    public static USR_ROLE_ADD: string = "User/UserRole"; // Add new user role
    public static USR_ROLE_UPD: string = "User/UserRole/Update"; // Update user role.
    public static USR_ROLE_GET: string = "User/UserRole/"; // Get user role. Requires Id

    /* Channel manager user role */
    public static CM_USR_ROLE_ADD: string = "ChannelManagerUserRole"; // Add new role for cm user
    public static CM_USR_ROLE_UPDATE: string = "ChannelManagerUserRole/UpdateUserRole"; // Update role for cm user.
    public static CM_USR_ROLE_GET: string = "ChannelManagerUserRole/"; // Get user role. Requires Id
    public static CM_USR_ROLE_LIST: string = "ChannelManagerUserRole/List"; // Get user role list
    public static CM_USR_ROLE_LIST_GET: string = "ChannelManagerUserRole/List/"; // Get user role list

    /* Country List */
    public static COUNTRY_ADD: string = "Country"; // Add new country
    public static COUNTRY_UPDATE: string = "Country/Update"; // Update country.
    public static COUNTRY_GET: string = "Country/"; // Get country. Requires Id
    public static COUNTRY_LIST: string = "Country/List"; // Get country. Requires Id

}
