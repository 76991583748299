import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal-print',
  templateUrl: './modal-print.component.html',
  styleUrls: ['./modal-print.component.scss']
})
export class ModalPrintComponent implements OnInit {
  @Input() htmlContent: any;

  constructor(private sanitizer: DomSanitizer, public activeModal: NgbActiveModal) { }

  ngOnInit() {
    // console.log(this.htmlContent);
  }


  get sanitizedHtmlContent(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.htmlContent);
  }

  closeModal() {
    this.activeModal.close('Modal closed');
  }

  printBill() {
    const a4Width = 794;
    const a4Height = 1123;

    const printWindow = window.open('', '', `width=${a4Width},height=${a4Height}`)!;

    printWindow.document.open();
    printWindow.document.write(`
    <html>
    <head>
      <title>Print</title>
      <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css">
      <link rel="stylesheet" type="text/css" href="./modal-print.component.scss">
      <style>
        @media print {
          @page {
            size: A4;
          }
        }
      </style>
    </head>
    <body>
      <div>${this.htmlContent}</div>
    </body>
    </html>
  `);

    const originalImage = document.querySelector('td img') as HTMLImageElement;
    if (originalImage) {
      const copiedImage = new Image();
      copiedImage.src = originalImage.src;
      copiedImage.style.height = '50px';
      copiedImage.style.width = 'auto';
      printWindow.document.body.appendChild(copiedImage);
    }

    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  }


}
