<div class="card card-outline card-primary">
    <div class="card-header text-center">
        <a [routerLink]="['/']" class="h1"><img src="../../../assets/img/logo.png" /></a>
    </div>
    <div class="card-body">
        <p class="login-box-msg">
            You are only one step a way from your new password, recover your
            password now.
        </p>
        <form [formGroup]="recoverPasswordForm" (ngSubmit)="recoverPassword()">
            <div class="col-sm-12">
                <span class="text-danger">*</span>
                <label for="password">New Password</label>
                <input type="password" class="form-control" placeholder="New Password"
                    formControlName="password">
                <div *ngIf="recoverPasswordForm.controls['password'].dirty || recoverPasswordForm.controls['password'].touched"
                    class="text-danger">
                    <span
                        *ngIf="recoverPasswordForm.controls['password'].errors?.['required']">*New password
                        is
                        required<br /></span>
                    <div
                        *ngIf="!recoverPasswordForm.controls['password'].errors?.['required'] && recoverPasswordForm.controls['password'].invalid">
                        <span
                            class="{{ containsPTRN('password', numPtrn) ? 'text-success' : 'text-danger' }}">*New password
                            must contain at least one number.<br /></span>
                        <span
                            class="{{ containsPTRN('password', ucPtrn) && containsPTRN('password', lcPtrn) ? 'text-success' : 'text-danger' }}">*one
                            uppercase and a lowercase letter<br /></span>
                        <span
                            class="{{ contains8Char() ? 'text-success' : 'text-danger' }}">*At
                            least 8
                            characters<br /></span>
                        <span
                            class="{{ containsPTRN('password', spcPtrn) ? 'text-danger' : 'text-success' }}">*New password
                            cannot contain whitespace<br /></span>
                        <span
                            class="{{ containsPTRN('password', spclPtrn) ? 'text-success' : 'text-danger' }}">*At
                            least 1 special character<br /></span>
                    </div>
                </div>

            </div>
            <div class="col-sm-12 mt-3">
                <span class="text-danger">*</span>
                <label for="confirmPassword">Confirm New Password</label>
                <input type="password" class="form-control"
                    placeholder="Confirm New Password" formControlName="confirmPassword">
                <div *ngIf="recoverPasswordForm.controls['confirmPassword'].dirty || recoverPasswordForm.controls['confirmPassword'].touched"
                    class="text-danger">
                    <span
                        *ngIf="recoverPasswordForm.controls['confirmPassword'].errors?.['required']">*Confirm
                        password
                        is
                        required<br /></span>
                    <span
                        *ngIf="!recoverPasswordForm.controls['confirmPassword'].errors?.['required'] && recoverPasswordForm.errors?.['passwordMatched']">*Confirm
                        password do not match</span>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <pf-button [type]="'submit'" [block]="true">
                        Change password
                    </pf-button>
                </div>
            </div>
        </form>

        <p class="mt-3 mb-1">
            <a [routerLink]="['/login']">Login</a>
        </p>
    </div>
</div>
