import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';

@Injectable()
export class APIResponseInterceptor implements HttpInterceptor {

  constructor(private toastr: ToastrService, private appService: AppService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap(() => { },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status == 0) {
            this.toastr.error("Something went wrong.");
          } else if (err.status !== 401) {
            return;
          }
          this.appService.logout();
        }
      }));
  }
}
