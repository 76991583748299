import { Component } from '@angular/core';

@Component({
  selector: 'psfile-dropzone',
  templateUrl: './psfile-dropzone.component.html',
  styleUrls: ['./psfile-dropzone.component.scss']
})
export class PSFileDropzoneComponent {

}
