import { Component, Input } from '@angular/core';
import { ExportAsConfig, ExportAsService } from "ngx-export-as";

@Component({
  selector: 'app-table-data-exporter',
  templateUrl: './table-data-exporter.component.html',
  styleUrls: ['./table-data-exporter.component.scss']
})


export class TableDataExporterComponent {
  exportAsConfig: ExportAsConfig;
  @Input() tableId = '';
  @Input() fileName = '';
  constructor(private exportAsService: ExportAsService) { }


  public export(fileType: any) {
    this.exportAsConfig = {
      type: fileType,
      elementIdOrContent: this.tableId,
      options: {
        jsPDF: {
          orientation: 'landscape'
        },
        margins: {
          top: '20'
        }
      }
    };

    this.exportAsService
      .save(
        this.exportAsConfig,
        this.fileName != '' ? this.fileName : 'SarvanamRMS'
      )
      .subscribe(() => {
      });
  }

}
