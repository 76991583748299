import { Component, OnInit, Renderer2 } from '@angular/core';
import { EmailTemplate } from '@models/email-template';
import { FilterPagination, PaginationResponse } from '@models/filter-pagination';
import { BaseResponse } from '@models/response';
import { ApiService } from '@services/api.service';

@Component({
  selector: 'email-template-list',
  templateUrl: './email-template-list.component.html',
  styleUrls: ['./email-template-list.component.scss']
})
export class EmailTemplateListComponent implements OnInit {
  emailTemplates: Array<EmailTemplate> = [];
  pagination: FilterPagination = new FilterPagination(1, 10, 'emailTemplateId', 'DESC', '', '');
  paginationRes: PaginationResponse = new PaginationResponse();
  hideSearch: boolean = true;

  constructor(private apiService: ApiService, private renderer: Renderer2) {}

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'email-template-list'
    );
    this.getEmailTemplageList();
  }

  hideOrShowSearch() {
    this.hideSearch = !this.hideSearch;
  }

  onPageChange(pageNo: number) {
    this.pagination.pageNumber = pageNo;
    this.getEmailTemplageList();
  }

  resetSearch() {
    this.pagination.isActive = '';
    this.pagination.searchTerm = '';
    this.getEmailTemplageList();
  }

  getEmailTemplageList() {
    this.apiService.emailTemplateList(this.pagination).subscribe((result: BaseResponse) => {
      this.emailTemplates = result.data;
      this.paginationRes = result.pagination;
    })
  }

}