import { Component, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseResponse } from '@models/response';
import { ApiService } from '@services/api.service';
import { ModelChannelManagerPaymentModeMap, ModelPaymentModeChannelManagers, ModelPaymentModeMapping } from '@models/model-channel-manager-payment-mode-map';
import { RL } from '../../../utils/static/route-link';
import { SMsg } from '../../../utils/static/s-msg';
import { Utils } from '../../../utils/utils';

@Component({
  selector: 'payment-mode',
  templateUrl: './payment-mode.component.html',
  styleUrls: ['./payment-mode.component.scss']
})
export class PaymentModeComponent implements OnInit {
  channelManagerPropertyInformationId: string = '';
  paymentModeMapInfo: ModelChannelManagerPaymentModeMap;
  channelManagers: Array<ModelPaymentModeChannelManagers>;
  public paymentModeForm: UntypedFormGroup;
  public newPaymentModeForm: UntypedFormGroup;
  public isLoading = false;
  public isDataChanged = false;

  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    let channelManagerPropertyInformationId: any = this.route.snapshot.paramMap.get('id');
    if (channelManagerPropertyInformationId != null) {
      this.channelManagerPropertyInformationId = channelManagerPropertyInformationId;
      this.isLoading = true;
      this.getPaymentModes();
    }

    this.newPaymentModeForm = new UntypedFormGroup({
      channelManagerPaymentModeId: new UntypedFormControl(null, Validators.required),
      channelManagerPaymentModeName: new UntypedFormControl(null, Validators.required),
      propertyPaymentModeId: new UntypedFormControl(null, Validators.required),
      propertyPaymentModeName: new UntypedFormControl(null, Validators.required),
      isDefault: new UntypedFormControl('N', Validators.required),
      isActive: new UntypedFormControl('Y', Validators.required)
    });

    this.paymentModeForm = new UntypedFormGroup({
      channelManagerPaymentModeId: new UntypedFormControl(null, Validators.required),
      channelManagerPaymentModeName: new UntypedFormControl(null, Validators.required),
      propertyPaymentModeId: new UntypedFormControl(null, Validators.required),
      propertyPaymentModeName: new UntypedFormControl(null, Validators.required),
      isDefault: new UntypedFormControl('N', Validators.required),
      isActive: new UntypedFormControl('Y', Validators.required)
    });

  }

  changeThisData(channelManagerId: number, index: number, fieldName: string, value: string){
    this.isDataChanged = true;
    this.channelManagers.forEach(element => {
      if (element.channelManagerId == channelManagerId) {
        if(fieldName == "isDefault"){
          if (value != 'N') {
            element.paymentModeMapping.forEach(mealPlan => {
              mealPlan.isDefault = 'N';
            });
          }
        }
        element.paymentModeMapping[index][fieldName] = value;
      }
    });
  }

  addNewRow(channelManagerId: number, index: number) {
    this.newPaymentModeForm.markAllAsTouched();
    if (this.newPaymentModeForm.valid) {
      this.isLoading = true;
      const cmPaymentModeId = this.newPaymentModeForm.value.channelManagerPaymentModeId;
      const cmPaymentModeName = this.newPaymentModeForm.value.channelManagerPaymentModeName;
      const prTypeId = this.newPaymentModeForm.value.propertyPaymentModeId;
      const prTypeName = this.newPaymentModeForm.value.propertyPaymentModeName;
      const selOptIsDefault = this.newPaymentModeForm.value.isDefault;
      const selOptIsActive = this.newPaymentModeForm.value.isActive;

      if (this.containsDuplicateId(channelManagerId, cmPaymentModeId)) {
        this.toastr.error("Payment mode map with channel manager payment mode id '" + cmPaymentModeId + "' already exists", "Error");
      } else {
        const newPaymentModeMap: ModelPaymentModeMapping = new ModelPaymentModeMapping(cmPaymentModeId, cmPaymentModeName, prTypeId, prTypeName, selOptIsDefault, true, selOptIsActive);
        this.channelManagers[index].paymentModeMapping.push(newPaymentModeMap);
        const newMapIndex = this.channelManagers[index].paymentModeMapping.length - 1;
        this.changeThisData(channelManagerId, newMapIndex, 'isDefault', selOptIsDefault);
        this.newPaymentModeForm.reset();
        this.newPaymentModeForm.controls.isDefault.setValue('N');
        this.newPaymentModeForm.controls.isActive.setValue('Y');
      }
    }
  }

  containsDuplicateId(channelManagerId: number, cmPaymentModeId: string): boolean {
    var hasDuplicateId = false;
    this.channelManagers.forEach(element => {
      if (element.channelManagerId == channelManagerId) {
        element.paymentModeMapping.forEach(mealPlan => {
          if (mealPlan.channelManagerPaymentModeId == cmPaymentModeId) {
            hasDuplicateId = true;
            return;
          }
        });
      }
    });
    return hasDuplicateId;
  }

  removeItem(cmIndex: number, mapIndex: number) {
    this.channelManagers[cmIndex].paymentModeMapping.splice(mapIndex, 1);
  }

  resetChanges() {
    this.isDataChanged = false;
    this.channelManagers.forEach(cm => {
      for (let i = cm.paymentModeMapping.length - 1; i >= 0; i--) {
        const rtm = cm.paymentModeMapping[i];
        if (rtm.isCustom) {
          cm.paymentModeMapping.splice(i, 1);
        }
      }
    });
  }

  async saveNewMarketSegmentMapping() {
    this.apiService
      .insertUpdatePaymentModeMapping(this.paymentModeMapInfo)
      .subscribe((response: BaseResponse) => {
        if(response.code == '200'){
          this.toastr.success(response.message);
          this.isDataChanged = false;
          this.getPaymentModes();
        }
      });
  }

  async getPaymentModes() {
    this.apiService.paymentModeMappingSingle(this.channelManagerPropertyInformationId).subscribe((response: BaseResponse) => {
        this.paymentModeMapInfo = response.data;
        if(Utils.isNotNullOrEmpty(this.paymentModeMapInfo)){
          if(Utils.isNotNullOrEmpty(this.paymentModeMapInfo.channelManager)){
            this.channelManagers = this.paymentModeMapInfo.channelManager;
          } else { this.showToast(); }
        } else { this.showToast(); }
      });
  }
  
  showToast(){    
    this.toastr.error(SMsg.NO_CM_ADDED);
    this.router.navigate([RL.CM_LI]);
  }

  onBackPressed() {
    this.router.navigate(['property']);
  }
}
