import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Gatekeeper } from 'gatekeeper-client-sdk';
import { ApiService } from '../services/api.service';
import { RL } from '../utils/static/route-link';
import { SMsg } from '../utils/static/s-msg';
import { SKey } from '../utils/static/s-str';

@Injectable({
    providedIn: 'root'
})

export class AppService {
    public user: any = null;

    constructor(private router: Router, private toastr: ToastrService, private apiService: ApiService) { }

    async verifyOTP(model) {
        try {
            await this.apiService.verifyOTP(model).subscribe((response: any) => {
                var data = response;
                if (data != null && data != '') {
                    localStorage.removeItem(SKey.TMP_TOKEN);
                    localStorage.setItem(SKey.TOKEN, data.token);
                    this.apiService.profile(data.userId).subscribe((responseProfile) => {
                        localStorage.setItem(SKey.PROFILE, JSON.stringify(responseProfile));
                        this.router.navigate([RL.ROOT]);
                        this.toastr.success(SMsg.LOGIN_SUCCESS);
                    })
                } else {
                    this.toastr.error(SMsg.OTP_VER_FAIL);
                }
            });
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async loginByAuth(model) {
        try {
            await this.apiService.login(model).subscribe((response: any) => {
                var data = response;
                if (data != null && data.token != null && data.token != '') {
                    localStorage.setItem(SKey.UID, data.userId);
                    // if (response.userVerified == true) {
                    //     localStorage.setItem(SKey.TMP_TOKEN, data.token);
                    //     this.router.navigate([RL.OTP_PIN]);
                    //     this.toastr.success(SMsg.OTP_SENT);
                    // } else {
                        localStorage.setItem(SKey.TOKEN, data.token);
                        this.apiService.profile(data.userId).subscribe((responseProfile) => {
                            const resProfileData = responseProfile.data;
                            localStorage.setItem(SKey.PROFILE, JSON.stringify(resProfileData));
                            this.router.navigate([RL.ROOT]);
                            this.toastr.success(SMsg.LOGIN_SUCCESS);
                        })
                    // }
                } else {
                    this.toastr.error(SMsg.LOGIN_FAILED);
                }
            });
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async changePassword(model) {
        try {
            await this.apiService.forceChangePassword(model).subscribe((response: any) => {
                if(response.code == 200){
                    localStorage.removeItem(SKey.TMP_TOKEN);
                    this.toastr.success(SMsg.PW_RESET_SUCCESS);
                    this.router.navigate([RL.SIGN_IN]);
                } else {
                    this.toastr.error(SMsg.PW_RESET_ERROR);
                }
            });
        } catch (error) {
            this.toastr.error(SMsg.STH_WENT_RNG);
        }
    }

    async registerByAuth(model) {
        try {
            await this.apiService.signUp(model).subscribe((response: any) => {
                if (response != null && response.code == 0) {
                    this.router.navigate([RL.SIGN_IN]);
                    this.toastr.success(SMsg.REG_SUCCESS);
                } else {
                    this.toastr.error(SMsg.REG_FAILED);
                }
            });
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async getProfile() {
        try {
            const userObj: any = localStorage.getItem(SKey.PROFILE);
            this.user = JSON.parse(userObj);
        } catch (error) {
            this.logout();
            throw error;
        }
    }

    getProfilePromise(): Promise<any> {
        return new Promise((resolve, reject) => {
          const userObj: any = localStorage.getItem(SKey.PROFILE);
          if (userObj) {
            const user = JSON.parse(userObj);
            resolve(user);
          } else {
            reject('User profile not found');
          }
        });
      }

    logout() {
        localStorage.removeItem(SKey.TOKEN);
        localStorage.removeItem(SKey.PROFILE);
        this.user = null;
        this.router.navigate([RL.SIGN_IN]);
    }

    get tempToken(): string | null {
        var token = localStorage.getItem(SKey.TMP_TOKEN);
        return token;
    }

    get token(): string | null {
        var token = localStorage.getItem(SKey.TOKEN);
        return token;
    }

    get userId(): string {
        var userId = localStorage.getItem(SKey.UID);
        return userId ? userId : "";
    }

    get name(): string {
        var name = this.user.name;
        return name ? name : "";
    }

    get roleId(): number {
        var roleId = this.user.role;
        return roleId;
    }
}
