<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ userId == '' ? 'Create' : 'Edit' }} User Information</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item active"><i class="fa fa-user"></i> {{ userId == '' ? 'Create' : 'Edit' }} User</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card">
            <form [formGroup]="userManagementForm" appFocusFirstInvalidField (ngSubmit)="saveUserDetails()">
                <div class="card-body">
                    <p>Fields marked with an astrisk (<span class="text-danger">*</span>) is required.</p>
                    <div class="form-group row">
                        <div class="col-sm-3">
                            <div class="ps-mat-input">
                                <select name="title" formControlName="salutation">
                                    <option value="">- Select -</option>
                                    <option value="Mr.">Mr.</option>
                                    <option value="Mrs.">Mrs.</option>
                                    <option value="Ms.">Ms.</option>
                                </select>
                                <label for="salutation"><span class="text-danger">*</span> Title</label>
                            </div>
                            <div *ngIf="userManagementForm.controls['salutation'].dirty || userManagementForm.controls['salutation'].touched"
                                class="text-danger">
                                <span *ngIf="userManagementForm.controls['salutation'].errors?.['required']">*Title is required</span>
                            </div>
                        </div>

                        <div class="col-sm-3">
                            <psmat-input type="text" formControlName="firstName" label="First Name" controlName="firstName" [parentForm]="userManagementForm" maxlength="30" [required]="true" ></psmat-input>
                            <div *ngIf="userManagementForm.controls['firstName'].dirty || userManagementForm.controls['firstName'].touched"
                                class="text-danger">
                                <span *ngIf="userManagementForm.controls['firstName'].errors?.['required']">*First name
                                    is
                                    required</span>
                                <span *ngIf="userManagementForm.controls['firstName'].errors?.['minlength']">*First name
                                    must be at least 4 characters long.</span>
                            </div>
                        </div>

                        <div class="col-sm-3">
                            <psmat-input type="text" label="Middle Name (Optional)" formControlName="middleName" maxlength="30"></psmat-input>
                        </div>

                        <div class="col-sm-3">
                            <psmat-input type="text" formControlName="lastName" label="Last Name" controlName="lastName" [parentForm]="userManagementForm" maxlength="30" [required]="true" ></psmat-input>
                            <div *ngIf="userManagementForm.controls['lastName'].invalid && (userManagementForm.controls['lastName'].dirty || userManagementForm.controls['lastName'].touched)"
                                class="text-danger">
                                <span *ngIf="userManagementForm.controls['lastName'].errors?.['required']">*Last name is required</span>
                                <span *ngIf="userManagementForm.controls['lastName'].errors?.['minlength']">*Min 3 characters required.</span>
                            </div>
                        </div>

                        <div class="col-sm-3">
                            <div class="ps-mat-input">
                                <select name="title" formControlName="gender">
                                    <option value="">- Select -</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                </select>
                                <label for="gender"><span class="text-danger">*</span> Gender</label>
                            </div>
                            <div *ngIf="userManagementForm.controls['gender'].dirty || userManagementForm.controls['gender'].touched"
                                class="text-danger">
                                <span *ngIf="userManagementForm.controls['gender'].errors?.['required']">*Select gender</span>
                            </div>
                        </div>
                        
                        <div class="col-sm-3">
                            <psmat-input type="text" formControlName="userName" label="Username" controlName="userName" [readonly]="enableReadOnly" [parentForm]="userManagementForm" maxlength="30" [required]="true" ></psmat-input>
                            <div *ngIf="userManagementForm.controls['userName'].invalid && (userManagementForm.controls['userName'].dirty || userManagementForm.controls['userName'].touched)"
                                class="text-danger">
                                <span *ngIf="userManagementForm.controls['userName'].errors?.['required']">*Email is required</span>
                                <span *ngIf="userManagementForm.controls['userName'].errors?.['pattern']">*Email is invalid.</span>
                            </div>
                        </div>

                        <div class="col-sm-3">
                            <psmat-input OnlyNumber="true" formControlName="contactNo" label="Contact No" controlName="contactNo" [parentForm]="userManagementForm" maxlength="15" [required]="true" ></psmat-input>
                            <div *ngIf="userManagementForm.controls['contactNo'].invalid && (userManagementForm.controls['contactNo'].dirty || userManagementForm.controls['contactNo'].touched)"
                                class="text-danger">
                                <span *ngIf="userManagementForm.controls['contactNo'].errors?.['required']">*Contact No
                                    is
                                    required</span>
                                <span
                                    *ngIf="userManagementForm.controls['contactNo'].errors?.['minlength'] && userManagementForm.controls['contactNo'].errors?.['maxlength']">*Contact
                                    No must be at least 10 characters long.</span>
                            </div>
                        </div>
                        
                        <div class="col-sm-3" *ngIf="userId == ''">
                            <psmat-input type="password" formControlName="password" label="Password" controlName="password" [parentForm]="userManagementForm" maxlength="40" [required]="true" ></psmat-input>
                            <div *ngIf="userManagementForm.controls['password'].dirty || userManagementForm.controls['password'].touched"
                                class="text-danger">
                                <span *ngIf="userManagementForm.controls['password'].errors?.['required']">*Password is
                                    required<br /></span>
                                <div *ngIf="!userManagementForm.controls['password'].errors?.['required'] && userManagementForm.controls['password'].invalid">
                                    <span
                                        class="{{ containsPTRN('password', numPtrn) ? 'text-success' : 'text-danger' }}">*Password
                                        must contain at least one number.<br /></span>
                                    <span
                                        class="{{ containsPTRN('password', ucPtrn) && containsPTRN('password', lcPtrn) ? 'text-success' : 'text-danger' }}">*one
                                        uppercase and a lowercase letter<br /></span>
                                    <span class="{{ contains8Char() ? 'text-success' : 'text-danger' }}">*At least 8
                                        characters<br /></span>
                                    <span
                                        class="{{ containsPTRN('password', spcPtrn) ? 'text-danger' : 'text-success' }}">*Password
                                        cannot contain whitespace<br /></span>
                                    <span
                                        class="{{ containsPTRN('password', spclPtrn) ? 'text-success' : 'text-danger' }}">*At
                                        least 1 special character<br /></span>
                                </div>
                            </div>

                        </div>

                        <div class="col-sm-3" *ngIf="userId == ''">
                            <psmat-input type="password" formControlName="confirmPassword" label="Confirm Password" controlName="confirmPassword" [parentForm]="userManagementForm" maxlength="40" [required]="true" ></psmat-input>
                            <div *ngIf="userManagementForm.controls['confirmPassword'].dirty || userManagementForm.controls['confirmPassword'].touched"
                                class="text-danger">
                                <span *ngIf="userManagementForm.controls['confirmPassword'].errors?.['required']">*Confirm password
                                    is
                                    required<br/></span>
                                    <span
                                        *ngIf="!userManagementForm.controls['confirmPassword'].errors?.['required'] && userManagementForm.errors?.['passwordMatched']">*Confirm
                                        password do not match</span>
                            </div>
                        </div>

                        <div class="col-sm-3">
                            <psmat-input OnlyNumber="true" label="Password Change Days" formControlName="pwdChangeDays" controlName="pwdChangeDays" [parentForm]="userManagementForm" maxlength="4" [required]="true" ></psmat-input>
                            <div *ngIf="userManagementForm.controls['pwdChangeDays'].dirty || userManagementForm.controls['pwdChangeDays'].touched"
                                class="text-danger">
                                <span *ngIf="userManagementForm.controls['pwdChangeDays'].errors?.['required']">*Password change days is required</span>
                            </div>
                        </div>

                        <div class="col-sm-3">
                            <psmat-input OnlyNumber="true" label="Password Change Warning Days" formControlName="pwdChangeWarningDays" controlName="pwdChangeWarningDays" [parentForm]="userManagementForm" maxlength="4" [required]="true" ></psmat-input>
                            <div *ngIf="userManagementForm.controls['pwdChangeWarningDays'].dirty || userManagementForm.controls['pwdChangeWarningDays'].touched"
                                class="text-danger">
                                <span *ngIf="userManagementForm.controls['pwdChangeWarningDays'].errors?.['required']">*This field is required</span>
                            </div>
                        </div>
                        
                        <div class="col-sm-3">
                            <psmat-input OnlyNumber="true" label="Session Out Period" formControlName="sessionTimeOutPeriod" controlName="sessionTimeOutPeriod" [parentForm]="userManagementForm" maxlength="4" [required]="true" ></psmat-input>
                            <div *ngIf="userManagementForm.controls['sessionTimeOutPeriod'].dirty || userManagementForm.controls['sessionTimeOutPeriod'].touched"
                                class="text-danger">
                                <span *ngIf="userManagementForm.controls['sessionTimeOutPeriod'].errors?.['required']">*This field is required</span>
                            </div>
                        </div>

                        <div class="col-sm-3">
                            <psmat-input OnlyNumber="true" label="Max Login Attemps" formControlName="maxFraudLogIn" controlName="maxFraudLogIn" [parentForm]="userManagementForm" maxlength="2" [required]="true" ></psmat-input>
                            <div *ngIf="userManagementForm.controls['maxFraudLogIn'].dirty || userManagementForm.controls['maxFraudLogIn'].touched"
                                class="text-danger">
                                <span *ngIf="userManagementForm.controls['maxFraudLogIn'].errors?.['required']">*This field is required</span>
                            </div>
                        </div>

                        <div class="col-sm-3">
                            <div class="ps-mat-input">
                                <select name="title" formControlName="isActive">
                                    <option value="">- Select -</option>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                </select>
                                <label for="isActive"><span class="text-danger">*</span> Is Active</label>
                            </div>
                            <div *ngIf="userManagementForm.controls['isActive'].dirty || userManagementForm.controls['isActive'].touched"
                                class="text-danger">
                                <span *ngIf="userManagementForm.controls['isActive'].errors?.['required']">*Select active status</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /.card-body -->
                <div class="card-footer">
                    <button type="submit" class="btn btn-success"><i class="fa fa-save"></i> Save</button>
                    <button type="button" class="btn btn-danger ml-3" (click)="onBackPressed()"><i class="fa fa-times"></i> Cancel</button>
                </div>
                <!-- /.card-footer-->
            </form>
        </div>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->