export class LedgerFilter{
    tenant_id: string | null;
    rent_info_id: string | null;
    start_date: string | null;
    end_date: string | null;

    constructor(tenantId: string, rent_info_id: string, start_date: string, end_date: string){
        this.tenant_id = tenantId;
        this.rent_info_id = rent_info_id;
        this.start_date = start_date;
        this.end_date = end_date;
    }
}