export class PropertyUnits{
    id: number;
    property_id: number;
    tenant_id: number;
    tenant_name: string;
    rent_collector_id: number;
    rent_collector_name: string;
    name: string;
    bedroom: number;
    bathroom: number;
    kitchen: number;
    availability: number;
    remarks: string;
    status: number;


    constructor(property_id: number, name?: string, bedroom?: number, bathroom?: number, kitchen?: number) {
        this.property_id = property_id;
        if(name != null){ this.name = name; }
        if(bedroom != null){ this.bedroom = bedroom; }
        if(bathroom != null){ this.bathroom = bathroom; }
        if(kitchen != null){ this.kitchen = kitchen; }
      }
}