import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// import { ApiService } from '@services/api.service';
import { ApiService } from '../../../services/api.service';
import { BaseResponse } from '@models/response';
import { ChannelManager } from '@models/channel-manager';
import { Utils } from '@utils/utils';
import { FilterPagination } from '@models/filter-pagination';
import { Location } from '@angular/common';
import { RL } from '../../../utils/static/route-link';
import { SMsg } from '../../../utils/static/s-msg';
import { PropertyInformation } from '../../../models/property-information';
import { AppService } from '../../../services/app.service';
import { SKey, SStr } from '../../../utils/static/s-str';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { Property } from '../../../models/property/model-property';
import { PropertyLocation } from '../../../models/location/model-location';
import { PropertyUnits } from '../../../models/units/model-units';
import { ReqAddUnits } from '../../../models/units/request-add-units';
import { PropertyRentAndCharges } from '../../../models/rentAndCharges/model-rent-and-charges';

@Component({
  selector: 'app-property-create',
  templateUrl: './property-create.component.html',
  styleUrls: ['./property-create.component.scss']
})
export class PropertyInformationCreateComponent implements OnInit {
  channelManagerList: Array<ChannelManager> = [];
  public propertyInformationForm: UntypedFormGroup;
  public propertyLocationForm: UntypedFormGroup;
  pagination: FilterPagination = new FilterPagination(1, 1000, 'name', 'DESC', '', 'Y');
  public isLoading = false;
  submitted = false;
  hotelIds: number;
  @ViewChild('stepperContainer') stepperContainer: ElementRef;
  currentStep = 0;
  maxStep = 4;
  selPropertyId: any;
  selPropertyLocationId: number | null;
  sesSelPropertyId: string | null;
  iframeUrl: string = "";
  sanitizedIframeHtml: SafeHtml | null = null;
  sanitizedIframeUrl: SafeResourceUrl | null = null;
  unitsArray: Array<PropertyUnits> = [];
  rncArray: Array<PropertyRentAndCharges> = [];
  validUnitsArray: Array<PropertyUnits> = [];
  noOfUnits: number = 0;
  propertyUnitForm: UntypedFormGroup[] = [];
  rentAndChargesForm: UntypedFormGroup[] = [];
  unitsExistsInDB: boolean = false;

  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private appService: AppService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder
  ) {
    this.selPropertyId = this.route.snapshot.paramMap.get('id');
    this.sanitizedIframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeUrl);
  }

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'property-page'
    );

    this.propertyInformationForm = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      owner_id: new UntypedFormControl(null),
      property_name: new UntypedFormControl(null, Validators.required),
      units: new UntypedFormControl(null, Validators.required),
      bedrooms: new UntypedFormControl(null, Validators.required),
      bathrooms: new UntypedFormControl(null, Validators.required),
      kitchens: new UntypedFormControl(null, Validators.required),
      square_footage: new UntypedFormControl(null, Validators.required),
      description: new UntypedFormControl(null, Validators.required)
    });

    this.propertyLocationForm = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      property_id: new UntypedFormControl(null),
      country: new UntypedFormControl(null, Validators.required),
      state: new UntypedFormControl(null, Validators.required),
      city: new UntypedFormControl(null, Validators.required),
      zip_code: new UntypedFormControl(null, Validators.required),
      address: new UntypedFormControl(null, Validators.required),
      map_link: new UntypedFormControl(null, Validators.required)
    });

    this.getData();
  }

  initializeUnitForms() {
    console.log(this.unitsArray);
    for (let i = 0; i < this.unitsArray.length; i++) {
      const unitFormGroup = this.formBuilder.group({
        id: [this.unitsArray[i].id],
        property_id: [this.selPropertyId],
        tenant_id: [this.unitsArray[i].tenant_id],
        name: [this.unitsArray[i].name, Validators.required],
        bedroom: [this.unitsArray[i].bedroom, Validators.required],
        bathroom: [this.unitsArray[i].bathroom, Validators.required],
        kitchen: [this.unitsArray[i].kitchen, Validators.required],
      });
      this.propertyUnitForm.push(unitFormGroup);
    }
  }

  initializeRentAndChargesForms(){
    for (let i = 0; i < this.rncArray.length; i++) {
      const rncFormGroup = this.formBuilder.group({
        id: [],
        unit_id: [this.rncArray[i].id],
        general_rent: [this.rncArray[i].general_rent],
        security_deposit: [this.rncArray[i].security_deposit, Validators.required],
        late_fee: [this.rncArray[i].late_fee, Validators.required],
        incident_receipt: [this.rncArray[i].incident_receipt, Validators.required],
        rent_type: [this.rncArray[i].rent_type, Validators.required],
        start_date: [this.rncArray[i].start_date, Validators.required],
        end_date: [this.rncArray[i].end_date, Validators.required],
      });
      this.rentAndChargesForm.push(rncFormGroup);
    }
  }

  showMap() {
    let mapLink = this.propertyLocationForm.controls.map_link.value;
    if (mapLink) {
      if (mapLink.startsWith('<iframe')) {
        mapLink = mapLink.replace(/width="[^"]*"/, 'width="100%"');
        mapLink = mapLink.replace(/height="[^"]*"/, 'height="200px"');
        this.sanitizedIframeHtml = this.sanitizer.bypassSecurityTrustHtml(mapLink);
        this.sanitizedIframeUrl = null;
      } else {
        this.sanitizedIframeHtml = null;
        this.sanitizedIframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(mapLink);
      }
    } else {
      this.sanitizedIframeHtml = null;
      this.sanitizedIframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
    }
  }

  showStep(stepNumber: number) {
    const steps = this.stepperContainer.nativeElement.querySelectorAll('.step');
    steps[this.currentStep].classList.remove('active');
    this.currentStep = stepNumber;
    steps[this.currentStep].classList.add('active');
  }

  nextStep(isAllValid: boolean) {
    if (isAllValid) {
      if (this.currentStep < this.maxStep) {
        this.currentStep++;
        this.getData();
      }
    }
  }

  prevStep() {
    if (this.currentStep > 0) {
      this.currentStep--;
      this.getData();
    }
  }

  getData() {
    if (this.selPropertyId != null || this.selPropertyId != '') {
      if (this.currentStep == 0) {
        this.getThisPropertyInfo();
      } else if (this.currentStep == 1) {
        this.getPropertyLocationInformation();
      } else if (this.currentStep == 2) {
        this.getPropertyUnitsInformation();
      }
    }
  }


  updateUnitsArrayData(index: number, field: string, value: any) {
    console.log("Index: " + index + ", Field: " + field + ", Value: " + value);
    if (index >= 0 && index < this.unitsArray.length) {
      const unit = this.unitsArray[index];
      if (field === 'name') {
        unit.name = value;
      } else if (field === 'bedroom') {
        unit.bedroom = value;
      } else if (field === 'bathroom') {
        unit.bathroom = value;
      } else if (field === 'kitchen') {
        unit.kitchen = value;
      }
    }
    console.log(this.unitsArray);
  }


  saveNewOrUpdatedData() {
    if (this.currentStep == 0) {
      this.savePropertyInformation();
    } else if (this.currentStep == 1) {
      this.savePropertyAddressInformation();
    } else if (this.currentStep == 2) {
      this.savePropertyUnitsInformation();
    }
  }

  async savePropertyInformation() {
    this.submitted = true;
    this.propertyInformationForm.markAllAsTouched();
    if (this.propertyInformationForm.valid) {
      this.propertyInformationForm.patchValue({
        owner_id: this.appService.userId
      });

      this.isLoading = true;
      if (this.selPropertyId != null) {
        await this.apiService.propertyInformationUpdate(this.propertyInformationForm.value).subscribe((result: any) => {
          this.isLoading = false;
          if (result.code == 0) {
            this.nextStep(true);
            this.toastr.success(result.message)
          } else {
            this.toastr.error(result.message)
          }
        });
      } else {
        await this.apiService.propertyInformationCreate(this.propertyInformationForm.value).subscribe((result: any) => {
          this.isLoading = false;
          if (result.code == 0) {
            if (result.data != null) {
              this.selPropertyId = result.data.property_id;
              // localStorage.setItem(SKey.PROP_ID, result.data.property_id);


              // if (this.selPropertyId === null || this.selPropertyId === '') {
              //   if (this.sesSelPropertyId != null || this.sesSelPropertyId != '') {
              //     this.sesSelPropertyId = localStorage.getItem(SKey.PROP_ID);
              //   }
              // } else {
              //   localStorage.removeItem(SKey.PROP_ID);
              // }

              this.nextStep(true);
              this.toastr.success(result.message)
            }
          } else {
            this.toastr.error(result.message)
          }
        });
      }
    } else {
      this.toastr.error('Please fill all the required fields.');
    }
  }

  async savePropertyAddressInformation() {
    this.submitted = true;
    this.propertyLocationForm.markAllAsTouched();
    if (this.propertyLocationForm.valid) {
      this.propertyLocationForm.patchValue({
        property_id: this.selPropertyId
      });

      this.isLoading = true;
      if (this.selPropertyId != null && this.selPropertyLocationId != null) {
        await this.apiService.propertyLocationInformationUpdate(this.propertyLocationForm.value).subscribe((result: any) => {
          this.isLoading = false;
          if (result.code == 0) {
            this.nextStep(true);
            this.toastr.success(result.message)
          } else {
            this.toastr.error(result.message)
          }
        });
      } else {
        await this.apiService.propertyLocationInformationCreate(this.propertyLocationForm.value).subscribe((result: any) => {
          this.isLoading = false;
          if (result.code == 0) {
            this.nextStep(true);
            this.toastr.success(result.message)
          } else {
            this.toastr.error(result.message)
          }
        });
      }
    } else {
      this.toastr.error('Please fill all the required fields.');
    }
  }

  async savePropertyUnitsInformation() {
    let isFormValid = false;
    this.submitted = true;
    this.propertyUnitForm.forEach(unitForm => {
      Object.values(unitForm.controls).forEach(control => {
        control.markAsTouched();
      });
    });

    this.validUnitsArray = [];
    this.propertyUnitForm.forEach(unitForm => {
      if (unitForm.valid) {
        isFormValid = true;
        if (this.selPropertyId != null && this.selPropertyLocationId != null) {
          const idValue = (unitForm.get('id') as FormControl).value;
          const nameValue = (unitForm.get('name') as FormControl).value;
          const bedroomValue = (unitForm.get('bedroom') as FormControl).value;
          const bathroomValue = (unitForm.get('bathroom') as FormControl).value;
          const kitchenValue = (unitForm.get('kitchen') as FormControl).value;

          const validUnit: PropertyUnits = new PropertyUnits(this.selPropertyId);
          validUnit.id = idValue;
          validUnit.name = nameValue;
          validUnit.bedroom = bedroomValue;
          validUnit.bathroom = bathroomValue;
          validUnit.kitchen = kitchenValue;
          this.validUnitsArray.push(validUnit);
        }
      } else {
        isFormValid = false;
      }
    });

    if (isFormValid) {
      const reqAddUnits = new ReqAddUnits(this.validUnitsArray);
      if (this.unitsExistsInDB) {
        this.isLoading = true;
        await this.apiService.propertyUnitsInformationUpdate(reqAddUnits).subscribe((result: any) => {
          this.isLoading = false;
          if (result.code == 0) {
            this.nextStep(true);
            this.toastr.success(result.message)
          } else {
            this.toastr.error(result.message)
          }
        });
      } else {
        await this.apiService.propertyUnitsInformationCreate(reqAddUnits).subscribe((result: any) => {
          this.isLoading = false;
          if (result.code == 0) {
            this.nextStep(true);
            this.toastr.success(result.message)
          } else {
            this.toastr.error(result.message)
          }
        });
      }

    } else {
      this.toastr.error('Please fill all the required fields.');
    }
  }

  async getThisPropertyInfo() {
    if (this.selPropertyId != null) {
      this.isLoading = true;
      this.apiService
        .propertyInformationSingle(this.selPropertyId)
        .subscribe((response: BaseResponse) => {
          const propertyInformation: Property = response.data;
          this.noOfUnits = propertyInformation.units;
          this.propertyInformationForm.patchValue({
            id: propertyInformation.id,
            owner_id: propertyInformation.owner_id,
            property_name: propertyInformation.property_name,
            units: propertyInformation.units,
            bedrooms: propertyInformation.bedrooms,
            bathrooms: propertyInformation.bathrooms,
            kitchens: propertyInformation.kitchens,
            square_footage: propertyInformation.square_footage,
            description: propertyInformation.description
          });
        });
    }
  }

  async getPropertyLocationInformation() {
    if (this.selPropertyId != null) {
      this.isLoading = true;
      this.apiService
        .propertyLocationInformationSingle(this.selPropertyId)
        .subscribe((response: BaseResponse) => {
          const locationInfo: PropertyLocation = response.data;
          if (locationInfo != null) {
            this.selPropertyLocationId = locationInfo.id;
            this.propertyLocationForm.patchValue({
              id: locationInfo.id,
              property_id: locationInfo.property_id,
              country: locationInfo.country,
              state: locationInfo.state,
              city: locationInfo.city,
              zip_code: locationInfo.zip_code,
              address: locationInfo.address,
              map_link: locationInfo.map_link
            });
            this.showMap();
          }
        });
    }
  }

  async getPropertyUnitsInformation() {
    this.unitsArray = [];
    this.propertyUnitForm = [];
    if (this.selPropertyId != null) {
      this.isLoading = true;
      this.apiService
        .propertyUnitInformation(this.selPropertyId)
        .subscribe((response: BaseResponse) => {
          const unitsInfo: PropertyUnits[] = response.data;
          if (unitsInfo.length > 0) {
            unitsInfo.forEach((unitInfo: PropertyUnits, index: number) => {
              this.unitsArray.push(unitInfo);
            });

            this.isLoading = false;
            this.unitsExistsInDB = true;
            this.initializeUnitForms();
          } else {
            this.unitsExistsInDB = false;
            if (this.selPropertyId != null) {
              for (let i = 0; i < this.noOfUnits; i++) {
                this.unitsArray.push(new PropertyUnits(this.selPropertyId));
              }
              this.initializeUnitForms();
            }
          }
        });
    } else {
      this.unitsExistsInDB = false;
      if (this.selPropertyId != null) {
        for (let i = 0; i < this.noOfUnits; i++) {
          this.unitsArray.push(new PropertyUnits(this.selPropertyId));
        }
        this.initializeUnitForms();
      }
    }
  }

  getChannelManagerName(index: number) {
    const channelManager: ChannelManager = this.channelManagerList[index];
    return channelManager.name;
  }


  ConfirmedValidator(controlName: string, matchingControlName: string): ValidationErrors | null {

    return (formGroup: UntypedFormGroup) => {
      debugger;
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value != '' && matchingControl.value == '') {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  onBackPressed() {
    this.location.back();
  }
}