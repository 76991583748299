import { Location } from '@angular/common';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';
import { EmailTemplate } from '@models/email-template';
import { BaseResponse } from '@models/response';
import { ApiService } from '@services/api.service';

@Component({
  selector: 'app-email-template-create',
  templateUrl: './email-template-create.component.html',
  styleUrls: ['./email-template-create.component.scss']
})
export class EmailTemplateCreateComponent implements OnInit {
  emailTemplateForm: UntypedFormGroup;
  public isLoading = false;
  enableReadOnly = false;
  emailTemplateId: string = '';
  submitted = false;
  hotelIds: number;
  public Editor = ClassicEditor;
  public htmlContent = `<div>
    <p style="font-size:1.1em">Hi,</p>
    <p>Thank you for choosing Sarvanam Software Pvt. Ltd.<br/>
        Your OTP verification code:
    </p>
        <h2>324457</h2>
        <p>OTP is valid for 5 minutes</p>
        <a href="sarvanam.com">Sarvanam Software Pvt. Ltd.</a><br/>
        <span>Charkhal Road, Kathmandu</span><br/>
        <span>Nepal</span>
    </div>`;

    constructor(
      private renderer: Renderer2,
      private toastr: ToastrService,
      private apiService: ApiService,
      private router: Router,
      private route: ActivatedRoute,
      private location: Location
    ) {}

  ngOnInit() {
    this.emailTemplateForm = new UntypedFormGroup({
      emailTemplateId: new UntypedFormControl(this.emailTemplateId),
      templateName: new UntypedFormControl(null, Validators.required),
      templateCode: new UntypedFormControl(null, Validators.required),
      displayName: new UntypedFormControl(null, Validators.required),
      emailFrom: new UntypedFormControl(null, Validators.required),
      subject: new UntypedFormControl(null, Validators.required),
      body: new UntypedFormControl(null, Validators.required),
      isActive: new UntypedFormControl('', Validators.required)
    });    
    
    let emailTemplateId: any = this.route.snapshot.paramMap.get('id');
    if (emailTemplateId != null) {
      this.emailTemplateId = emailTemplateId;
      this.isLoading = true;
      this.enableReadOnly = true;
      this.getThisEmailTemplate();
    }
  }

  async saveEmailTemplateForm() {
    this.submitted = true;
    this.emailTemplateForm.markAllAsTouched();
    if (this.emailTemplateForm.valid) {
      this.isLoading = true;
      if (this.emailTemplateId != '') {
        await this.apiService.emailTemplateUpdate(this.emailTemplateForm.value).subscribe((result: any) => {
          this.isLoading = false;
          if (result.responseCode == "200") {
            this.toastr.success(result.responseMessage)
            this.router.navigate(['/email-template-list']);
          } else {
            this.toastr.error(result.responseMessage)
          }
        });
      } else {
        await this.apiService.emailTemplateCreate(this.emailTemplateForm.value).subscribe((result: any) => {
          this.isLoading = false;
          if (result.responseCode == "200") {
            this.toastr.success(result.responseMessage)
            this.router.navigate(['/email-template-list']);
          } else {
            this.toastr.error(result.responseMessage)
          }
        });
      }
    } else {
      this.toastr.error('Please fill all the required fields.');
    }
  }

  async getThisEmailTemplate(){
    this.apiService
      .emailTemplateSingle(this.emailTemplateId)
      .subscribe((response: BaseResponse) => {
        const emailTemplate: EmailTemplate = response.data;
        this.emailTemplateForm.patchValue({
          emailTemplateId: this.emailTemplateId,
          templateName: emailTemplate.templateName,
          templateCode: emailTemplate.templateCode,
          displayName: emailTemplate.displayName,
          emailFrom: emailTemplate.emailFrom,
          subject: emailTemplate.subject,
          body: emailTemplate.body,
          isActive: emailTemplate.isActive
        });
      });
      console.log(this.emailTemplateForm);
  }

  onBackPressed(){
    this.location.back();
  }
}
