export class ModelFilterUnit{
    tenantId: any;
    rentCollectorId: any;
    availability: any;
    status: any;

    constructor(tenantId: any, rentCollectorId: any, availability: any, status: any){
        if(tenantId != null){ this.tenantId = tenantId; }
        if(rentCollectorId != null){ this.rentCollectorId = rentCollectorId; }
        if(availability != null){ this.availability = availability; }
        if(status != null){ this.status = status; }
    }
}