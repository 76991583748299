<!-- Brand Logo -->
<a [routerLink]="['/']" class="brand-link">
    <pf-image
        src="assets/img/logo.png"
        alt="SarvanamRMS Logo"
        style="opacity: 0.8; width: 100%; height: inherit; margin-bottom: 10px;"
    ></pf-image>
</a>

<!-- Sidebar -->
<div class="sidebar">
    <div class="form-inline mt-3">
        <app-sidebar-search></app-sidebar-search>
    </div>

    <!-- Sidebar Menu -->
    <nav class="mt-2" style="overflow-y: hidden">
        <ul
            class="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
        >
            <app-menu-item
                *ngFor="let item of menu"
                [menuItem]="item"
            ></app-menu-item>
        </ul>
    </nav>
</div>
