<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6 d-flex">
                <h1>Collection Report</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item active"><i class="fa fa-list"></i> Collection Report</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <div class="row">

                                <div class="col-sm-2">
                                    <div class="ps-mat-input">
                                        <select id="rent_collector_id" name="rent_collector_id"
                                            [(ngModel)]="collectionRepFilter.rent_collector_id">
                                            <option value="0">All</option>
                                            <option *ngFor="let owner of ownerList" [value]="owner.id">{{ owner.name }}
                                            </option>
                                        </select>
                                        <label for="rent_collector_id">Unit Owner</label>
                                    </div>
                                </div>

                                <div class="col-sm-2">
                                    <div class="ps-mat-input">
                                        <select id="payment_method_id" name="payment_method_id"
                                            [(ngModel)]="collectionRepFilter.payment_method_id">
                                            <option value="0">All</option>
                                            <option *ngFor="let data of paymentMethods" [value]="data.id">{{ data.name
                                                }}
                                            </option>
                                        </select>
                                        <label for="payment_method_id">Payment Method</label>
                                    </div>
                                </div>

                                <div class="col-sm-2">
                                    <psmat-input type="date" [value]="currentDate"
                                        (change)="onFieldValueChange('start_date', $event)"
                                        label="Start Date"></psmat-input>
                                </div>

                                <div class="col-sm-2">
                                    <psmat-input type="date" [value]="currentDate"
                                        (change)="onFieldValueChange('end_date', $event)"
                                        label="End Date"></psmat-input>
                                </div>


                                <button class="btn btn-primary btn-sm ml-4" type="submit"
                                    (click)="loadCollectionReport()"><i class="fa fa-search"></i>
                                    Search</button>
                                <button class="btn btn-secondary btn-sm ml-2" type="submit" (click)="resetSearch()"><i
                                        class="fa fa-retweet"></i>
                                    Reset</button>
                            </div>
                        </div>
                        <div class="text-right">
                            <app-table-data-exporter tableId="dataTable"
                                fileName="ledger_report"></app-table-data-exporter>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body table-responsive p-0 tbl-h">
                <div *ngIf="collectionList != null && collectionList.length > 0">
                    <table id="dataTable" class="table table-bordered table-head-fixed text-nowrap">
                        <thead>
                            <tr>
                                <th class="text-center w-5p" scope="col">S.N.</th>
                                <th class="text-center w-1h5p" scope="col">Date</th>
                                <th scope="col">Receipt No</th>
                                <th scope="col">Unit Name</th>
                                <th scope="col">Received Amount</th>
                                <th scope="col">Received By</th>
                                <th scope="col">Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of collectionList; index as i">
                                <td>{{ startingSn + i }}</td>
                                <td class="text-center" scope="row">{{ data.received_date }}</td>
                                <td>{{ data.receipt_no }}</td>
                                <td>{{ data.unit_name }}</td>
                                <td class="text-right">{{ data.received_amount }}</td>
                                <td>{{ data.received_by }}</td>
                                <td>{{ data.remarks }}</td>
                            </tr>
                            <tr>
                                <td colspan="4" class="text-right"><strong>Total: </strong></td>
                                <td class="text-right">NRS.{{totalAmount}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div *ngIf="collectionList == null || collectionList.length == 0">
                    <h4 class="text-center p-4">No Data</h4>
                </div>
            </div>
        </div>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->