import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import { BaseResponse } from '@models/response';
import { ChannelManagerUserFilter, FilterPagination } from '@models/filter-pagination';
import { ModelChannelManagerForRoomTypeMap } from '@models/model-channel-manager-for-room-type-map.model';
import { ModelChannelManagerForRateTypeMap } from '@models/model-channel-manager-rate-type-map';
import { ModelChannelManagerForMealPlanMap } from '@models/model-channel-manager-meal-plan-map';
import { EP } from '../store/ep';
import { ModelChannelManagerMarketSegmentMap } from '../models/model-channel-manager-market-segment-map';
import { ModelChannelManagerPaymentModeMap } from '../models/model-channel-manager-payment-mode-map';
import { ReqResetCMUPassword } from '../models/request/ReqResetCMUPassword';
import { ReqLockUnlockUser } from '../models/request/ReqLockUnLockUser';
import { ReqAssignProperty } from '../models/request/ReqAssignProperty';
import { SKey } from '../utils/static/s-str';
import { ReqAddUnits } from '../models/units/request-add-units';
import { PaymentPostingFilter } from '../models/payment-posting-filter';
import { ReqModelBillMatch } from '../models/rent/req-model-bill-match';
import { ReqModelMatchedBills } from '../models/rent/req-model-matched-receipts';
import { ReqModelUnmatchReceipt } from '../models/rent/req-model-unmatch-receipt';
import { LedgerFilter } from '../models/ledger-filter';
import { CollectionReportFilter } from '../models/collection-report-filter';
import { ModelRentStatusFilter } from '../models/rent/model-rent-status-filter';
import { ModelRentInfoFilter } from '../models/rent/model-rent-info-filter';

@Injectable({
    providedIn: 'root'
})

export class ApiService {
    setting: any = null;

    constructor(private http: HttpClient) {
    }

    verifyOTP(context: any): Observable<BaseResponse> {
        var body = {
            Token: localStorage.getItem(SKey.TMP_TOKEN),
            OTPCode: context.OTPCode
        };
        return this.http.post(EP.VERIFY_OTP, body).pipe(
            map((body: any) => {
                return body.data
            })
        );
    }

    resendOTP(): Observable<BaseResponse> {
        var body = {
            Token: localStorage.getItem(SKey.TMP_TOKEN)
        };
        return this.http.post(EP.RE_SEND_OTP, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    login(context: any): Observable<BaseResponse> {
        return this.http.post(EP.LOGIN, context).pipe(
            map((body: any) => {
                return body.data
            })
        );
    }

    signUp(context: any): Observable<BaseResponse> {
        return this.http.post(EP.SIGNUP, context).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    requestForgotPassword(context: any): Observable<BaseResponse> {
        return this.http.post(EP.FORGOT_PW, context).pipe(
            map((body: any) => {
                return body.data
            })
        );
    }

    recoverPassword(context: any): Observable<BaseResponse> {
        return this.http.post(EP.RESET_PW, context).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    changePassword(context: any): Observable<BaseResponse> {
        return this.http.post(EP.CHANGE_PW, context).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    forceChangePassword(context: any): Observable<BaseResponse> {
        return this.http.post(EP.CMU_FORCE_CHANGE_PW, context).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    dashboardData(userId: string, userRoleId: number): Observable<BaseResponse> {
        const endPoint = userRoleId == 1 ? EP.ADMIN_DASHBOARD : userRoleId == 2 ? EP.OWNER_DASHBOARD : EP.TENANT_DASHBOARD;
        return this.http.get(endPoint + userId).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    profile(userId: string): Observable<BaseResponse> {
        return this.http.get(EP.USR_PROFILE + userId).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    updateProfile(body: any): Observable<BaseResponse> {
        return this.http.post(EP.USR_PROFILE_UPD, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    getUsers(): Observable<BaseResponse> {
        return this.http.get(EP.USERS).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    addUser(body: any): Observable<BaseResponse> {
        return this.http.post(EP.ADD_USER, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    updateUser(body: any): Observable<BaseResponse> {
        return this.http.post(EP.UPD_USER, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    getMonthlyPostingWithFilter(filter: PaymentPostingFilter){
        return this.http.post(EP.ALL_MONTHLY_POSTINGS, filter).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    getCollectionReportWithFilter(filter: CollectionReportFilter){
        return this.http.post(EP.COLLECTION_REPORT, filter).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    getLedgerWithFilter(filter: LedgerFilter){
        return this.http.post(EP.LEDGER, filter).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    getRentStatusWithFilter(filter: ModelRentStatusFilter){
        return this.http.post(EP.RENT_STATUS, filter).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    addBillMatching(body: ReqModelBillMatch): Observable<BaseResponse> {
        return this.http.post(EP.ADD_BILL_MATCHING, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    deleteBillMatching(body: any): Observable<BaseResponse> {
        return this.http.post(EP.DEL_BILL_MATCHING, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }


    

    rentInfoAdd(body: any): Observable<BaseResponse> {
        return this.http.post(EP.ADD_RENT_INFO, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    rentInfoUpdate(body: any): Observable<BaseResponse> {
        return this.http.post(EP.UPD_RENT_INFO, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }


    addUnitInfo(body: any): Observable<BaseResponse> {
        return this.http.post(EP.ADD_PROP_UNIT, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    updateUnitInfo(body: any): Observable<BaseResponse> {
        return this.http.post(EP.UPD_PROP_UNIT, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }
    

    getTenantRentPostings(tenant_id: number): Observable<BaseResponse> {
        return this.http.get(EP.TENANT_RENT_POSTING + tenant_id).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    rentPostingAdd(body: any): Observable<BaseResponse> {
        return this.http.post(EP.ADD_RENT_POSTING, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    rentPostingUpdate(body: any): Observable<BaseResponse> {
        return this.http.post(EP.UPD_RENT_POSTING, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    printRentPosting(id: number): Observable<BaseResponse> {
        return this.http.get(EP.PRINT_RENT_POSTING + id).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    rentPostingDelete(id: number): Observable<BaseResponse> {
        return this.http.post(EP.DEL_RENT_POSTING + id, null).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    

    unitList(): Observable<BaseResponse> {
        return this.http.get(EP.ALL_TENANT).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    

    tenantAdd(body: any): Observable<BaseResponse> {
        return this.http.post(EP.ADD_TENANT, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    tenantUpdate(body: any): Observable<BaseResponse> {
        return this.http.post(EP.UPD_TENANT, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    tenantDelete(id: number): Observable<BaseResponse> {
        return this.http.post(EP.DEL_TENANT + id, null).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    tenantList(): Observable<BaseResponse> {
        return this.http.get(EP.ALL_TENANT).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    ownerList(): Observable<BaseResponse> {
        return this.http.get(EP.ALL_OWNER_USERS).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    documentList(id: number): Observable<BaseResponse> {
        return this.http.get(EP.ALL_DOC_OF_TENANT + id).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    documentTypeList(): Observable<BaseResponse> {
        return this.http.get(EP.ALL_DOC_TYPE).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    addDocument(body: any): Observable<BaseResponse> {
        return this.http.post(EP.ADD_DOC, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    // getDocument(docName: string): Observable<Blob> {
    //     return this.http.get(EP.SHOW_IMG + docName, { responseType: 'blob' }).pipe(
    //         map((body: any) => {
    //             return body
    //         })
    //     );
    // }
    
    getDocument(docName: string): Observable<Blob> {
        return this.http.get(EP.SHOW_IMG + docName, { responseType: 'blob' }).pipe(
          catchError((error: any) => {
            console.error('Error:', error);
            return throwError('An error occurred while fetching the image.');
          })
        );
      }

    updateDocument(body: any): Observable<BaseResponse> {
        return this.http.post(EP.UPD_DOC, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    deleteDocument(id: any): Observable<BaseResponse> {
        return this.http.post(EP.DEL_DOC + id, null).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    receiptList(tenant_id: string): Observable<BaseResponse> {
        return this.http.get(EP.DEBTOR_REC_RENT_INFO + tenant_id).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    matchedReceiptList(body: ReqModelMatchedBills): Observable<BaseResponse> {
        return this.http.post(EP.GET_MATCHED_BILLS, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    unMatchReceipt(body: ReqModelUnmatchReceipt): Observable<BaseResponse> {
        return this.http.post(EP.BILL_UN_MATCH, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    propertyList(body: any): Observable<BaseResponse> {
        return this.http.post(EP.ALL_PROPERTY, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    allRentInformationList(): Observable<BaseResponse> {
        return this.http.get(EP.ALL_RENT_INFO).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    rentInformationList(debtor_id: number): Observable<BaseResponse> {
        return this.http.get(EP.DEBTOR_RENT_INFO + debtor_id).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    getAllRentInformationList(body: ModelRentInfoFilter): Observable<BaseResponse> {
        return this.http.post(EP.RENT_INFOS, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    getPaymentMethods(): Observable<BaseResponse> {
        return this.http.get(EP.ALL_PAY_METHOD).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    getPaymentMethod(methodId: number): Observable<BaseResponse> {
        return this.http.get(EP.GET_PAY_METHOD + methodId).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    addPaymentMethod(body: any): Observable<BaseResponse> {
        return this.http.post(EP.ADD_PAY_METHOD, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    updatePaymentMethod(body: any): Observable<BaseResponse> {
        return this.http.post(EP.UPD_PAY_METHOD, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    deletePaymentMethod(methodId: number): Observable<BaseResponse> {
        return this.http.post(EP.DEL_PAY_METHOD + methodId, null).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    receivedRentInformationList(debtor_id: number): Observable<BaseResponse> {
        return this.http.get(EP.DEBTOR_REC_RENT_INFO + debtor_id).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    receivedRentInformationForRC(debtor_id: number, rent_code: string): Observable<BaseResponse> {
        return this.http.get(EP.DEBTOR_REC_RENT_INFO + debtor_id + "/" + rent_code).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    receivedRentAdd(body: any): Observable<BaseResponse> {
        return this.http.post(EP.ADD_REC_RENT_INFO, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    receivedRentUpdate(body: any): Observable<BaseResponse> {
        return this.http.post(EP.UPD_REC_RENT_INFO, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    printReceivedRent(id: number): Observable<BaseResponse> {
        return this.http.get(EP.PRINT_RENT_RECEIVED + id).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    receivedRentDelete(id: number): Observable<BaseResponse> {
        return this.http.post(EP.DEL_REC_RENT_INFO + id, null).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    balanceAmountForRC(tenantId: number, rentCode: string){
        return this.http.get(EP.BALANCE_AMT_FOR_RC + tenantId + "/" + rentCode).pipe(
            map((body: any) => {
                return body
            })
        );
    }




    bookingList(pagination: FilterPagination): Observable<BaseResponse> {
        return this.http.post(EP.ARB_LIST, pagination).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    getServiceSalt(): Observable<BaseResponse> {
        return this.http.get(EP.CMPI_GEN_SALT).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    getServiceToken(body: any): Observable<BaseResponse> {
        return this.http.post(EP.CMPI_GEN_TKN, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }
    


    /* Property Information V */

    propertyInformationList(pagination: FilterPagination): Observable<BaseResponse> {
        return this.http.post(EP.CMPI_LIST, pagination).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    propertyInformationSingle(id: number): Observable<BaseResponse> {
        return this.http.get(EP.GET_PROPERTY + id).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    locationInformationSingle(id: string): Observable<BaseResponse> {
        return this.http.get(EP.GET_ADRS + id).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    propertyLocationInformationSingle(id: number): Observable<BaseResponse> {
        return this.http.get(EP.GET_PROP_ADRS + id).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    propertyInformationCreate(body: any): Observable<BaseResponse> {
        return this.http.post(EP.ADD_PROPERTY, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    propertyInformationUpdate(body: any): Observable<BaseResponse> {
        return this.http.post(EP.UPD_PROPERTY, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }


    propertyLocationInformationCreate(body: any): Observable<BaseResponse> {
        return this.http.post(EP.ADD_PROP_ADRS, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    propertyLocationInformationUpdate(body: any): Observable<BaseResponse> {
        return this.http.post(EP.UPD_PROP_ADRS, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }


    getAllUnits(): Observable<BaseResponse> {
        return this.http.get(EP.GET_ALL_UNITS).pipe(
            map((body: any) => {
                return body
            })
        );
    }


    getAllUnitsWithFilter(body: any): Observable<BaseResponse> {
        return this.http.post(EP.GET_ALL_UNITS, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }


    propertyUnitInformation(id: string): Observable<BaseResponse> {
        return this.http.get(EP.GET_PROP_UNITS + id).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    propertyUnitsInformationCreate(params: ReqAddUnits): Observable<BaseResponse> {
        return this.http.post(EP.ADD_PROP_UNITS, params).pipe(
            map((body: any) => {
                console.log(body);
                return body
            })
        );
    }

    propertyUnitsInformationUpdate(params: ReqAddUnits): Observable<BaseResponse> {
        return this.http.post(EP.UPD_PROP_UNITS, params).pipe(
            map((body: any) => {
                return body
            })
        );
    }







    propertyInfoByCMId(id: string): Observable<BaseResponse> {
        return this.http.get(EP.CMPI_GET_THIS + id).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    roomTypeMappingSingle(id: string): Observable<BaseResponse> {
        return this.http.get(EP.CMM_GET + id).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    updateRoomTypeMapping(updatedData: ModelChannelManagerForRoomTypeMap): Observable<BaseResponse> {
        return this.http.post(EP.CMM_ADD, updatedData).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    rateTypeMappingSingle(id: string): Observable<BaseResponse> {
        return this.http.get(EP.RTM_GET + id).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    insertUpdateRateTypeMapping(updatedData: ModelChannelManagerForRateTypeMap): Observable<BaseResponse> {
        return this.http.post(EP.RTM_ADD, updatedData).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    mealPlanMappingSingle(id: string): Observable<BaseResponse> {
        return this.http.get(EP.MPM_GET + id).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    insertUpdateMealPlanMapping(updatedData: ModelChannelManagerForMealPlanMap): Observable<BaseResponse> {
        return this.http.post(EP.MPM_ADD, updatedData).pipe(
            map((body: any) => {
                return body
            })
        );
    }



    /* Market Segment Mapping */
    marketSegmentMappingSingle(id: string): Observable<BaseResponse> {
        return this.http.get(EP.MSM_GET + id).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    insertUpdateMarketSegmentMapping(updatedData: ModelChannelManagerMarketSegmentMap): Observable<BaseResponse> {
        return this.http.post(EP.MSM_ADD, updatedData).pipe(
            map((body: any) => {
                return body
            })
        );
    }
    /* Market Segment Mapping */



    /* Payment Mode Mapping */
    paymentModeMappingSingle(id: string): Observable<BaseResponse> {
        return this.http.get(EP.PMM_GET + id).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    insertUpdatePaymentModeMapping(updatedData: ModelChannelManagerPaymentModeMap): Observable<BaseResponse> {
        return this.http.post(EP.PMM_ADD, updatedData).pipe(
            map((body: any) => {
                return body
            })
        );
    }
    /* Payment Mode Mapping */


    /* Property Information ^ */



    /* Channel Manager User */

    channelManagerUserList(pagination: ChannelManagerUserFilter): Observable<BaseResponse> {
        return this.http.post(EP.CMU_LIST, pagination).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    channelManagerUserPropertyAdd(param: ReqAssignProperty): Observable<BaseResponse> {
        return this.http.post(EP.CMU_PROP_ADD, param).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    channelManagerUserPropertyList(id: string): Observable<BaseResponse> {
        return this.http.get(EP.CMU_PROP_LI_GET + id).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    resetChannelManagerUserPassword(param: ReqResetCMUPassword): Observable<BaseResponse> {
        return this.http.post(EP.CMU_RESET_PW, param).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    lockUnlockUser(param: ReqLockUnlockUser): Observable<BaseResponse> {
        return this.http.post(EP.CMU_LOCK_UNLOCK, param).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    /* Channel Manager User */



    /* Channel Manager V */

    channelManagerList(pagination: FilterPagination): Observable<BaseResponse> {
        return this.http.post(EP.CM_LIST, pagination).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    channelManagerSingle(id: string): Observable<BaseResponse> {
        return this.http.get(EP.CM_GET + id).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    channelManagerSave(body: any): Observable<BaseResponse> {
        return this.http.post(EP.CM_ADD, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    channelManagerUpdate(body: any): Observable<BaseResponse> {
        return this.http.post(EP.CM_UPD, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    /* Channel Manager ^ */



    /* Email Template V */

    emailTemplateList(pagination: FilterPagination): Observable<BaseResponse> {
        return this.http.post(EP.EMAIL_TMLT_LIST, pagination).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    emailTemplateSingle(id: string): Observable<BaseResponse> {
        return this.http.get(EP.EMAIL_TMLT_GET + id).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    emailTemplateCreate(body: any): Observable<BaseResponse> {
        return this.http.post(EP.EMAIL_TMLT_ADD, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    emailTemplateUpdate(body: any): Observable<BaseResponse> {
        return this.http.post(EP.EMAIL_TMLT_UPD, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    /* Email Template ^ */



    /* Country List */

    countrySave(body: any): Observable<BaseResponse> {
        return this.http.post(EP.COUNTRY_ADD, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    getCountry(id: string): Observable<BaseResponse> {
        return this.http.get(EP.COUNTRY_GET + id).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    countryUpdate(body: any): Observable<BaseResponse> {
        return this.http.post(EP.COUNTRY_UPDATE, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    countryList(body: any): Observable<BaseResponse> {
        return this.http.post(EP.COUNTRY_LIST, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    /* Country List */




    /* Role Setup */
    createRole(body: any): Observable<BaseResponse> {
        return this.http.post(EP.ROLE_ADD, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    getRole(id: string): Observable<BaseResponse> {
        return this.http.get(EP.ROLE_GET + id).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    updateRole(body: any): Observable<BaseResponse> {
        return this.http.post(EP.ROLE_UPDATE, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    roleList(): Observable<BaseResponse> {
        return this.http.get(EP.ROLE_LIST).pipe(
            map((body: any) => {
                return body
            })
        );
    }
    /* Role Setup */




    /* Channel manager user role */
    createCMUserRole(body: any): Observable<BaseResponse> {
        return this.http.post(EP.CM_USR_ROLE_ADD, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    getCMUserRole(id: string): Observable<BaseResponse> {
        return this.http.get(EP.CM_USR_ROLE_LIST_GET + id).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    updateCMUserRole(body: any): Observable<BaseResponse> {
        return this.http.post(EP.CM_USR_ROLE_UPDATE, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }

    roleCMUserList(body: any): Observable<BaseResponse> {
        return this.http.post(EP.CM_USR_ROLE_LIST, body).pipe(
            map((body: any) => {
                return body
            })
        );
    }
    /* Channel manager user role */
}
