<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6 d-flex">
                <h4>Assigned units to debtor {{selectedTenant.name}} <span class="text-gray-600">({{dataCount}})</span>
                </h4>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item active"><a routerLink="/tenants"><i class="fa fa-users"></i> Debtors</a>
                    </li>
                    <li class="breadcrumb-item active"><i class="fa fa-home"></i> Rent Informations</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card of-x-a p-3">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-primary" (click)="add(content)"><i class="fa fa-plus"></i>
                            Assign new unit</button>
                    </div>
                    <div class="col-sm-6">
                        <div class="flex float-sm-right">
                            <app-table-data-exporter tableId="dataTable"
                                fileName="assigned_units_to_{{selectedTenant.name}}"></app-table-data-exporter>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3" *ngIf="rentInfoList.length > 0">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body table-responsive p-0 tbl-h">
                            <table id="dataTable" class="table table-bordered table-head-fixed tbl-sm text-nowrap">
                                <thead>
                                    <tr>
                                        <th class="text-center w-5p" scope="col">S.N.</th>
                                        <th>Rent Code</th>
                                        <th>Unit Name</th>
                                        <th class="text-center">Start Date</th>
                                        <th class="text-center">End Date</th>
                                        <th class="text-center">Monthly Rent</th>
                                        <th class="text-center">Electricity</th>
                                        <th class="text-center">Water</th>
                                        <th class="text-center">Other</th>
                                        <th class="text-center">Increment Date</th>
                                        <th class="text-center">Status</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let rentInfo of rentInfoList; index as i">
                                        <th scope="row">{{ startingSn + i }})</th>
                                        <td>{{rentInfo.rent_code}}</td>
                                        <td>{{rentInfo.unit_name}}</td>
                                        <td class="text-center">{{rentInfo.start_date}}</td>
                                        <td class="text-center">{{rentInfo.end_date}}</td>
                                        <td class="text-center">{{rentInfo.monthly_rent}}</td>
                                        <td class="text-center">{{rentInfo.electricity}}</td>
                                        <td class="text-center">{{rentInfo.water}}</td>
                                        <td class="text-center">{{rentInfo.other}}</td>
                                        <td class="text-center">{{rentInfo.increment_date}}</td>
                                        <td class="text-center text-{{ rentInfo.status == 0 ? 'success' : 'danger' }}">
                                            <i class="fa fa-circle"></i> {{ rentInfo.status == 0 ? 'Active' :
                                            'InActive' }}
                                        </td>
                                        <td class="text-center">
                                            <button class="btn btn-outline text-primary pstitle-primary-right"
                                                pstitle="Edit rentInfo {{ rentInfo.name }}"
                                                (click)="edit(content, rentInfo)">
                                                <i class="fa fa-edit p-1"></i></button>

                                            <button class="btn btn-outline text-danger pstitle-danger-right"
                                                pstitle="Delete rentInfo {{ rentInfo.name }}"
                                                (click)="del(delete, rentInfo)">
                                                <i class="fa fa-trash p-1"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>
            </div>

            <div *ngIf="rentInfoList == null || rentInfoList.length == 0">
                <h4 class="text-center p-4">No Data</h4>
            </div>
        </div>
    </div>
    <!-- /.card -->
</section>



<ng-template #content let-modal>
    <div class="modal fade show" tabindex="-1" role="dialog" style="display: block; padding-right: 15px;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">{{dialogActFor}} unit for {{selectedTenant.name}}
                    </h4>
                    <i class="fa fa-times fa-2x" (click)="modal.dismiss('Cross click')"></i>
                </div>
                <form [formGroup]="rentInfoForm" appFocusFirstInvalidField>
                    <div class="modal-body">
                        <span>Fields marked with (<span class="text-danger">*</span>) is required.<br>
                            <!-- <span class="text-sm"><span class="text-primary">Note: </span>Even if you select multiple units, the amount in the fields below is individual.</span> -->
                        </span>
                        <div class="form-group row">
                            <input type="hidden" formControlName="id">
                            <div class="col-sm-12 mt-4">
                                <div class="ps-mat-input">
                                    <ng-multiselect-dropdown placeholder="Select Unit/s" [settings]="dropdownSettings"
                                        [(ngModel)]="selectedUnits" [data]="unitInfoList" formControlName="unit_ids">
                                    </ng-multiselect-dropdown>
                                    <label for="unit_ids"
                                        style="top: -11px !important; margin-top: 0 !important; font-size: 12px !important;"><span
                                            class="text-danger">*</span> Unit Name</label>
                                </div>
                            </div>
                            <div class="col-sm-6 mt-4">
                                <psmat-input type="date" formControlName="start_date" label="Start Date"
                                    controlName="start_date" [parentForm]="rentInfoForm" required="true"
                                    (onTextChange)="onDateChange('Start')"></psmat-input>
                            </div>
                            <div class="col-sm-6 mt-4">
                                <psmat-input type="date" formControlName="end_date" label="End Date"
                                    controlName="end_date" [parentForm]="rentInfoForm" required="true"
                                    (onTextChange)="onDateChange('End')"></psmat-input>
                            </div>
                            <div class="col-sm-6 mt-4">
                                <psmat-input onlyNumber="true" formControlName="monthly_rent" label="Monthly Rent"
                                    controlName="monthly_rent" [parentForm]="rentInfoForm"
                                    required="true"></psmat-input>
                            </div>
                            <div class="col-sm-6 mt-4">
                                <psmat-input onlyNumber="true" formControlName="electricity" label="Electricity"
                                    controlName="electricity" [parentForm]="rentInfoForm"></psmat-input>
                            </div>
                            <div class="col-sm-6 mt-4">
                                <psmat-input onlyNumber="true" formControlName="water" label="Water" controlName="water"
                                    [parentForm]="rentInfoForm"></psmat-input>
                            </div>
                            <div class="col-sm-6 mt-4">
                                <psmat-input onlyNumber="true" formControlName="other" label="Other" controlName="other"
                                    [parentForm]="rentInfoForm"></psmat-input>
                            </div>
                            <div class="col-sm-12 mt-4">
                                <psmat-textarea [rows]="4" formControlName="other_information" label="Other Information"
                                    controlName="other_information" [parentForm]="rentInfoForm"
                                    maxlength="255"></psmat-textarea>
                            </div>
                            <div class="col-sm-6 mt-4">
                                <psmat-input type="date" formControlName="increment_date" label="Increment Date"
                                    controlName="increment_date" [parentForm]="rentInfoForm" required="true"
                                    [min]="minStartDate" [max]="maxEndDate"></psmat-input>
                            </div>
                            <div class="col-sm-6 mt-4">
                                <div class="ps-mat-input">
                                    <select name="status" formControlName="status">
                                        <option value=''>- Select -</option>
                                        <option value="0">Yes</option>
                                        <option value="1">No</option>
                                    </select>
                                    <label for="status">Is Active</label>
                                </div>
                                <div *ngIf="rentInfoForm.controls['status'].dirty || rentInfoForm.controls['status'].touched"
                                    class="text-danger">
                                    <span *ngIf="rentInfoForm.controls['status'].errors?.['required']">*Status is
                                        required</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-success" (click)="saveRentInfo()"><i
                                class="fa fa-save"></i>
                            Save</button>
                        <button type="button" class="btn btn-danger" (click)="modal.close('Cross click')"><i
                                class="fa fa-times"></i> Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #delete let-modal>
    <div class="modal-header modal-xl bg-danger">
        <h4 class="modal-title" id="modal-basic-title">Tenant</h4>
        <i class="fa fa-times fa-2x" (click)="modal.dismiss('Cross click')"></i>
    </div>
    <div class="modal-body bg-danger">
        <h4>Are you sure to delete {{selTenantName}}?</h4>
    </div>
    <div class="modal-footer bg-danger">
        <button type="submit" class="btn btn-danger" (click)="deleteTenant()"><i class="fa fa-trash"></i> Sure</button>
        <button type="button" class="btn btn-success" (click)="modal.close('Cross click')"><i class="fa fa-times"></i>
            Cancel</button>
    </div>
</ng-template>