import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '@modules/main/main.component';
import { BlankComponent } from './pages/blank/blank.component';
import { LoginComponent } from '@modules/login/login.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { RegisterComponent } from '@modules/register/register.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthGuard } from '@guards/auth.guard';
import { NonAuthGuard } from '@guards/non-auth.guard';
import { ForgotPasswordComponent } from '@modules/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from '@modules/recover-password/recover-password.component';
import { SubMenuComponent } from './pages/main-menu/sub-menu/sub-menu.component';
import { PropertyInformationListComponent } from './pages/property/property-list/property-list.component';
import { PropertyInformationCreateComponent } from './pages/property/property-create/property-create.component';
import { UserManagementListComponent } from './pages/user-management/user-management-list/user-management-list.component';
import { UserManagementCreateComponent } from './pages/user-management/user-management-create/user-management-create.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { OtpPinComponent } from '@modules/otp-pin/otp-pin.component';
import { EmailTemplateCreateComponent } from './pages/settings/email-template/email-template-create/email-template-create.component';
import { EmailTemplateListComponent } from './pages/settings/email-template/email-template-list/email-template-list.component';
import { CountryComponent } from './pages/settings/country/country.component';
import { PaymentModeComponent } from './pages/property/payment-mode/payment-mode.component';
import { RoleSetupComponent } from './pages/settings/role-setup/role-setup.component';
import { RL } from './utils/static/route-link';
import { ChangePasswordComponent } from './modules/change-password/change-password.component';
import { TempAuthGuardGuard } from './guards/temp-auth-guard.guard';
import { ViewUserInfoComponent } from './pages/user-management/view-user-info/view-user-info.component';
import { TenantsComponent } from './pages/tenants/tenants.component';
import { RentInformationComponent } from './pages/rent-information/rent-information.component';
import { RentPostingComponent } from './pages/rent-posting/rent-posting.component';
import { RentReceivedComponent } from './pages/rent/rent-received/rent-received.component';
import { BillMatchingComponent } from './pages/rent/bill-matching/bill-matching.component';
import { DocumentComponent } from './pages/document/document/document.component';
import { LedgerComponent } from './pages/rent/ledger/ledger.component';
import { RentStatusComponent } from './pages/rent/rent-status/rent-status.component';
import { UnitsComponent } from './pages/units/units.component';
import { PaymentMethodsComponent } from './pages/settings/payment-methods/payment-methods.component';
import { ModalPrintComponent } from './pages/modal/modal-print/modal-print.component';
import { UsersComponent } from './pages/users/users.component';
import { CollectionReportComponent } from './pages/rent/collection-report/collection-report.component';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: RL.PROP_INFO_LI,
                component: PropertyInformationListComponent
            },
            {
                path: RL.RENT_INFO,
                component: RentInformationComponent
            },
            {
                path: RL.RENT_POSTING,
                component: RentPostingComponent
            },
            {
                path: RL.RENT_RECEIVED,
                component: RentReceivedComponent
            },
            {
                path: RL.DOCUMENT,
                component: DocumentComponent
            },
            {
                path: RL.BILL_MATCHING,
                component: BillMatchingComponent
            },
            {
                path: RL.COLLECTION_REPORT,
                component: CollectionReportComponent
            },
            {
                path: RL.LEDGER,
                component: LedgerComponent
            },
            {
                path: RL.RENT_STATUS,
                component: RentStatusComponent
            },
            {
                path: RL.USERS,
                component: UsersComponent
            },
            {
                path: RL.ROLE,
                component: RoleSetupComponent
            },
            {
                path: RL.UNITS,
                component: UnitsComponent
            },
            {
                path: RL.TENANTS_LI,
                component: TenantsComponent
            },
            {
                path: RL.PAY_METHOD,
                component: PaymentMethodsComponent
            },
            {
                path: 'modal-print',
                component: ModalPrintComponent
            },
            {
                path: RL.PROP_INFO_ADD,
                component: PropertyInformationCreateComponent
            },
            {
                path: RL.PROP_INFO_EDT,
                component: PropertyInformationCreateComponent
            },
            {
                path: RL.PAY_MODE_GET,
                component: PaymentModeComponent
            },
            {
                path: RL.EMAIL_TEMP_LI,
                component: EmailTemplateListComponent
            },
            {
                path: RL.EMAIL_TEMP_ADD,
                component: EmailTemplateCreateComponent
            },
            {
                path: RL.EMAIL_TEMP_EDT,
                component: EmailTemplateCreateComponent
            },
            {
                path: RL.PROFILE,
                component: ProfileComponent
            },
            {
                path: 'blank',
                component: BlankComponent
            },
            {
                path: 'sub-menu-1',
                component: SubMenuComponent
            },
            {
                path: 'sub-menu-2',
                component: BlankComponent
            },
            {
                path: '',
                component: DashboardComponent
            },
            {
                path: RL.USR_MGMT_LI,
                component: UserManagementListComponent
            },
            {
                path: RL.USR_MGMT_ADD,
                component: UserManagementCreateComponent
            },
            {
                path: RL.USR_MGMT_EDT,
                component: UserManagementCreateComponent
            },
            {
                path: RL.VIEW_USR,
                component: ViewUserInfoComponent
            },
            {
                path: RL.APP_NOTIFY,
                component: NotificationComponent
            },
            {
                path: RL.COUNTRY_LI,
                component: CountryComponent
            },
            {
                path: RL.ROLE_LI,
                component: RoleSetupComponent
            },
        ]
    },
    {
        path: RL.SIGN_IN,
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: RL.SIGN_UP,
        component: RegisterComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: RL.FORGOT_PW,
        component: ForgotPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: RL.RECOVER_PW,
        component: RecoverPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: RL.CHANGE_PW,
        component: ChangePasswordComponent,
        canActivate: [TempAuthGuardGuard]
    },
    {
        path: RL.OTP_PIN,
        component: OtpPinComponent
    },
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
