import { Component, OnInit, Renderer2 } from '@angular/core';
import { BaseResponse } from '@models/response';
import { PaginationResponse } from '@models/filter-pagination';
import { AppService } from '../../../services/app.service';
import { ApiService } from '../../../services/api.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ModelRentInformation } from '../../../models/rentInformation/model-rent-information';
import { ActivatedRoute } from '@angular/router';
import { ModelTenant } from '../../../models/tenant/model-tenant';
import { DataService } from '../../../services/data.service';
import { ModelPaymentMethod } from '../../../models/paymentMethod/model-payment-method';

@Component({
  selector: 'payment-method',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent implements OnInit {
  selectedTenant: ModelTenant | any;
  paginationRes: PaginationResponse = new PaginationResponse();
  hideSearch: boolean = true;
  public isLoading = false;
  closeResult = '';
  startingSn: number = 1;
  dataCount: number = 0;
  paymentMethods: Array<ModelPaymentMethod> = [];
  public payMethodForm: UntypedFormGroup;
  selPayMethodId: any;
  selTenantName: string;
  minStartDate: string;
  maxEndDate: string;
  dialogActFor: string = "Add";

  constructor(private apiService: ApiService, private renderer: Renderer2, private modalService: NgbModal, private toastr: ToastrService){
  }

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'property-list'
    );
    
    this.payMethodForm = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      name: new UntypedFormControl('', Validators.required),
      req_other_fields: new UntypedFormControl('', Validators.required),
      status: new UntypedFormControl(0, Validators.required)
    });

    this.getPaymentMethodList();
  }

  getPaymentMethodList(){
    this.apiService.getPaymentMethods().subscribe((result: BaseResponse) => {
      this.paymentMethods = result.data;
      this.dataCount = this.paymentMethods.length;
    })
  }  

  onDateChange(dateType: string){
    if(dateType === "Start"){
      this.minStartDate = this.payMethodForm.controls.start_date.value;
    } else {
      this.maxEndDate = this.payMethodForm.controls.end_date.value;
    }
  }

  add(content){
    this.dialogActFor = "Add";
    this.payMethodForm.reset();
    this.payMethodForm.patchValue({status: ''});
    this.openPopup(content)
  }

  edit(content, payMethod) {
    this.dialogActFor = "Edit";
    this.payMethodForm.reset();
    this.payMethodForm.patchValue({
      id: payMethod.id,
      name: payMethod.name,
      req_other_fields: payMethod.req_other_fields,
      status: payMethod.status,
    })
    this.openPopup(content);
	}

  async savePayMethod(){
    this.payMethodForm.markAllAsTouched();
    this.payMethodForm.get('name')?.markAsDirty();
    // this.payMethodForm.patchValue({ id: this.selPayMethodId });
    if(this.payMethodForm.valid){
      this.isLoading = true;
      if(this.payMethodForm.value.id != null){
        await this.apiService.updatePaymentMethod(this.payMethodForm.value).subscribe((result: any) => {
          this.handleResponse(result);
        });
      } else {
        const formData = { ...this.payMethodForm.value };  
        await this.apiService.addPaymentMethod(formData).subscribe((result: any) => {
          this.handleResponse(result);
        });
      }
      this.closeModal();
    }
  }

  del(content, tenant){
    this.selPayMethodId = tenant.id;
    this.selTenantName = tenant.name;
    this.openPopup(content);
  }

  async deletePayMethod(){
    await this.apiService.deletePaymentMethod(this.selPayMethodId).subscribe((result: any) => {
      this.handleResponse(result);
    });
    this.closeModal();
  }

  handleResponse(result: any){
    this.isLoading = false;
    if(result.code == 0){
      this.toastr.success(result.message)
      this.getPaymentMethodList();
    } else {
      this.toastr.error(result.message)
    }
  }
 

  openPopup(content){
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
  }
  
  closeModal(){
    this.modalService.dismissAll();
  }
  
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}
}