import { Component, OnInit, Renderer2 } from '@angular/core';
import { BaseResponse } from '@models/response';
import { PaginationResponse } from '@models/filter-pagination';
import { AppService } from '../../services/app.service';
import { ApiService } from '../../services/api.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ModelRentInformation } from '../../models/rentInformation/model-rent-information';
import { ActivatedRoute } from '@angular/router';
import { ModelTenant } from '../../models/tenant/model-tenant';
import { DataService } from '../../services/data.service';
import { PropertyUnits } from '../../models/units/model-units';
import { ModelUser } from '../../models/user/model-user';

@Component({
  selector: 'units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.scss']
})
export class UnitsComponent implements OnInit {
  selectedTenant: ModelTenant | any;
  paginationRes: PaginationResponse = new PaginationResponse();
  hideSearch: boolean = true;
  public isLoading = false;
  closeResult = '';
  startingSn: number = 1;
  dataCount: number = 0;
  unitInfoList: Array<PropertyUnits> = [];
  ownerList: Array<ModelUser> = [];
  public unitInfoForm: UntypedFormGroup;
  selTenantId: any;
  selTenantName: string;
  minStartDate: string;
  maxEndDate: string;
  dialogActFor: string = "Add";

  constructor(private apiService: ApiService, private route: ActivatedRoute, private dataService: DataService, private appService: AppService, private renderer: Renderer2, private modalService: NgbModal, private toastr: ToastrService){
    this.selTenantId = this.route.snapshot.paramMap.get('id');
    this.selectedTenant = dataService.getData();
  }

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'property-list'
    );
    
    this.unitInfoForm = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      tenant_id: new UntypedFormControl(this.selTenantId),
      rent_collector_id: new UntypedFormControl(null),
      name: new UntypedFormControl('', Validators.required),
      bedroom: new UntypedFormControl('', Validators.required),
      bathroom: new UntypedFormControl('', Validators.required),
      kitchen: new UntypedFormControl('', Validators.required),
      remarks: new UntypedFormControl('', Validators.required),
      status: new UntypedFormControl('', Validators.required)
    });

    this.getUnitInformation();
    this.getOwners();
  }
  
  getOwners(){
    this.apiService.ownerList().subscribe((result: BaseResponse) => {
      this.ownerList = result.data;
    })
  }  

  getUnitInformation(){
    this.apiService.getAllUnits().subscribe((result: BaseResponse) => {
      this.unitInfoList = result.data;
      this.dataCount = this.unitInfoList.length;
    })
  }  

  onDateChange(dateType: string){
    if(dateType === "Start"){
      this.minStartDate = this.unitInfoForm.controls.start_date.value;
    } else {
      this.maxEndDate = this.unitInfoForm.controls.end_date.value;
    }
  }

  add(content){
    this.dialogActFor = "Add";
    this.unitInfoForm.reset();
    this.unitInfoForm.patchValue({status: ''});
    this.openPopup(content)
  }

  edit(content, unitInfo) {
    this.dialogActFor = "Edit";
    this.unitInfoForm.reset();
    this.unitInfoForm.patchValue({
      id: unitInfo.id,
      tenant_id: unitInfo.tenant_id,
      rent_collector_id: unitInfo.rent_collector_id,
      name: unitInfo.name,
      bedroom: unitInfo.bedroom,
      bathroom: unitInfo.bathroom,
      kitchen: unitInfo.kitchen,
      remarks: unitInfo.remarks,
      status: unitInfo.status,
    })
    this.openPopup(content);
	}

  async saveUnitInfo(){
    this.unitInfoForm.markAllAsTouched();
    this.unitInfoForm.get('name')?.markAsDirty();
    this.unitInfoForm.patchValue({ tenant_id: this.selTenantId });
    if(this.unitInfoForm.valid){
      this.isLoading = true;
      if(this.unitInfoForm.value.id != null){
        await this.apiService.updateUnitInfo(this.unitInfoForm.value).subscribe((result: any) => {
          this.handleResponse(result);
        });
      } else {
        const formData = { ...this.unitInfoForm.value, tenant_id: this.selTenantId };  
        await this.apiService.addUnitInfo(formData).subscribe((result: any) => {
          this.handleResponse(result);
        });
      }
      this.closeModal();
    }
  }

  async deleteTenant(){
    await this.apiService.tenantDelete(this.selTenantId).subscribe((result: any) => {
      this.handleResponse(result);
    });
    this.closeModal();
  }

  handleResponse(result: any){
    this.isLoading = false;
    if(result.code == 0){
      this.toastr.success(result.message)
      this.getUnitInformation();
    } else {
      this.toastr.error(result.message)
    }
  }
 

  openPopup(content){
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
  }
  
  closeModal(){
    this.modalService.dismissAll();
  }
  
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}
}