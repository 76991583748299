// Static Messages

export class SMsg{
    public static LOGIN_SUCCESS: string = "Login Successful";
    public static REG_SUCCESS: string = "Register Successful";
    public static LOGIN_FAILED: string = "Sorry! email/username or Password do not match.";
    public static REG_FAILED: string = "Sorry! something went wrong while creating account. Please try again later.";
    public static OTP_SENT: string = "OTP sent to your email address.";
    public static OTP_VER_FAIL: string = "OTP verification failed.";
    public static CHG_UR_PW: string = "Please change your password.";
    public static FIELDS_EMP: string = "Please fill all the fields";
    public static PW_RESET_SUCCESS: string = "Password reset successful.";
    public static PW_RESET_ERROR: string = "Sorry! password reset unsuccessful.";
    public static STH_WENT_RNG: string = "Sorry! something went wrong. Please try again later.";
    public static UNKNOWN_PROP: string = "Sorry, there is no such property. Please select the property from the list which appears as you type in property search field.";
    
    public static NO_CM_ADDED: string = "Sorry! there is no any channel manager available. Please either insert or activate atleast 1 channel manager first.";    
}