<div class="card card-outline card-primary">
    <div class="card-header">
        <a [routerLink]="['/']"><img class="aln-center" src="../../../assets/img/logo.png" /></a>
    </div>
    <div class="card-body">
        <span>Fields marked with an astrisk (<span class="text-danger">*</span>) is required</span>

        <form [formGroup]="registerForm" (ngSubmit)="registerByAuth()">
            
            <div class="mt-2">
                <psmat-input type="text" formControlName="name" label="Name" controlName="name" [parentForm]="registerForm" required="true"></psmat-input>
                <div *ngIf="registerForm.controls['name'].dirty || registerForm.controls['name'].touched"
                    class="text-danger">
                    <span *ngIf="registerForm.controls['name'].errors?.['required']">*Name is required<br /></span>
                </div>
            </div>
            
            <div class="mt-4">
                <psmat-input type="email" formControlName="email" label="Email" controlName="email" [parentForm]="registerForm" required="true"></psmat-input>
                <div *ngIf="registerForm.controls['email'].dirty || registerForm.controls['email'].touched"
                    class="text-danger">
                    <span *ngIf="registerForm.controls['email'].errors?.['required']">*Email is required<br /></span>
                </div>
            </div>
            
                                        
            <div class="mt-3">
                <psmat-input type="password" formControlName="password" label="Password"
                    controlName="password" [parentForm]="registerForm" required="true"></psmat-input>
                    
                    <div *ngIf="registerForm.controls['password'].dirty || registerForm.controls['password'].touched"
                        class="text-danger">
                        <span
                            *ngIf="registerForm.controls['password'].errors?.['required']">*New
                            password
                            is
                            required<br /></span>
                    </div>
                    <div
                        *ngIf="!registerForm.controls['password'].errors?.['required'] && registerForm.controls['password'].invalid">
                        <span
                            class="{{ containsPTRN('password', numPtrn) ? 'text-success' : 'text-danger' }}">*New
                            password
                            must contain at least one number.<br /></span>
                        <span
                            class="{{ containsPTRN('password', ucPtrn) && containsPTRN('password', lcPtrn) ? 'text-success' : 'text-danger' }}">*one
                            uppercase and a lowercase letter<br /></span>
                        <span
                            class="{{ contains8Char() ? 'text-success' : 'text-danger' }}">*At
                            least 8
                            characters<br /></span>
                        <span
                            class="{{ containsPTRN('password', spcPtrn) ? 'text-danger' : 'text-success' }}">*New
                            password
                            cannot contain whitespace<br /></span>
                        <span
                            class="{{ containsPTRN('password', spclPtrn) ? 'text-success' : 'text-danger' }}">*At
                            least 1 special character<br /></span>
                    </div>
            </div>
            
            <div class="mt-3">
                <psmat-input type="password" formControlName="confirm_password" label="Confirm Password"
                    controlName="confirm_password" [parentForm]="registerForm" required="true"></psmat-input>
                    <div *ngIf="registerForm.controls['confirm_password'].dirty || registerForm.controls['confirm_password'].touched"
                        class="text-danger">
                        <span
                            *ngIf="registerForm.controls['confirm_password'].errors?.['required']">*Confirm
                            password
                            is
                            required<br /></span>
                        <span
                            *ngIf="!registerForm.controls['confirm_password'].errors?.['required'] && registerForm.errors?.['passwordMatched']">*Confirm
                            password do not match</span>
                    </div>
            </div>
            
            <div class="mt-4">
                <psmat-input OnlyNumber="true" formControlName="contact" label="Contact" controlName="contact" [parentForm]="registerForm" maxlength="15"></psmat-input>
            </div>

            <div class="mt-4">
                <div class="ps-mat-input">
                    <select name="title" formControlName="gender">
                        <option value="">- Select -</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                    </select>
                    <label for="gender"><span class="text-danger">*</span> Gender</label>
                </div>
                <div *ngIf="registerForm.controls['gender'].dirty || registerForm.controls['gender'].touched"
                    class="text-danger">
                    <span
                        *ngIf="registerForm.controls['gender'].errors?.['required']">*Select
                        gender</span>
                </div>
            </div>

            <div class="mt-4">
                <psmat-input type="date" formControlName="dob" label="DOB" [max]="minAllowedDob" controlName="dob" [parentForm]="registerForm"></psmat-input>
                <div *ngIf="registerForm.controls['dob'].invalid && (registerForm.controls['dob'].dirty || registerForm.controls['dob'].touched)"
                    class="text-danger">
                    <span
                        *ngIf="registerForm.controls['dob'].errors?.['required']">*DOB is required</span>
                </div>
            </div>

            <div class="mt-4">
                <div class="ps-mat-input">
                    <select name="role" formControlName="role">
                        <option value="">- Select -</option>
                        <option *ngFor="let role of roleList" [value]="role.id">{{ role.name }}</option>
                    </select>
                    <label for="role">Role</label>
                </div>
                <div *ngIf="registerForm.controls['role'].dirty || registerForm.controls['role'].touched"
                    class="text-danger">
                    <span *ngIf="registerForm.controls['role'].errors?.['required']">*Role is required<br /></span>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-5 aln-center">
                    <pf-button
                        [type]="'submit'"
                        [block]="true"
                        [loading]="isAuthLoading"
                        [disabled]="isFacebookLoading || isGoogleLoading"
                    >
                        Register
                    </pf-button>
                </div>
            </div>
        </form>

        <div class="w-1hper text-center mt-2">
            <a [routerLink]="['/login']">
                I already have an account</a
            >
        </div>
    </div>
</div>
