<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6 d-flex">
                <h4>Received Rents <span class="text-gray-600">({{dataCount}})</span></h4>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item active"><a routerLink="/tenants"><i class="fa fa-users"></i> Debtors</a>
                    </li>
                    <li class="breadcrumb-item active"><i class="fa fa-money-bill"></i> Received Rents</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card of-x-a p-3">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-primary" (click)="add(content)"><i class="fa fa-plus"></i>
                            Add
                            Received Rent</button>
                    </div>
                    <div class="col-sm-6">
                        <div class="flex float-sm-right">
                            <app-table-data-exporter tableId="dataTable"
                                fileName="received_rents"></app-table-data-exporter>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3" *ngIf="receivedRentList.length > 0">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body table-responsive p-0 tbl-h">
                            <div *ngIf="receivedRentList != null && receivedRentList.length > 0">
                                <table id="dataTable" class="table table-bordered table-head-fixed tbl-sm text-nowrap">
                                    <thead>
                                        <tr>
                                            <th class="text-center w-5p" scope="col">S.N.</th>
                                            <th>Receipt No</th>
                                            <th>Unit Name</th>
                                            <th class="text-center">Received Amount</th>
                                            <!-- <th class="text-center">Payment Status</th> -->
                                            <th class="text-center">Paid By</th>
                                            <th class="text-center">Payment Method</th>
                                            <th class="text-center">Received By</th>
                                            <th class="text-center">Received Date</th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let rentReceived of receivedRentList; index as i">
                                            <th scope="row">{{ startingSn + i }})</th>
                                            <td>{{rentReceived.receipt_no}}</td>
                                            <td>{{rentReceived.unit_name}}</td>
                                            <td class="text-right">NRS.{{rentReceived.received_amount}}</td>
                                            <!-- <td
                                                class="text-center text-{{ rentReceived.payment_status == 0 ? 'success' : rentReceived.payment_status == 1 ? 'secondary' : 'danger' }}">
                                                <i class="fa fa-money-bill"></i> {{ rentReceived.payment_status == 0 ? 'Paid' : rentReceived.payment_status == 1 ? 'Partially Paid' : 'Pending' }}
                                            </td> -->
                                            <td><i class="pl-2 pr-2 fa fa-user"></i> {{rentReceived.paid_by == null ?
                                                'N/A'
                                                : rentReceived.paid_by }}</td>
                                            <td><i
                                                    class="pl-2 pr-2 fa {{getFaIcon(rentReceived.payment_method) }} text-success"></i>
                                                {{rentReceived.payment_method == null ? 'N/A' :
                                                rentReceived.payment_method
                                                }}</td>
                                            <td><i class="pl-2 pr-2 fa fa-user"></i> {{rentReceived.received_by}}</td>
                                            <td class="text-center">{{rentReceived.received_date}}</td>
                                            <td class="text-center">
                                                <button class="btn btn-outline text-primary pstitle-primary-right"
                                                    pstitle="Edit rent rosting of {{ rentReceived.date }}"
                                                    (click)="edit(content, rentReceived)">
                                                    <i class="fa fa-edit p-1"></i></button>

                                                <button class="btn btn-outline text-black pstitle-secondary-right"
                                                    pstitle="Print bill for this rent posting {{ rentReceived.date }}"
                                                    (click)="printThisBill(rentReceived.id)">
                                                    <i class="fa fa-print p-1"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="receivedRentList == null || receivedRentList.length == 0">
                                <h4 class="text-center p-4">No Data</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="receivedRentList == null || receivedRentList.length == 0">
                <h4 class="text-center p-4">No Data</h4>
            </div>
        </div>
    </div>
    <!-- /.card -->
</section>



<ng-template #content let-modal>
    <div class="modal fade show" tabindex="-1" role="dialog" style="display: block; padding-right: 15px;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">{{dialogActFor}} rent received from
                        {{selectedTenant.name}}</h4>
                    <i class="fa fa-times fa-2x" (click)="modal.dismiss('Cross click')"></i>
                </div>
                <form [formGroup]="rentReceivedForm" appFocusFirstInvalidField>
                    <div class="modal-body">
                        <span>Fields marked with an astrisk (<span class="text-danger">*</span>) is required</span>
                        <div class="form-group row">
                            <input type="hidden" formControlName="id">
                            <div class="col-sm-12 mt-4">
                                <div class="ps-mat-input">
                                    <select name="rent_information_id" formControlName="rent_information_id"
                                        (change)="onOptionSelected($event)">
                                        <option [ngValue]="null">- Select -</option>
                                        <option *ngFor="let rentInfo of rentInfoList" [value]="rentInfo.id">{{
                                            rentInfo.unit_name }}</option>
                                    </select>
                                    <label for="rent_information_id">Rent received for</label>
                                </div>
                            </div>

                            <div class="col-sm-12 mt-4" *ngIf="balanceAmount">
                                <psmat-input onlyNumber="true" label="Balance Amount" [value]="balanceAmount"
                                    readonly="true"></psmat-input>
                            </div>
                            <div class="col-sm-6 mt-4">
                                <psmat-input onlyNumber="true" formControlName="received_amount" label="Received Amount"
                                    controlName="received_amount" [parentForm]="rentReceivedForm"
                                    required="true"></psmat-input>
                            </div>
                            <div class="col-sm-6 mt-4">
                                <psmat-input type="text" formControlName="paid_by" label="Paid By" controlName="paid_by"
                                    [parentForm]="rentReceivedForm" required="true"></psmat-input>
                            </div>
                            <div class="col-sm-{{isPayMethodCheque || isPayMethodWallet ? '6' : '12'}} mt-4">
                                <div class="ps-mat-input">
                                    <select name="payment_method_id" formControlName="payment_method_id"
                                        (change)="onPaymentMethodSelected($event)">
                                        <option value="null">- Select -</option>
                                        <option *ngFor="let payMethod of paymentMethods" [value]="payMethod.id">{{
                                            payMethod.name }}</option>
                                    </select>
                                    <label for="payment_method_id"><span class="text-danger">*</span> Payment
                                        Method</label>
                                </div>
                            </div>
                            <div class="col-sm-6 mt-4" *ngIf="isPayMethodCheque">
                                <psmat-input type="text" formControlName="cheque_bank_name" label="Cheque Bank Name"
                                    controlName="cheque_bank_name" [parentForm]="rentReceivedForm"
                                    [required]="isPayMethodCheque"></psmat-input>
                            </div>
                            <div class="col-sm-6 mt-4" *ngIf="isPayMethodCheque">
                                <psmat-input onlyNumber="true" formControlName="cheque_no" label="Cheque No"
                                    controlName="cheque_no" [parentForm]="rentReceivedForm"
                                    [required]="isPayMethodCheque"></psmat-input>
                            </div>
                            <div class="col-sm-6 mt-4" *ngIf="isPayMethodCheque">
                                <psmat-input type="date" formControlName="cheque_date" label="Cheque Date"
                                    controlName="cheque_date" [parentForm]="rentReceivedForm"
                                    [required]="isPayMethodCheque"></psmat-input>
                            </div>
                            <div class="col-sm-6 mt-4" *ngIf="isPayMethodWallet">
                                <psmat-input onlyNumber="true" formControlName="wallet_id" label="Wallet Id"
                                    controlName="wallet_id" [parentForm]="rentReceivedForm"
                                    [required]="isPayMethodWallet"></psmat-input>
                            </div>

                            <div class="col-sm-6 mt-4">
                                <psmat-input type="text" formControlName="received_by" label="Received By"
                                    controlName="received_by" [parentForm]="rentReceivedForm" required="true"
                                    readonly="true"></psmat-input>
                            </div>
                            <div class="col-sm-6 mt-4">
                                <psmat-input type="date" formControlName="received_date" label="Received Date"
                                    controlName="received_date" [parentForm]="rentReceivedForm" required="true"
                                    readonly="true" [min]="currentDate" [max]="currentDate"></psmat-input>
                            </div>
                            <div class="col-sm-12 mt-4">
                                <psmat-textarea [rows]="4" formControlName="remarks" label="Remarks"
                                    controlName="remarks" [parentForm]="rentReceivedForm" maxlength="255"
                                    required="true"></psmat-textarea>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-success" (click)="saveReceivedRent(false)"><i
                                class="fa fa-save"></i>
                            Save</button>
                        <button type="submit" class="btn btn-success" (click)="saveReceivedRent(true)"><i
                                class="fa fa-save"></i>
                            Save & <i class="fa fa-print"></i> Print</button>
                        <button type="button" class="btn btn-danger" (click)="closeModal()"><i class="fa fa-times"></i>
                            Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #delete let-modal>
    <div class="modal-header modal-xl bg-danger">
        <h4 class="modal-title" id="modal-basic-title">Tenant</h4>
        <i class="fa fa-times fa-2x" (click)="modal.dismiss('Cross click')"></i>
    </div>
    <div class="modal-body bg-danger">
        <h4>Are you sure to delete {{selTenantName}}?</h4>
    </div>
    <div class="modal-footer bg-danger">
        <button type="submit" class="btn btn-danger" (click)="deleteTenant()"><i class="fa fa-trash"></i> Sure</button>
        <button type="button" class="btn btn-success" (click)="modal.close('Cross click')"><i class="fa fa-times"></i>
            Cancel</button>
    </div>
</ng-template>