import { Component, OnInit, Renderer2 } from '@angular/core';
import { BaseResponse } from '@models/response';
import { PaginationResponse } from '@models/filter-pagination';
import { AppService } from '../../services/app.service';
import { ApiService } from '../../services/api.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ModelRentInformation } from '../../models/rentInformation/model-rent-information';
import { ActivatedRoute } from '@angular/router';
import { ModelRentPosting } from '../../models/rentPosting/model-rent-posting';
import { SMsg } from '../../utils/static/s-msg';
import { Utils } from '../../utils/utils';
import { DataService } from '../../services/data.service';
import { ModelTenant } from '../../models/tenant/model-tenant';
import { ModalPrintComponent } from '../modal/modal-print/modal-print.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'rent-posting',
  templateUrl: './rent-posting.component.html',
  styleUrls: ['./rent-posting.component.scss']
})
export class RentPostingComponent implements OnInit {
  selectedTenant: ModelTenant | any;
  paginationRes: PaginationResponse = new PaginationResponse();
  hideSearch: boolean = true;
  public isLoading = false;
  closeResult = '';
  startingSn: number = 1;
  dataCount: number = 0;
  rentPostingsList: Array<ModelRentPosting> = [];
  rentInfoList: Array<ModelRentInformation> = [];
  public rentPostingForm: UntypedFormGroup;
  selTenantId: any;
  selTenantName: string;
  selectedRentInfo: any;
  currentDate: any;
  minStartDate: string;
  endDate: string;
  maxEndDate: string;
  dialogActFor: string = "Add";
  htmlContent: any;

  constructor(private apiService: ApiService, private sanitizer: DomSanitizer, private route: ActivatedRoute, private dataService: DataService, private appService: AppService, private renderer: Renderer2, private modalService: NgbModal, private toastr: ToastrService) {
    this.selTenantId = this.route.snapshot.paramMap.get('id');
    this.selectedTenant = dataService.getData();
  }

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'property-list'
    );
    this.currentDate = Utils.getCurrentDate('MM-dd-yyyy');

    this.rentPostingForm = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      rent_information_id: new UntypedFormControl('', Validators.required),
      tenant_id: new UntypedFormControl(this.selTenantId),
      start_date: new UntypedFormControl(this.currentDate, Validators.required),
      end_date: new UntypedFormControl(this.currentDate, Validators.required),
      monthly_rent: new UntypedFormControl(0, Validators.required),
      electricity: new UntypedFormControl(0),
      water: new UntypedFormControl(0),
      other: new UntypedFormControl(0),
      other_information: new UntypedFormControl(0),
      date: new UntypedFormControl(this.currentDate, Validators.required),
    });

    this.getRentInformation();
    this.getRentPostings();
  }

  get sanitizedHtmlContent(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.htmlContent);
  }

  onBlur(dateType: string) {
    const sDate = this.rentPostingForm.controls.start_date.value;
    const eDate = this.rentPostingForm.controls.end_date.value;
    if (dateType === "Start") {
      this.minStartDate = sDate;
      this.maxEndDate = eDate;
    } else {
      this.minStartDate = sDate;
      this.maxEndDate = eDate;
    }
  }

  getRentInformation() {
    this.apiService.rentInformationList(this.selTenantId).subscribe((result: BaseResponse) => {
      this.rentInfoList = result.data;
    })
  }

  getRentPostings() {
    this.apiService.getTenantRentPostings(this.selTenantId).subscribe((result: BaseResponse) => {
      this.rentPostingsList = result.data;
      this.dataCount = this.rentPostingsList.length;
      if(this.dataCount > 0){
        this.minStartDate = this.rentPostingsList[this.dataCount - 1].start_date;
      }
    })
  }

  add(content) {
    this.dialogActFor = "Add";
    this.rentPostingForm.reset();
    this.rentPostingForm.patchValue({
      start_date: this.currentDate,
      end_date: this.currentDate,
      date: this.currentDate
    })
    this.openPopup(content)
  }

  onOptionSelected(event: any) {
    const selectedValue = event.target.value;
    this.selectedRentInfo = this.rentInfoList.find(rentInfo => rentInfo.id == selectedValue);

    if (this.selectedRentInfo) {
      this.minStartDate = this.selectedRentInfo.start_date;
      this.maxEndDate = this.selectedRentInfo.end_date;

      this.rentPostingForm.patchValue({
        rent_information_id: this.selectedRentInfo.id,
        tenant_id: this.selectedRentInfo.tenant_id,
        // start_date: this.selectedRentInfo.start_date,
        // end_date: this.selectedRentInfo.end_date,
        monthly_rent: this.selectedRentInfo.monthly_rent,
        electricity: this.selectedRentInfo.electricity,
        water: this.selectedRentInfo.water,
        other: this.selectedRentInfo.other,
        date: this.currentDate
      })
    } else {
      this.rentPostingForm.reset();
    }
  }

  edit(content, rentInfo) {
    this.dialogActFor = "Edit";
    this.rentPostingForm.reset();
    this.rentPostingForm.patchValue({
      id: rentInfo.id,
      rent_information_id: rentInfo.rent_information_id,
      tenant_id: rentInfo.tenant_id,
      start_date: rentInfo.start_date,
      end_date: rentInfo.end_date,
      monthly_rent: rentInfo.monthly_rent,
      electricity: rentInfo.electricity,
      water: rentInfo.water,
      other: rentInfo.other,
      other_information: rentInfo.other_information,
      date: rentInfo.date
    })
    this.openPopup(content);
  }

  async printThisBill(mrpId) {
    this.isLoading = true;
    await this.apiService.printRentPosting(mrpId).subscribe((result: any) => {
      if (result.code == 0) {
        this.htmlContent = result.data.billHtml;
        const dialogRef = this.modalService.open(ModalPrintComponent);
        dialogRef.componentInstance.htmlContent = this.htmlContent;
      } else {
        this.handleResponse(result);
      }
      this.isLoading = false;
    });
  }

  async saveRentPosting(printInvoice: boolean) {
    let isError = true;
    this.rentPostingForm.markAllAsTouched();
    if (this.rentPostingForm.valid) {
      this.isLoading = true;
      if (this.rentPostingForm.value.id != null) {
        await this.apiService.rentPostingUpdate(this.rentPostingForm.value).subscribe((result: any) => {
          if (result.code == 0) {
            this.htmlContent = result.data.billHtml;
            isError = false;
            if (printInvoice && this.htmlContent != "") {
              const dialogRef = this.modalService.open(ModalPrintComponent);
              dialogRef.componentInstance.htmlContent = this.htmlContent;
            }
          }
          this.handleResponse(result);
        });
      } else {
        const formData = { ...this.rentPostingForm.value, tenant_id: this.selTenantId };
        await this.apiService.rentPostingAdd(formData).subscribe((result: any) => {
          if (result.code == 0) {
            this.htmlContent = result.data.billHtml;
            isError = false;
            if (printInvoice && this.htmlContent != "") {
              const dialogRef = this.modalService.open(ModalPrintComponent);
              dialogRef.componentInstance.htmlContent = this.htmlContent;
            }
          }
          this.handleResponse(result);
        });
      }
      this.closeModal();
    } else {
      const invalidFields: string[] = [];
      const controls = this.rentPostingForm.controls;
      for (const controlName in controls) {
        if (controls.hasOwnProperty(controlName)) {
          if (controls[controlName].invalid) {
            invalidFields.push(controlName);
          }
        }
      }

      this.toastr.error(SMsg.FIELDS_EMP + " -> " + invalidFields);
    }
  }

  del(content, tenant) {
    this.selTenantId = tenant.id;
    this.selTenantName = tenant.name;
    this.openPopup(content);
  }

  async deleteTenant() {
    await this.apiService.tenantDelete(this.selTenantId).subscribe((result: any) => {
      this.handleResponse(result);
    });
    this.closeModal();
  }

  handleResponse(result: any) {
    this.isLoading = false;
    if (result.code == 0) {
      this.toastr.success(result.message)
      this.getRentPostings();
    } else {
      this.toastr.error(result.message)
    }
  }


  openPopup(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}