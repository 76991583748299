export class FilterPagination{
    pageNumber: number;
    pageSize: number;
    shortBy: string;
    shortOrder: string;
    searchTerm: string;
    isActive: string;

    constructor(pageNumber: number, pageSize: number, shortBy: string, shortOrder: string, searchTerm: string, isActive: string){
        this.pageNumber = pageNumber;
        this.pageSize = pageSize;
        this.shortBy = shortBy;
        this.shortOrder = shortOrder;
        this.searchTerm = searchTerm;
        this.isActive = isActive;
    }
}

export class BookingListFilter extends FilterPagination{
    isSynched: string;
    axisRoomHotelId: string;
    axisRoomBookingStatus: string;
    constructor(pageNumber: number, pageSize: number, shortBy: string, shortOrder: string, searchTerm: string, isActive: string, isSynched: string, axisRoomHotelId: string, axisRoomBookingStatus: string){
        super(pageNumber, pageSize, shortBy, shortOrder, searchTerm, isActive);
        this.isSynched = isSynched;
        this.axisRoomHotelId = axisRoomHotelId;
        this.axisRoomBookingStatus = axisRoomBookingStatus;
    }
}

export class ChannelManagerUserFilter extends FilterPagination{
    isLocked: string;
    constructor(pageNumber: number, pageSize: number, shortBy: string, shortOrder: string, searchTerm: string, isActive: string, isLocked: string){
        super(pageNumber, pageSize, shortBy, shortOrder, searchTerm, isActive);
        this.isLocked = isLocked;
    }
}

export class PropertyListFilter extends FilterPagination{
    callPublicAPI: string;
    allowSyncData: string;
    constructor(pageNumber: number, pageSize: number, shortBy: string, shortOrder: string, searchTerm: string, isActive: string, callPublicAPI: string, allowSyncData: string){
        super(pageNumber, pageSize, shortBy, shortOrder, searchTerm, isActive);
        this.callPublicAPI = callPublicAPI;
        this.allowSyncData = allowSyncData;
    }
}

export class PaginationResponse{
    pageNumber: number = 0;
    pageSize: number = 0;
    totalPage: number = 0;
    totalRow: number = 0;
}