<div class="card card-outline card-primary">
    <div class="card-header text-center">
        <a [routerLink]="['/']" class="h1"><img src="../../../assets/img/logo.png" /></a>
    </div>
    <div class="card-body">
        <form [formGroup]="changePasswordForm" (keyup.enter)="changePassword()" (ngSubmit)="changePassword()">

            <div class="col-12" *ngIf="userId == ''">
                <div class="ps-mat-input">
                    <input type="password" class="form-control" formControlName="newPassword" required />
                    <label for="newPassword">New Password</label>
                </div>
                <div *ngIf="changePasswordForm.controls['newPassword'].dirty || changePasswordForm.controls['newPassword'].touched"
                    class="text-danger txt-sm">
                    <span *ngIf="changePasswordForm.controls['newPassword'].errors?.['required']">*New Password is
                        required<br /></span>
                    <div *ngIf="!changePasswordForm.controls['newPassword'].errors?.['required'] && changePasswordForm.controls['newPassword'].invalid">
                        <span
                            class="{{ containsPTRN('newPassword', numPtrn) ? 'text-success' : 'text-danger' }}">*New Password
                            must contain at least one number.<br /></span>
                        <span
                            class="{{ containsPTRN('newPassword', ucPtrn) && containsPTRN('newPassword', lcPtrn) ? 'text-success' : 'text-danger' }}">*one
                            uppercase and a lowercase letter<br /></span>
                        <span class="{{ contains8Char() ? 'text-success' : 'text-danger' }}">*At least 8
                            characters<br /></span>
                        <span
                            class="{{ containsPTRN('newPassword', spcPtrn) ? 'text-danger' : 'text-success' }}">*New Password
                            cannot contain whitespace<br /></span>
                        <span
                            class="{{ containsPTRN('newPassword', spclPtrn) ? 'text-success' : 'text-danger' }}">*At
                            least 1 special character<br /></span>
                    </div>
                </div>

            </div>

            <div class="col-12 mt-4" *ngIf="userId == ''">
                <div class="ps-mat-input">
                    <input type="password" class="form-control" formControlName="password" required />
                    <label for="password">Confirm New Password</label>
                </div>
                <div *ngIf="changePasswordForm.controls['password'].dirty || changePasswordForm.controls['password'].touched"
                    class="text-danger txt-sm">
                    <span *ngIf="changePasswordForm.controls['password'].errors?.['required']">*Confirm new password
                        is
                        required<br/></span>
                        <span
                            *ngIf="!changePasswordForm.controls['password'].errors?.['required'] && changePasswordForm.errors?.['passwordMatched']">*Confirm new
                            password do not match</span>
                </div>
            </div>

            <div class="d-flex mt-4">
                <div class="col-12">
                    <pf-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">
                        Change
                    </pf-button>
                </div>
            </div>
        </form>
    </div>
    <!-- /.login-card-body -->
    <div class="card-footer">
        <p class="text-center fs-6 lh1 mb-0">
            <span class="fs-6">Copyright © {{data.copyrightYear}} Sarvanam Software</span><br />
            <span class="fs-6">Version {{data.versionNumber}}</span>
        </p>
    </div>
</div>