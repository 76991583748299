<div class="ps-mat-input">
    <input
        [id]="controlName"
        [OnlyNumber]="onlyNumber"
        [autocomplete]="controlName"
        [attr.type]="isTypePassword ? 'text' : type" 
        class="form-control" 
        [value]="value" 
        [maxLength]="maxlength"
        [attr.required]="required ? true : false" 
        [min]="min" 
        [max]="max" 
        [readOnly]="readonly" 
        (input)="onInput($event)" 
        (blur)="onTouched()"
        (keyup)="onKeyUpEvent($event)"
        (change)="onTextChangeEvent($event)"
        (keypress)="onKeyPressEvent($event)"/>
    <label [attr.for]="controlName"><span class="text-danger" *ngIf="required">*</span> {{ label }}</label>
    
    <i *ngIf="type === 'password'" class="ps-mat-inp-grp-append fa" [ngClass]="{
        'fa-eye-slash': !isTypePassword,
        'fa-eye': isTypePassword
      }" (click)="toggleFieldTextType()"></i> 
</div>

<div *ngIf="required && showRequiredMsg && (parentForm.controls[controlName].dirty || parentForm.controls[controlName].touched)"
class="text-danger text-sm">
<span *ngIf="parentForm.controls[controlName].errors?.['required']">*{{label != null || label != '' ? label : 'This field'}} is required<br /></span>
</div>