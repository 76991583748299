<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Notification</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item active">Notification</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header text-right">
                <button class="btn btn-primary">Mark all as read</button>
            </div>
            <div class="card-body">
                <div *ngFor="let notification of notifications;" class="row stroke-{{notification.type}}">
                    <div class="col-md-10">
                        <h5>{{ notification.title }}</h5>
                        <p>{{ notification.message }}</p>
                        <span><b>{{ notification.date | date:'EEE d MMM y h:m:s a' }}</b></span>
                        <span class="ml-2">{{ notification.date | dateAgo }}</span>
                    </div>

                    <div class="col-md-2 text-right">
                        <div class="buttons mt-4">
                            <button class="btn btn-primary" type="button">Mark as read</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- /.content -->