import { FilterPagination, PaginationResponse } from '@models/filter-pagination';
import { Component, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BaseResponse } from '@models/response';
import { ApiService } from '../../../services/api.service';
import { Roles } from '@models/Roles';

@Component({
  selector: 'app-role-setup',
  templateUrl: './role-setup.component.html',
  styleUrls: ['./role-setup.component.scss']
})

export class RoleSetupComponent {
  roleList:Array<Roles>  = [];
  closeResult = '';
  public roleForm: UntypedFormGroup;
  public isLoading = false;
  hideSearch: boolean = true;
  startingSn: number = 1;
  dataCount: number = 0;

  constructor(private apiService: ApiService, private renderer: Renderer2, private modalService: NgbModal, private toastr: ToastrService){}

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'role-list'
    );

    this.roleForm = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      code: new UntypedFormControl('', Validators.required),
      name: new UntypedFormControl('', Validators.required),
      description: new UntypedFormControl(''),
      status: new UntypedFormControl('', Validators.required)
    });
    this.getRoleList();
  }

  hideOrShowSearch() {
    this.hideSearch = !this.hideSearch;
  }

  onPageChange(pageNo: number) {
    this.getRoleList();
  }

  resetSearch() {
    this.getRoleList();
  }
  
  getRoleList(){    
    this.apiService.roleList().subscribe((result: BaseResponse) => {
      this.roleList = result.data;
      this.dataCount = this.roleList.length;
    })
  }

  edit(content, role) {
    this.roleForm.reset();
    this.roleForm.patchValue({
      id: role.id,
      code: role.code,
      name: role.name,
      description: role.description,
      status: role.status
    })
    this.openPopup(content);
	}

  openPopup(content){
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
  }

  getColorClass(status: string){
    if(status != null){
      return status == 'Y' ? 'text-success' : 'text-danger';
    }
  }

  closeModal(){
    this.modalService.dismissAll();
  }

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

  async saveRole(){
    this.roleForm.markAllAsTouched();
    this.roleForm.get('roleName')?.markAsDirty();
    if(this.roleForm.valid){
      this.isLoading = true;
      if(this.roleForm.value.id != null){
        await this.apiService.updateRole(this.roleForm.value).subscribe((result: any) => {
          this.handleResponse(result);
        });
      }
      // else {
      //   await this.apiService.createRole(this.roleForm.value).subscribe((result: any) => {
      //     this.handleResponse(result);
      //   });
      // }
      this.closeModal();
    }
  }

  handleResponse(result: any){
    this.isLoading = false;
    if(result.code == 0){
      this.toastr.success(result.message)
      this.getRoleList();
    } else {
      this.toastr.error(result.message)
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(
        document.querySelector('app-root'),
        'role-list'
    );
  }
}
