import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppService } from '@services/app.service';
import { DateTime } from 'luxon';
import { ModelProfile } from '@models/user-profile';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
    @ViewChild('dropdown') dropdown: ElementRef;
    public user: ModelProfile;
    isOpen = false;

    constructor(private appService: AppService) { }

    ngOnInit(): void {
        this.user = this.appService.user;
    }

    logout() {
        this.appService.logout();
    }

  showDropdown() {
    setTimeout(() => {
      this.isOpen = !this.isOpen;
    }, 0);
  }

    formatDate(date) {
        return DateTime.fromISO(date).toFormat('dd LLL yyyy');
    }
}
