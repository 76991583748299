import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AppService } from '@services/app.service';
import { ModelProfile } from '@models/user-profile';
import { DateTime } from 'luxon';
import { AbstractControl, FormBuilder, FormGroup, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Pattern } from '@utils/validators/patterns';
import { UserService } from '@services/user.service';
import { BaseResponse } from '@models/response';
import { UserInformation } from '@models/userInformation';

@Component({
  selector: 'view-user-info',
  templateUrl: './view-user-info.component.html',
  styleUrls: ['./view-user-info.component.scss']
})
export class ViewUserInfoComponent  implements OnInit {
  userId: string = '';
  public isLoading = false;
  public user: UserInformation;

  constructor(private appService: AppService, private toastr: ToastrService, private userService: UserService, private router: Router, private route: ActivatedRoute, private formbuilder: FormBuilder, private location: Location) { }

  ngOnInit(): void {
    let userId: any = this.route.snapshot.paramMap.get('id');
    if (userId != null) {
      this.userId = userId;
      this.isLoading = true;
      this.getUserDetailById();
    }
  }
  

  async getUserDetailById() {
    this.userService.userDetailsById(this.userId).subscribe((response: BaseResponse) => {
      if(response.code == 200){
        this.user = response.data;
      }
    });
  }

  formatDate(date) {
      return DateTime.fromISO(date).toFormat('dd LLL yyyy');
  }
  
  onBackPressed() {
      this.location.back();
  }
}
