<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6 d-flex">
                <h4>Documents <span class="text-gray-600">({{dataCount}})</span></h4>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item active"><a routerLink="/tenants"><i class="fa fa-users"></i> Debtors</a>
                    </li>
                    <li class="breadcrumb-item active"><i class="fa fa-home"></i> Rental Information</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card of-x-a p-3">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <button type="button" class="btn btn-primary" (click)="add(content)"><i class="fa fa-plus"></i>
                            Add
                            Document</button>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">

                    <div class="row mt-4" *ngIf="documentList != null && documentList.length > 0">
                        <div class="col-lg-3 col-md-6 col-12 mt-3" *ngFor="let document of documentList; index as i">
                            <div class="bg-white shadow-xl rounded-lg overflow-hidden">
                                <div class="bg-cover bg-center h-56 p-4"
                                    style="background-image: url('{{document.thumbnail}}')">
                                </div>
                                <div class="p-3">
                                    <div class="row">
                                        <div class="col-sm-11">
                                            <p class="uppercase tracking-wide text-sm font-bold text-gray-700">
                                                {{document.original_name}}</p>
                                            <span class="txt-sm"><strong>Type : {{document.doc_type}}</strong></span>
                                        </div>
                                        <div class="col-sm-1">
                                            <div class="flex float-sm-right">
                                                <pf-dropdown hide-arrow>
                                                    <div slot="button">
                                                        <a class="nav-link">
                                                            <i class="fas fa-ellipsis-v"></i>
                                                        </a>
                                                    </div>
                                                    <div slot="menu">
                                                        <div class="dropdown-divider"></div>
                                                        <button
                                                            class="btn btn-outline text-primary w-1hper text-sm text-left pstitle-primary-right"
                                                            pstitle="Edit document '{{ document.original_name }}'"
                                                            (click)="edit(content, document)"><i
                                                                class="fa fa-edit p-1"></i> Edit</button>

                                                        <div class="dropdown-divider"></div>
                                                        <button
                                                            class="btn btn-outline text-danger w-1hper text-sm text-left pstitle-danger-right"
                                                            pstitle="Delete document '{{ document.original_name }}'"
                                                            (click)="del(delete, document)"><i
                                                                class="fa fa-trash p-1"></i> Delete</button>
                                                    </div>
                                                </pf-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="text-gray-700"><i class="fas fa-file"></i>
                                            {{document.description ? document.description : "N/A"}}</p>
                                    </div>
                                    <div class="flex items-center pt-2">
                                        <button type="button" class="btn btn-block bg-primary"
                                            (click)="viewDoc(view, document)">View Details</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="documentList == null || documentList.length == 0">
                <h4 class="text-center p-4">No Data</h4>
            </div>
        </div>
    </div>
    <!-- /.card -->
</section>



<ng-template #content let-modal>
    <div class="modal-header modal-xl">
        <h4 class="modal-title" id="modal-basic-title">{{dialogActFor}} document of {{selectedTenant.name}}</h4>
        <i class="fa fa-times fa-2x" (click)="modal.dismiss('Cross click')"></i>
    </div>
    <form [formGroup]="documentForm" appFocusFirstInvalidField enctype="multipart/form-data">
        <div class="modal-body">
            <span>Fields marked with an astrisk (<span class="text-danger">*</span>) is required</span>
            <div class="form-group row">
                <input type="hidden" formControlName="image_id">
                <div class="col-sm-12 mt-4">
                    <div class="ps-mat-input">
                        <select name="doc_type_id" formControlName="doc_type_id" (change)="onOptionSelected($event, 0)">
                            <option [ngValue]="null">- Select -</option>
                            <option *ngFor="let docType of docTypeList" [value]="docType.id">{{ docType.name }}</option>
                        </select>
                        <label for="doc_type_id">Document Type</label>
                    </div>
                </div>

                <div class="col-sm-12 mt-4" *ngIf="rentInfoList.length > 0 || selRentInfoId">
                    <div class="ps-mat-input">
                        <select name="rent_info_id" formControlName="rent_info_id"
                            (change)="onOptionSelected($event, 1)" [required]="rentInfoList.length > 0">
                            <option value="null">- Select -</option>
                            <option *ngFor="let rentInfo of rentInfoList" [value]="rentInfo.id">RC:
                                {{rentInfo.rent_code}} | Name: {{ rentInfo.room_name }}</option>
                        </select>
                        <label for="rent_info_id">Room</label>
                    </div>
                </div>

                <div class="col-sm-12 mt-4">
                    <div class="ps-mat-input col-sm-12">
                        <input type="file" class="form-control" formControlName="image"
                            (change)="onImageSelected($event)" accept="image/jpeg, image/png, image/jpg" required />
                        <label for="search">Document</label>
                    </div>
                    <div *ngIf="documentForm.controls['image'].dirty && documentForm.controls['image'].touched"
                        class="text-danger">
                        <span *ngIf="documentForm.controls['image'].errors?.['required']">*Document is required</span>
                    </div>
                </div>

                <div class="col-sm-12 mt-4" *ngIf="thumbnail">
                    <div class="ps-mat-input col-sm-12 p-3" style="background-color: #00000017;">
                        <img [src]="thumbnail" alt="" srcset="" class="uploaded-img">
                        <label for="search" style="margin-top: -20px !important;">Uploaded Document</label>
                    </div>
                </div>

                <div class="col-sm-12 mt-4">
                    <psmat-textarea [rows]="4" formControlName="description" label="Description"
                        controlName="description" [parentForm]="documentForm" maxlength="255"></psmat-textarea>
                </div>

                <div class="col-sm-12 mt-4">
                    <div class="ps-mat-input">
                        <select name="status" formControlName="status">
                            <option value="">- Select -</option>
                            <option value="0">Active</option>
                            <option value="1">Inactive</option>
                        </select>
                        <label for="status">Status</label>
                    </div>
                    <div *ngIf="documentForm.controls['status'].dirty || documentForm.controls['status'].touched"
                        class="text-danger">
                        <span *ngIf="documentForm.controls['status'].errors?.['required']">*Status is required</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-success" (click)="saveDocument()"><i class="fa fa-save"></i>
                Save</button>
            <button type="button" class="btn btn-danger" (click)="modal.close('Cross click')"><i
                    class="fa fa-times"></i> Cancel</button>
        </div>
    </form>
</ng-template>


<ng-template #view let-modal>
    <div class="modal-content">
        <div class="modal-header modal-xl">
            <h4 class="modal-title" id="modal-basic-title">{{selDocumentName}}</h4>
            <i class="fa fa-times fa-2x" (click)="modal.dismiss('Cross click')"></i>
        </div>
        <div class="modal-body p-0 m-0">
            <div class="col-sm-12 p-0 m-0" *ngIf="mainImage">
                <img [src]="mainImage" style="width: auto; height: 100%;">
            </div>

            <div class="col-sm-12 mt-4">
                <span class="txt-sm"><strong>Type : </strong>{{selDocType}}</span>
            </div>

            <div class="col-sm-12">
                <span class="txt-sm"><strong>Description : </strong>{{selDocDesc}}</span>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="downloadImage()"><i class="fa fa-download"></i> Download</button>
            <button type="button" class="btn btn-danger" (click)="modal.close('Cross click')"><i
                    class="fa fa-times"></i>
                Close</button>
        </div>
    </div>
</ng-template>


<ng-template #delete let-modal>
    <div class="modal-header modal-xl bg-danger">
        <h4 class="modal-title" id="modal-basic-title">Document</h4>
        <i class="fa fa-times fa-2x" (click)="modal.dismiss('Cross click')"></i>
    </div>
    <div class="modal-body bg-danger">
        <h4>Are you sure to delete {{selDocumentName}}?</h4>
    </div>
    <div class="modal-footer bg-danger">
        <button type="submit" class="btn btn-danger" (click)="deleteDocument()"><i class="fa fa-trash"></i>
            Sure</button>
        <button type="button" class="btn btn-success" (click)="modal.close('Cross click')"><i class="fa fa-times"></i>
            Cancel</button>
    </div>
</ng-template>