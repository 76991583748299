import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[OnlyNumber]'
})

export class OnlyNumber {

  regexStr = '^[0-9]*$';
  constructor(private el: ElementRef) { }

  @Input() OnlyNumber: boolean;

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    let e = <KeyboardEvent>event;

    if (this.OnlyNumber) {
      if (['Delete', 'Backspace', 'Escape', 'Enter', '.', 'Decimal', 'Tab'].indexOf(e.key) !== -1 ||
        // Allow: Ctrl+A
        (e.key == 'a' && e.ctrlKey === true) ||
        // Allow: Ctrl+C
        (e.key == 'c' && e.ctrlKey === true) ||
        // Allow: Ctrl+V
        (e.key == 'v' && e.ctrlKey === true) ||
        // Allow: Ctrl+X
        (e.key == 'x' && e.ctrlKey === true) ||
        // Allow: home, end, left, right
        (e.key.startsWith('Arrow') || e.key.startsWith('Home') || e.key.startsWith('End'))) {
        // let it happen, don't do anything
        return;
      }
      let ch = e.key;
      let regEx = new RegExp(this.regexStr);
      if (regEx.test(ch))
        return;
      else
        e.preventDefault();
    }
  }
}