import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AppService } from '../../services/app.service';
import { ModelProfile } from '@models/user-profile';
import { DateTime } from 'luxon';
import { AbstractControl, FormBuilder, FormGroup, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Pattern } from '@utils/validators/patterns';
import { UserService } from '../../services/user.service';
import { RL } from '../../utils/static/route-link';
import { ModelUserProfile } from '../../models/response/model-user-profile';
import { Utils } from '../../utils/utils';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public userProfileForm: UntypedFormGroup;
  activTab: string = 'profile';
  changePasswordForm: UntypedFormGroup;
  public isLoading = false;
  isEditDisabled = true;
  public user: ModelProfile;
  public userProfile: ModelUserProfile;
  numPtrn = Pattern.NUM;
  ucPtrn = Pattern.UC;
  lcPtrn = Pattern.LC;
  spcPtrn = Pattern.SPACE;
  spclPtrn = Pattern.SPECIAL;
  emailPtrn = Pattern.EMAIL;
  usrNmPtrn = Pattern.USERNAME;
  minAllowedDob: any;

  constructor(private appService: AppService, private toastr: ToastrService, private userService: UserService, private apiService: ApiService, private router: Router, private formbuilder: FormBuilder, private location: Location) {
    this.getProfileInfo();
  }

  ngOnInit(): void {
    this.user = this.appService.user;
    this.minAllowedDob = Utils.getMaxAllowedDOB(null);    

    this.changePasswordForm = this.formbuilder.group(
      {
        new_password: ["", Validators.required],
        confirmPassword: ["", Validators.required]
      },
      {
        validator: this.ConfirmedValidator('new_password', 'confirmPassword')
      });

    this.changePasswordForm = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      current_password: new UntypedFormControl(null, Validators.required),
      new_password: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=.*[a-zA-Z]).{8,}$/)]),
      confirmPassword: new UntypedFormControl(null, Validators.required),
    }, { validators: [this.passwordMatchValidator] });

    this.userProfileForm = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      title: new UntypedFormControl('', Validators.required),
      name: new UntypedFormControl(null, [Validators.required, Validators.minLength(4)]),
      email: new UntypedFormControl(null),
      contact: new UntypedFormControl(null, [Validators.required, Validators.minLength(3)]),
      gender: new UntypedFormControl('', Validators.required),
      dob: new UntypedFormControl(null),
      role: new UntypedFormControl(null),
      role_name: new UntypedFormControl(null),
    });
  }

  async updatePassowrd() {
    this.changePasswordForm.markAllAsTouched();
    if (this.changePasswordForm.valid) {
      this.isLoading = true;
      this.changePasswordForm.patchValue({id: this.appService.userId});
      await this.apiService.changePassword(this.changePasswordForm.value).subscribe((result: any) => {
        this.isLoading = false;
        if (result.code == 0) {
          this.toastr.success(result.message)
          this.appService.logout();
          this.changePasswordForm.reset();
        } else {
          this.toastr.error(result.message)
        }
      });
    }
  }

  async getProfileInfo(){
    this.isLoading = true;
    await this.apiService.profile(this.appService.userId).subscribe((result: any) => {
      this.isLoading = false;
      if (result.code == 0) {
        this.userProfile = result.data;
        this.userProfileForm.patchValue({
          id: this.userProfile.id,
          title: this.userProfile.title,
          name: this.userProfile.name,
          email: this.userProfile.email,
          contact: this.userProfile.contact,
          gender: this.userProfile.gender,
          dob: this.userProfile.dob,
          role: this.userProfile.role,
          role_name: this.userProfile.role_name,
          status: this.userProfile.status
        });
      } else {
        this.toastr.error(result.message)
      }
    });
  }

  passwordMatchValidator: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    const password = group.get('new_password')?.value ?? null;
    const cPassword = group.get('confirmPassword')?.value ?? '';
    return password !== cPassword ? { passwordMatched: true } : null;
  }

  containsPTRN(controller: string, pattern: any): boolean {
    const pwVal: string = this.changePasswordForm.controls[controller].value;
    return Pattern.checkPattern(pwVal, pattern);
  }

  contains8Char(): boolean {
    const pwVal: string = this.changePasswordForm.controls['new_password'].value;
    return pwVal != null && pwVal.length >= 8;
  }


  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  async updateUserDetails() {
    this.userProfileForm.markAllAsTouched();
    if (this.userProfileForm.valid) {
      this.isLoading = true;
      this.userProfileForm.patchValue({id: this.appService.userId});
      await this.userService.updateUserProfile(this.userProfileForm.value).subscribe((result: any) => {
        this.isLoading = false;
        if (result.code == 0) {
          this.toastr.success(result.message)
          this.cancelEdit();
        } else {
          this.toastr.error(result.message)
        }
      });
    }
  }

  setThisTabActive(tabName: string) { this.activTab = tabName; }

  getTabActiveStat(tabName: string): string {
    return this.activTab == tabName ? 'active' : '';
  }

  cancelEdit(){
    this.userProfileForm.reset();
    this.isEditDisabled = true;
    this.getProfileInfo();
  }

  isTabActive(tabName: string): boolean { return this.activTab == tabName; }

  onBackPressed() {
    this.location.back();
  }
}
