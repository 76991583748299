import { Location } from '@angular/common';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseResponse } from '@models/response';
import { UserInformation } from '@models/userInformation';
import { UserService } from '@services/user.service';
import { Pattern } from '@utils/validators/patterns';
import { Utils } from '../../../utils/utils';

@Component({
  selector: 'app-user-management-create',
  templateUrl: './user-management-create.component.html',
  styleUrls: ['./user-management-create.component.scss'],
})

export class UserManagementCreateComponent implements OnInit {
  public userManagementForm: UntypedFormGroup;
  public isLoading = false;
  enableReadOnly = false;
  userId: string = '';
  numPtrn = Pattern.NUM;
  ucPtrn = Pattern.UC;
  lcPtrn = Pattern.LC;
  spcPtrn = Pattern.SPACE;
  spclPtrn = Pattern.SPECIAL;
  emailPtrn = Pattern.EMAIL;
  usrNmPtrn = Pattern.USERNAME;

  constructor(private renderer: Renderer2, private toastr: ToastrService, private userService: UserService, private router: Router, private route: ActivatedRoute, private formbuilder: FormBuilder, private location: Location) {
  }

  ngOnInit() {

    let userId: any = this.route.snapshot.paramMap.get('id');
    if (userId != null) {
      this.userId = userId;
      this.isLoading = true;
      this.enableReadOnly = true;
      this.getUserDetailById();
    }

    this.userManagementForm = this.formbuilder.group(
      {
        password: ["", Validators.required],
        confirmPassword: ["", Validators.required]
      },
      {
        validator: this.ConfirmedValidator('password', 'confirmPassword')
      });

    this.renderer.addClass(document.querySelector('app-root'), 'user-management-page');

    this.userManagementForm = new UntypedFormGroup({
      userId: new UntypedFormControl(null),
      salutation: new UntypedFormControl('', Validators.required),
      firstName: new UntypedFormControl(null, [Validators.required, Validators.minLength(4)]),
      middleName: new UntypedFormControl(null),
      lastName: new UntypedFormControl(null, [Validators.required, Validators.minLength(3)]),
      gender: new UntypedFormControl('', Validators.required),
      userName: new UntypedFormControl(null, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      password: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=.*[a-zA-Z]).{8,}$/)]),
      confirmPassword: new UntypedFormControl(null, Validators.required),
      pwdChangeDays: new UntypedFormControl(null, Validators.required),
      pwdChangeWarningDays: new UntypedFormControl(null, Validators.required),
      sessionTimeOutPeriod: new UntypedFormControl(null, Validators.required),
      contactNo: new UntypedFormControl(null, Validators.required),
      maxFraudLogIn: new UntypedFormControl(null, Validators.required),
      isActive: new UntypedFormControl('', Validators.required)
    }, { validators: [this.passwordMatchValidator] });

    this.userManagementForm.controls['userId'].valueChanges.subscribe(userId => {
      if (userId == '') {
        this.userManagementForm.addControl('password', new UntypedFormControl('', [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=.*[a-zA-Z]).{8,}$/)]));
        this.userManagementForm.addControl('confirmPassword', new UntypedFormControl('', Validators.required));
      } else {
        this.userManagementForm.removeControl('password');
        this.userManagementForm.removeControl('confirmPassword');
      }
    });
  }
  
  passwordMatchValidator: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    const password = group.get('password')?.value ?? '';    
    const cPassword = group.get('confirmPassword')?.value ?? '';
    return password !== cPassword ? { passwordMatched: true } : null;
  }

  containsPTRN(controller: string, pattern: any):boolean{
    const pwVal:string = this.userManagementForm.controls[controller].value;
    return Pattern.checkPattern(pwVal, pattern);
  }

  contains8Char():boolean{
    const pwVal:string = this.userManagementForm.controls['password'].value;
    return pwVal != null && pwVal.length >= 8;
  }

  async saveUserDetails() {
    this.userManagementForm.markAllAsTouched();
    if (this.userManagementForm.valid) {
      this.isLoading = true;
      if (this.userId == '') {
        await this.userService.userInformationCreate(this.userManagementForm.value).subscribe((result: any) => {
          this.isLoading = false;
          if (result.responseCode == "200") {
            this.toastr.success(result.responseMessage)
            this.router.navigate(['/user-management-list']);
          } else {
            this.toastr.error(result.responseMessage)
          }
        });
      } else {
        await this.userService.userInformationUpdate(this.userManagementForm.value).subscribe((result: any) => {
          this.isLoading = false;
          if (result.responseCode == "200") {
            this.toastr.success(result.responseMessage)
            this.router.navigate(['/user-management-list']);
          } else {
            this.toastr.error(result.responseMessage)
          }
        });
      }
    } else {
      this.toastr.error('Please fill all the required fields.');
    }
  }


  async getUserDetailById() {
    this.userService.userDetailsById(this.userId).subscribe((response: BaseResponse) => {
      const userInformation: UserInformation = response.data;
      this.userManagementForm.patchValue({
        userId: this.userId,
        userName: userInformation.userName,
        salutation: userInformation.salutation,
        firstName: userInformation.firstName,
        middleName: userInformation.middleName,
        lastName: userInformation.lastName,
        gender: Utils.ucFirst(userInformation.gender),
        password: userInformation.password,
        pwdChangeDays: userInformation.pwdChangeDays,
        pwdChangeWarningDays: userInformation.pwdChangeWarningDays,
        sessionTimeOutPeriod: userInformation.sessionTimeOutPeriod,
        contactNo: userInformation.contactNo,
        email: userInformation.email,
        maxFraudLogIn: userInformation.maxFraudLogIn,
        propertyInformationId: userInformation.propertyInformationId,
        isActive: userInformation.isActive
      });
    });
  }


  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  onBackPressed(){
    this.location.back();
  }
}
