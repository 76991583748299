import { Component, OnInit, Renderer2, SecurityContext } from '@angular/core';
import { BaseResponse } from '@models/response';
import { AppService } from '@services/app.service';
import { ModelTenant } from '@models/tenant/model-tenant';
import { ApiService } from '@services/api.service';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SMsg } from '@utils/static/s-msg';
import { ActivatedRoute } from '@angular/router';
import { ModelDocument } from '@models/document/res-model-document';
import { ModelRentInformation } from '@models/rentInformation/model-rent-information';
import { ModelDocumentType } from '@models/document/res-model-doc-type';
import { DataService } from '@services/data.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {
  selectedTenant: ModelTenant | any;
  hideSearch: boolean = true;
  public isLoading = false;
  closeResult = '';
  startingSn: number = 1;
  dataCount: number = 0;
  documentList: Array<ModelDocument> = [];
  rentInfoList: Array<ModelRentInformation> = [];
  docTypeList: Array<ModelDocumentType> = [];
  public documentForm: UntypedFormGroup;
  selectedDoc: any;
  selTenantId: any;
  selRentInfoId: any;
  selDocTypeId: any;
  selDocumentId: any;
  selDocumentName: any;
  thumbnail: any;
  mainImage: any;
  selTenantName: string;
  newImageSelected: boolean = false;
  isUpdatingDocument: boolean = false;
  dialogActFor: string = "Add";
  selDocName: string;
  selDocType: string;
  selDocDesc: string;
  imageBlobUrl: SafeUrl;

  constructor(private sanitizer: DomSanitizer, private apiService: ApiService, private route: ActivatedRoute, private dataService: DataService, private fb: FormBuilder,  private appService: AppService, private renderer: Renderer2, private modalService: NgbModal, private toastr: ToastrService){
    this.selTenantId = this.route.snapshot.paramMap.get('id');
    this.selectedTenant = dataService.getData();
  }

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'property-list'
    );
    
    this.documentForm = this.fb.group({
      image_id: [null],
      tenant_id: [this.selTenantId],
      rent_info_id: [null],
      doc_type_id: ['', Validators.required],
      image: [null, Validators.required],
      description: [null],
      status: ['', Validators.required]
    });

    this.getDocumentList();
  }  

  onOptionSelected(event: any, optionType: number) {
    // Here optionType 0 = Document Type List and 1 = Rent Info List
    const selectedValue = event.target.value;
    if(selectedValue){
      if(optionType == 0){
        this.selDocTypeId = selectedValue;
        this.selectedDoc = this.docTypeList.find(doc => doc.id == selectedValue);  
        if(this.selectedDoc.name === "Agreement"){
          this.getRentInformation();
        } else {
          this.rentInfoList = [];
          this.selRentInfoId = null;
        }
      } else if (optionType == 1){
        this.selRentInfoId = selectedValue;
      }
    } else {
      this.documentForm.reset();
    }
  }

  getRentInformation() {
    this.apiService.rentInformationList(this.selTenantId).subscribe((result: BaseResponse) => {
      this.rentInfoList = result.data;
    })
  }

  getDocumentList(){
    this.apiService.documentList(this.selTenantId).subscribe((result: BaseResponse) => {
      this.documentList = result.data;
      this.dataCount = this.documentList.length;
    })
  }  

  getDocumentTypeList(){
    this.apiService.documentTypeList().subscribe((result: BaseResponse) => {
      this.docTypeList = result.data;
    })
  }

  add(content){
    this.dialogActFor = "Add";
    this.selRentInfoId = null;
    this.thumbnail = null;
    this.documentForm.reset();
    this.documentForm.patchValue({status: ''});
    this.isUpdatingDocument = false;
    this.openPopup(content)
    this.getDocumentTypeList();
  }

  viewDoc(view, data){
    this.selDocumentName =  data.original_name;
    this.selDocName = data.new_name;
    this.selDocType = data.doc_type;
    this.selDocDesc = data.description;
    this.mainImage = data.main_image;
    this.openPopup(view)
  }

  downloadImage() {
    this.apiService.getDocument(this.selDocName).subscribe((blob) => {
      const objectUrl = URL.createObjectURL(blob);
      this.imageBlobUrl = this.sanitizer.bypassSecurityTrustUrl(objectUrl);
      this.triggerDownload(this.imageBlobUrl);
    });
  }
  
  triggerDownload(url: SafeUrl) {
    const a = document.createElement('a');
    const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.URL, url);
    if (sanitizedUrl) {
      a.href = sanitizedUrl;
      a.download = this.selDocumentName || 'rms-file.jpg';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      console.error('Invalid URL');
    }
  }

  edit(content, data) {
    this.dialogActFor = "Edit";
    this.selRentInfoId = null;
    this.getDocumentTypeList();
    this.documentForm.reset();
    this.thumbnail = data.thumbnail;
    this.selDocTypeId = data.doc_type_id;
    const docType = data.doc_type;
    if(docType === "Agreement"){
      this.getRentInformation();
    }
    this.documentForm.patchValue({
      image_id: data.id,
      doc_type_id: data.doc_type_id,
      rent_info_id: data.rent_info_id,
      description: data.description,
      status:data.status
    })
    this.openPopup(content);
    this.isUpdatingDocument = true;
	}

  onImageSelected(event: any): void {
    this.newImageSelected = true;
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.thumbnail = e.target.result;
      };
      reader.readAsDataURL(file);
      this.documentForm.get('image')?.setValue(file);
    } else {
      this.thumbnail = null;
    }
  }

  async saveDocument(){
    if (this.isUpdatingDocument) {
      this.documentForm.get('image')?.clearValidators();
      this.documentForm.get('image')?.updateValueAndValidity();
    }
    this.documentForm.markAllAsTouched();
    if(this.documentForm.valid){
      this.isLoading = true;
      const formData = new FormData();
      formData.append('tenant_id', this.selTenantId);
      formData.append('rent_info_id', this.selRentInfoId);
      
      const documentControl = this.documentForm.get('image');
      if (this.documentForm.get('image_id')) { formData.append('image_id', this.documentForm.get('image_id')?.value); }
      if (this.documentForm.get('doc_type_id')) { formData.append('doc_type_id', this.documentForm.get('doc_type_id')?.value); }
      if (this.newImageSelected && documentControl && documentControl.value) { formData.append('image', documentControl.value); }
      if (this.documentForm.get('description')) { formData.append('description', this.documentForm.get('description')?.value); }
      if (this.documentForm.get('status')) { formData.append('status', this.documentForm.get('status')?.value); }
      
      if(this.documentForm.value.image_id != null){
        await this.apiService.updateDocument(formData).subscribe((result: any) => {
          this.handleResponse(result);
        });
      } else {
        this.apiService.addDocument(formData).subscribe((result: any) => {
          this.handleResponse(result);
        });
      }
      this.closeModal();
    } else {
      const invalidFields: string[] = [];
      const controls = this.documentForm.controls;
      for (const controlName in controls) {
        if (controls.hasOwnProperty(controlName)) {
          if (controls[controlName].invalid) {
            invalidFields.push(controlName);
          }
        }
      }

      this.toastr.error(SMsg.FIELDS_EMP + " -> "+ invalidFields);
    }
  }

  del(content, document){
    this.selDocumentId = document.id;
    this.selDocumentName = document.original_name;
    this.openPopup(content);
  }

  async deleteDocument(){
    await this.apiService.deleteDocument(this.selDocumentId).subscribe((result: any) => {
      this.handleResponse(result);
    });
    this.closeModal();
  }

  handleResponse(result: any){
    this.isLoading = false;
    if(result.code == 0){
      this.toastr.success(result.message)
      this.documentForm.reset();
      this.getDocumentList();
    } else {
      this.toastr.error(result.message)
    }
  }
 

  openPopup(content){
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
  }
  
  closeModal(){
    this.modalService.dismissAll();
  }
  
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}
}