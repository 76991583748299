import {AppState} from '@/store/state';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppService} from '@services/app.service';
import {Observable} from 'rxjs';
import { RL } from '../../../utils/static/route-link';

const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public user;
    public menu;

    constructor(
        public appService: AppService,
        private store: Store<AppState>
    ) {}

    ngOnInit() {
        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
        });
        this.user = this.appService.user;
        var role = this.appService.roleId;
        this.menu = MENU;
    }
}


export const MENU = [
    {
        name: 'Dashboard',
        iconClasses: 'fas fa-tachometer-alt',
        path: [RL.ROOT]
    },
    // {
    //     name: 'Property',
    //     iconClasses: 'fas fa-hotel',
    //     children: [
    //         {
    //             name: 'All Property',
    //             iconClasses: 'fas fa-circle',
    //             path: [RL.PROP_INFO_LI]
    //         },
    //         {
    //             name: 'Add Unit',
    //             iconClasses: 'fas fa-circle',
    //             path: [RL.PROP_INFO_ADD]
    //         }
    //     ]
    // },
    {
        name: 'Users',
        iconClasses: 'fas fa-users',
        path: [RL.USERS]
    },
    {
        name: 'Units',
        iconClasses: 'fas fa-th',
        path: [RL.UNITS]
    },
    {
        name: 'Debtors',
        iconClasses: 'fas fa-users',
        path: [RL.TENANTS_LI]
    },
    {
        name: 'Rent Status',
        iconClasses: 'fas fa-home',
        path: [RL.RENT_STATUS]
    },
    {
        name: 'Bill Matching',
        iconClasses: 'fas fa-file-invoice-dollar',
        path: [RL.BILL_MATCHING]
    },
    {
        name: 'Collection Report',
        iconClasses: 'fas fa-file-invoice',
        path: [RL.COLLECTION_REPORT]
    },
    {
        name: 'Ledger',
        iconClasses: 'fas fa-file-invoice',
        path: [RL.LEDGER]
    },
    {
        name: 'Settings',
        iconClasses: 'fas fa-cogs',
        children: [
            {
                name: 'Payment Method',
                iconClasses: 'fas fa-money-bill',
                path: [RL.PAY_METHOD]
            },
            // {
            //     name: 'Receipt',
            //     iconClasses: 'fas fa-task',
            //     path: 'modal-print'
            // },
            {
                name: 'User Role',
                iconClasses: 'fas fa-user-cog',
                path: [RL.ROLE]
            }
        ]
    }
    // {
    //     name: 'Documents',
    //     iconClasses: 'fas fa-folder-open',
    // },
];