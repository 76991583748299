<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6 d-flex">
                <h4>Roles <span class="text-gray-600">({{dataCount}})</span></h4>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item active"><a routerLink="/tenants"><i class="fa fa-users"></i> Settings</a>
                    </li>
                    <li class="breadcrumb-item active"><i class="fa fa-user-cog"></i> Roles</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card of-x-a p-3">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        <h4>Roles</h4>
                    </div>
                    <div class="col-sm-6">
                        <div class="flex float-sm-right text-right">
                            <app-table-data-exporter tableId="dataTable"
                                fileName="role_setup"></app-table-data-exporter>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3" *ngIf="roleList.length > 0">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body table-responsive p-0" style="max-height: 50vh; height: auto;">
                            <table id="dataTable" class="table table-head-fixed tbl-sm text-nowrap">
                                <thead>
                                    <tr>
                                        <th class="text-center" scope="col">S.N.</th>
                                        <th class="w-1hp text-center" scope="col">Code</th>
                                        <th scope="col">Role</th>
                                        <th scope="col">Description</th>
                                        <th class="text-center w-2hp" scope="col">Status</th>
                                        <th class="text-center w-1hp" scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let role of roleList; index as i">
                                        <th class="text-center w-1hp" scope="row">{{ startingSn + i }})</th>
                                        <td class="text-center">{{ role.code }}</td>
                                        <td>{{ role.name }}</td>
                                        <td>{{ role.description }}</td>
                                        <td class="text-center text-{{ role.status == 0 ? 'success' : 'danger' }}">
                                            <i class="fa fa-circle"></i> {{ role.status == 0 ? 'Active' : 'InActive' }}
                                        </td>
                                        <td class="text-center">
                                            <button class="btn btn-outline text-primary pstitle-primary-right"
                                                (click)="edit(content, role)" pstitle="Edit role {{ role.name }}"><i
                                                    class="fa fa-edit text-primary p-1"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>
            </div>

            <div *ngIf="roleList == null || roleList.length == 0">
                <h4 class="text-center p-4">No Data</h4>
            </div>
        </div>
    </div>
    <!-- /.card -->
</section>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Role</h4>
        <i class="fa fa-times fa-2x" (click)="modal.dismiss('Cross click')"></i>
    </div>
    <form [formGroup]="roleForm" (ngSubmit)="saveRole()">
        <div class="modal-body">
            <div class="form-group row">
                <span class="pl-2 pr-2">Fields marked with an astrisk (<span class="text-danger">*</span>) is
                    required</span>
                <div class="col-sm-12 mt-4">
                    <psmat-input type="text" formControlName="name" label="Name" controlName="name"
                        [parentForm]="roleForm" required="true"></psmat-input>
                </div>
                <div class="col-sm-12 mt-4">
                    <psmat-input onlyNumber="true" formControlName="code" label="Code" controlName="code"
                        [parentForm]="roleForm" [readonly]="true" required="true"></psmat-input>
                </div>
                <div class="col-sm-12 mt-4">
                    <psmat-textarea [rows]="4" formControlName="description" label="Description"
                        controlName="description" [parentForm]="roleForm" maxlength="255"></psmat-textarea>
                </div>
                <div class="col-sm-12 mt-4">
                    <div class="ps-mat-input">
                        <select id="status" name="status" formControlName="status">
                            <option value="">- Select -</option>
                            <option value="0">Active</option>
                            <option value="1">Inactive</option>
                        </select>
                        <label for="status"><span class="text-danger">*</span> Status</label>
                    </div>
                    <div *ngIf="roleForm.controls['status'].dirty || roleForm.controls['status'].touched"
                        class="text-danger">
                        <span *ngIf="roleForm.controls['status'].errors?.['required']">*Status is required</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-success"><i class="fa fa-save"></i> Save</button>
            <button type="button" class="btn btn-danger" (click)="modal.close('Cross click')"><i
                    class="fa fa-times"></i> Cancel</button>
        </div>
    </form>
</ng-template>