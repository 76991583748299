export class ModelRentStatusFilter{
    tenant_id: number | null;
    rent_collector_id: number | null;
    rent_info_id: number | null;
    start_date: string | null;
    end_date: string | null;

    constructor(tenantId: number, rentCollectorId: number, rent_info_id: number, start_date: string, end_date: string){
        this.tenant_id = tenantId;
        this.rent_collector_id = rentCollectorId;
        this.rent_info_id = rent_info_id;
        this.start_date = start_date;
        this.end_date = end_date;
    }
}