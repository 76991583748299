import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'psmulti-select',
  templateUrl: './psmulti-select.component.html',
  styleUrls: ['./psmulti-select.component.scss'],
})

export class PSMultiSelectComponent  implements OnInit {
  selectedOptions: string[] = [];
  searchTerm: string = '';
  showDropdown: boolean = false;

  ngOnInit(): void {
  }

}