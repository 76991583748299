import {Component, HostBinding} from '@angular/core';
import {DateTime} from 'luxon';
import * as jsonData  from '@assets/setting.json';
import { SysConfig } from '@models/system-config';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent {
    data: SysConfig = jsonData;
    @HostBinding('class') classes: string = 'main-footer';
    public currentYear: string = DateTime.now().toFormat('y');
}
