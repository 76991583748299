<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <div class="container-fluid">
                    <div class="row mt-3">
                        <div class="col-sm-6">
                            <h4>{{ selPropertyId == '' ? 'Add' : 'Update' }} Property</h4>
                        </div>
                        <div class="col-sm-6">
                            <ol class="flex float-sm-right">
                                <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i> Home</a></li>
                                <li class="breadcrumb-item active"><i class="fa fa-home"></i> {{ selPropertyId
                                    == '' ?
                                    'Add' : 'Update' }} Property</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <hr />

                <div class="border p-3 bg-white text-sm rounded-lg flex justify-center">
                    <div class="{{currentStep >= 0 ? 'ps-stepper-complete' : 'ps-stepper'}}">
                        <i class="fas fa-home mt-1"></i>
                        <span class="text-gray-900 ml-2">Property Information</span>
                    </div>
                    <div class="spliter"></div>
                    <div class="{{currentStep >= 1 ? 'ps-stepper-complete' : 'ps-stepper'}} ml-2">
                        <i class="fas fa-map-marker-alt mt-1"></i>
                        <span class="text-gray-900 ml-2">Location</span>
                    </div>
                    <div class="spliter"></div>
                    <div class="{{currentStep >= 2 ? 'ps-stepper-complete' : 'ps-stepper'}}">
                        <i class="fas fa-th-large mt-1"></i>
                        <span class="text-gray-900 ml-2">Unit</span>
                    </div>
                    <div class="spliter"></div>
                    <div class="{{currentStep >= 3 ? 'ps-stepper-complete' : 'ps-stepper'}}">
                        <i class="fas fa-file-invoice mt-1"></i>
                        <span class="text-gray-900 ml-2">Rent & Charges</span>
                    </div>
                    <div class="spliter"></div>
                    <div class="{{currentStep >= 4 ? 'ps-stepper-complete' : 'ps-stepper'}}">
                        <i class="fas fa-images mt-1"></i>
                        <span class="text-gray-900 ml-2">Image</span>
                    </div>
                </div>

                <div class="card mt-3">
                    <div class="card-body">
                        <div class="stepper">
                            <div class="step" [ngClass]="{ 'active': currentStep == 0 }">
                                <form [formGroup]="propertyInformationForm" appFocusFirstInvalidField>
                                    <div class="col-sm-6">
                                        <h5>Property Information</h5>
                                    </div>
                                    <hr />
                                    <div class="form-group row">
                                        <div class="col-sm-4 mt-4">
                                            <div class="ps-mat-input">
                                                <input type="text" class="form-control" formControlName="property_name"
                                                    required />
                                                <label for="property_name">Property Name</label>
                                            </div>
                                            <div *ngIf="propertyInformationForm.controls['property_name'].dirty || propertyInformationForm.controls['property_name'].touched"
                                                class="text-danger">
                                                <span
                                                    *ngIf="propertyInformationForm.controls['property_name'].errors?.['required']">*Property
                                                    name is required</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 mt-4">
                                            <div class="ps-mat-input">
                                                <input OnlyNumber="true" type="text" class="form-control"
                                                    formControlName="units" required />
                                                <label for="units">Number of Units</label>
                                            </div>
                                            <div *ngIf="propertyInformationForm.controls['units'].dirty || propertyInformationForm.controls['units'].touched"
                                                class="text-danger">
                                                <span
                                                    *ngIf="propertyInformationForm.controls['units'].errors?.['required']">*Unit
                                                    is required</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 mt-4">
                                            <div class="ps-mat-input">
                                                <input OnlyNumber="true" type="text" class="form-control"
                                                    formControlName="bedrooms" required />
                                                <label for="bedrooms">Number of Bedroom</label>
                                            </div>
                                            <div *ngIf="propertyInformationForm.controls['bedrooms'].dirty || propertyInformationForm.controls['bedrooms'].touched"
                                                class="text-danger">
                                                <span
                                                    *ngIf="propertyInformationForm.controls['bedrooms'].errors?.['required']">*No
                                                    of bedroom is required</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 mt-4">
                                            <div class="ps-mat-input">
                                                <input OnlyNumber="true" type="text" class="form-control"
                                                    formControlName="bathrooms" required />
                                                <label for="bathrooms">Number of Bathroom</label>
                                            </div>
                                            <div *ngIf="propertyInformationForm.controls['bathrooms'].dirty || propertyInformationForm.controls['bathrooms'].touched"
                                                class="text-danger">
                                                <span
                                                    *ngIf="propertyInformationForm.controls['bathrooms'].errors?.['required']">*No
                                                    of bathroom is required</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 mt-4">
                                            <div class="ps-mat-input">
                                                <input OnlyNumber="true" type="text" class="form-control"
                                                    formControlName="kitchens" required />
                                                <label for="kitchens">Number of Kitchen</label>
                                            </div>
                                            <div *ngIf="propertyInformationForm.controls['kitchens'].dirty || propertyInformationForm.controls['kitchens'].touched"
                                                class="text-danger">
                                                <span
                                                    *ngIf="propertyInformationForm.controls['kitchens'].errors?.['required']">*No
                                                    of kitchen is required</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 mt-4">
                                            <div class="ps-mat-input">
                                                <input OnlyNumber="true" type="text" class="form-control"
                                                    formControlName="square_footage" />
                                                <label for="square_footage">Square Foot</label>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 mt-4">
                                            <div class="ps-mat-input">
                                                <textarea class="form-control" rows="6" formControlName="description"
                                                    required></textarea>
                                                <label for="description">Description</label>
                                            </div>
                                            <div *ngIf="propertyInformationForm.controls['description'].dirty || propertyInformationForm.controls['description'].touched"
                                                class="text-danger">
                                                <span
                                                    *ngIf="propertyInformationForm.controls['description'].errors?.['required']">*Description
                                                    is required</span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="step" [ngClass]="{ 'active': currentStep == 1 }">
                                <form [formGroup]="propertyLocationForm" appFocusFirstInvalidField>
                                    <div class="col-sm-6">
                                        <h5>Location Information</h5>
                                    </div>
                                    <hr />
                                    <div class="form-group row">
                                        <div class="col-sm-4 mt-4">
                                            <div class="ps-mat-input">
                                                <input type="text" class="form-control" formControlName="country"
                                                    required />
                                                <label for="country">Country</label>
                                            </div>
                                            <div *ngIf="propertyLocationForm.controls['country'].dirty || propertyLocationForm.controls['country'].touched"
                                                class="text-danger">
                                                <span
                                                    *ngIf="propertyLocationForm.controls['country'].errors?.['required']">*Country
                                                    name is required</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 mt-4">
                                            <div class="ps-mat-input">
                                                <input type="text" class="form-control" formControlName="state"
                                                    required />
                                                <label for="state">State</label>
                                            </div>
                                            <div *ngIf="propertyLocationForm.controls['state'].dirty || propertyLocationForm.controls['state'].touched"
                                                class="text-danger">
                                                <span
                                                    *ngIf="propertyLocationForm.controls['state'].errors?.['required']">*State
                                                    is required</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 mt-4">
                                            <div class="ps-mat-input">
                                                <input type="text" class="form-control" formControlName="city"
                                                    required />
                                                <label for="city">City</label>
                                            </div>
                                            <div *ngIf="propertyLocationForm.controls['city'].dirty || propertyLocationForm.controls['city'].touched"
                                                class="text-danger">
                                                <span
                                                    *ngIf="propertyLocationForm.controls['city'].errors?.['required']">*City
                                                    name is required</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 mt-4">
                                            <div class="ps-mat-input">
                                                <input type="text" class="form-control" formControlName="zip_code"
                                                    required />
                                                <label for="zip_code">Zip Code</label>
                                            </div>
                                            <div *ngIf="propertyLocationForm.controls['zip_code'].dirty || propertyLocationForm.controls['zip_code'].touched"
                                                class="text-danger">
                                                <span
                                                    *ngIf="propertyLocationForm.controls['zip_code'].errors?.['required']">*Zip
                                                    code is required</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 mt-4">
                                            <div class="ps-mat-input">
                                                <input type="text" class="form-control" formControlName="address"
                                                    required />
                                                <label for="address">Address</label>
                                            </div>
                                            <div *ngIf="propertyLocationForm.controls['address'].dirty || propertyLocationForm.controls['address'].touched"
                                                class="text-danger">
                                                <span
                                                    *ngIf="propertyLocationForm.controls['address'].errors?.['required']">*Address
                                                    is required</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 mt-4">
                                            <div class="ps-mat-input">
                                                <input type="text" class="form-control" formControlName="map_link"
                                                    (blur)="showMap()" />
                                                <label for="map_link">Map Link</label>
                                            </div>
                                            <span class="text-sm text-secondary"><strong>Note:</strong> Use iframe src
                                                link</span>
                                        </div>
                                        <div class="col-sm-12 mt-4">
                                            <div class="border bg-white text-sm rounded-lg flex justify-center map-div">
                                                <div *ngIf="sanitizedIframeHtml">
                                                    <div [innerHTML]="sanitizedIframeHtml"></div>
                                                </div>
                                                <div *ngIf="sanitizedIframeUrl">
                                                    <iframe [src]="sanitizedIframeUrl" width="100%" height="200px"
                                                        frameborder="0"></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="step" [ngClass]="{ 'active': currentStep == 2 }">
                                <form [formGroup]="propertyUnitForm" appFocusFirstInvalidField>
                                    <div class="col-sm-6">
                                        <h5>Add Unit</h5>
                                    </div>
                                    <hr />
                                    <div *ngFor="let unitForm of propertyUnitForm; let i = index">
                                        <div class="row" [formGroup]="unitForm">
                                          <div class="col-sm-3 mt-2">
                                            <div class="ps-mat-input">
                                              <input type="text" class="form-control" formControlName="name" required />
                                              <label for="name">Unit Name</label>
                                            </div>
                                            <div *ngIf="unitForm.get('name').hasError('required') && unitForm.get('name').touched" class="text-danger">
                                              *Name is required
                                            </div>
                                          </div>

                                          <div class="col-sm-3 mt-2">
                                            <div class="ps-mat-input">
                                              <input OnlyNumber="true" type="text" class="form-control" formControlName="bedroom" required />
                                              <label for="bedroom">Bedroom</label>
                                            </div>
                                            <div *ngIf="unitForm.get('bedroom').hasError('required') && unitForm.get('bedroom').touched" class="text-danger">
                                              *Bedroom is required
                                            </div>
                                          </div>

                                          <div class="col-sm-3 mt-2">
                                            <div class="ps-mat-input">
                                              <input OnlyNumber="true" type="text" class="form-control" formControlName="bathroom" required />
                                              <label for="bathroom">Bathroom</label>
                                            </div>
                                            <div *ngIf="unitForm.get('bathroom').hasError('required') && unitForm.get('bathroom').touched" class="text-danger">
                                              *Bathroom is required
                                            </div>
                                          </div>

                                          <div class="col-sm-3 mt-2">
                                            <div class="ps-mat-input">
                                              <input OnlyNumber="true" type="text" class="form-control" formControlName="kitchen" required />
                                              <label for="kitchen">Kitchen</label>
                                            </div>
                                            <div *ngIf="unitForm.get('kitchen').hasError('required') && unitForm.get('kitchen').touched" class="text-danger">
                                              *Kitchen is required
                                            </div>
                                          </div>
                                        </div>
                                        <hr/>
                                      </div>
                                </form>
                            </div>
                            <div class="step" [ngClass]="{ 'active': currentStep == 3 }">
                                <form [formGroup]="rentAndChargesForm" appFocusFirstInvalidField>
                                    <div class="col-sm-6">
                                      <h5>Rent & Charges</h5>
                                    </div>
                                    <hr />
                                    <div *ngFor="let rentAndChargeForm of rentAndChargesForm; let i = index">
                                        <div class="row" [formGroup]="rentAndChargeForm">
                                          <div class="col-sm-3 mt-2">
                                            <div class="ps-mat-input">
                                              <input type="text" class="form-control" formControlName="general_rent" required />
                                              <label for="general_rent">General Rent</label>
                                            </div>
                                            <div *ngIf="rentAndChargeForm.get('general_rent').hasError('required') && rentAndChargeForm.get('general_rent').touched" class="text-danger">
                                              *General rent is required
                                            </div>
                                          </div>

                                          <div class="col-sm-3 mt-2">
                                            <div class="ps-mat-input">
                                              <input OnlyNumber="true" type="text" class="form-control" formControlName="security_deposit" required />
                                              <label for="security_deposit">Security Deposit</label>
                                            </div>
                                            <div *ngIf="rentAndChargeForm.get('security_deposit').hasError('required') && rentAndChargeForm.get('security_deposit').touched" class="text-danger">
                                              *Security deposit is required
                                            </div>
                                          </div>

                                          <div class="col-sm-3 mt-2">
                                            <div class="ps-mat-input">
                                              <input OnlyNumber="true" type="text" class="form-control" formControlName="late_fee" required />
                                              <label for="late_fee">Late Fee</label>
                                            </div>
                                            <div *ngIf="rentAndChargeForm.get('late_fee').hasError('required') && rentAndChargeForm.get('late_fee').touched" class="text-danger">
                                              *Late fee is required
                                            </div>
                                          </div>

                                          <div class="col-sm-3 mt-2">
                                            <div class="ps-mat-input">
                                              <input OnlyNumber="true" type="text" class="form-control" formControlName="incident_receipt" required />
                                              <label for="incident_receipt">Incident Receipt</label>
                                            </div>
                                            <div *ngIf="rentAndChargeForm.get('incident_receipt').hasError('required') && rentAndChargeForm.get('incident_receipt').touched" class="text-danger">
                                              *Incident receipt is required
                                            </div>
                                          </div>
                                        </div>
                                        <hr/>
                                      </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="controls">
                    <button class="btn btn-primary" (click)="prevStep()"
                        [disabled]="currentStep == 0">Previous</button>
                    <button class="btn btn-primary ml-3" (click)="saveNewOrUpdatedData()"
                        [disabled]="currentStep === maxStep">Save & Next</button>
                </div>

                <div class="final-controls">
                    <button type="submit" class="btn btn-success"><i class="fa fa-save"></i> Save</button>
                    <button type="button" class="btn btn-danger ml-3" (click)="onBackPressed()"><i
                            class="fa fa-times"></i> Cancel</button>
                </div>
            </div>
        </div>
    </div>
</section>