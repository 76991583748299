<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Profile</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">User Profile</li>
                </ol>
            </div>
        </div>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3">
                <div class="card card-primary card-outline">
                    <div class="card-body box-profile">
                        <div class="text-center">
                            <pf-image class="user-img" src="/assets/img/default-profile.png" alt="User profile picture"
                                width="100" height="100" rounded></pf-image>
                        </div>

                        <h3 class="profile-username text-center">
                            {{ user != null ? user.userName : 'N/A'}}
                        </h3>

                        <p class="text-muted text-center">{{ user != null && user.isActive ? 'Verified User' :
                            'Unverified User'}}</p>

                        <ul class="list-group list-group-unbordered mb-3">
                            <li class="list-group-item">
                                <b>Username</b>
                                <a class="float-right">{{ user != null ? user.userName : 'N/A'}}</a>
                            </li>
                            <li class="list-group-item">
                                <b>Member Since</b> <a class="float-right">Year ago</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-9">
                <div class="card">
                    <div class="card-header">
                        <h4>Detail Information</h4>
                    </div>
                    <div class="card-body">
                        <div class="timeline">
                            <div>
                                <i class="fas fa-user-circle bg-blue"></i>
                                <div class="timeline-item">
                                    <h3 class="timeline-header">Username</h3>
                                    <div class="timeline-body">{{user.userName}}</div>
                                </div>
                            </div>
                            <div>
                                <i class="fas fa-envelope bg-blue"></i>
                                <div class="timeline-item">
                                    <h3 class="timeline-header">Email</h3>
                                    <div class="timeline-body">{{user.email}}</div>
                                </div>
                            </div>
                            <div>
                                <i class="fas fa-user bg-blue"></i>
                                <div class="timeline-item">
                                    <h3 class="timeline-header">Name</h3>
                                    <div class="timeline-body">{{user.salutation + user.firstName + " " +user.middleName + " " +user.lastName}}</div>
                                </div>
                            </div>
                            <div>
                                <i class="fas fa-mars bg-blue"></i>
                                <div class="timeline-item">
                                    <h3 class="timeline-header">Gender</h3>
                                    <div class="timeline-body">
                                        <span><i class="fa fa-{{ user.gender !== 'male' && user.gender !== 'Male' ? 'female' : 'male' }}"></i> {{user.gender}}</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <i class="fas fa-phone bg-blue"></i>
                                <div class="timeline-item">
                                    <h3 class="timeline-header">Contact No</h3>
                                    <div class="timeline-body">{{user.contactNo !== '' ? user.contactNo : "N/A"}}</div>
                                </div>
                            </div>
                            <div>
                                <i class="fas fa-circle bg-blue"></i>
                                <div class="timeline-item">
                                    <h3 class="timeline-header">Active Status</h3>
                                    <div class="timeline-body">
                                        <span class="text-center text-{{ user.isActive != 'N' ? 'success' : 'danger' }}"><i class="fa fa-circle"></i> {{ user.isActive != 'N' ? 'Active' : 'InActive' }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>