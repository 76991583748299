import { Component, HostBinding, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AppService } from '@services/app.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '@services/api.service';

@Component({
  selector: 'app-otp-pin',
  templateUrl: './otp-pin.component.html',
  styleUrls: ['./otp-pin.component.scss']
})

export class OtpPinComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'login-box';
  loading!: boolean;
  resendEnabled: boolean = false;
  public otpPinForm: UntypedFormGroup;
  public otp: string = '';
  showOtpComponent = true;
  resendOTPTime = 10;
  time: number = this.resendOTPTime;
  display ;
  interval;

  constructor(private renderer: Renderer2, private toastr: ToastrService, private appService: AppService, private apiService: ApiService) { }

  async ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'login-page'
    );

    this.otpPinForm = new UntypedFormGroup({
      OTPCode: new UntypedFormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
      Username: new UntypedFormControl(null)
    });
    this.startTimer();
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.time > 0) {
        this.time--;
      } else {
        this.resendEnabled = true;
        clearInterval(this.interval);
        this.display = null;
      }
      this.display=this.transform( this.time)
    }, 1000);
  }
  transform(value: number): string {
       const minutes: number = Math.floor(value / 60);
       return minutes + ':' + (value - minutes * 60);
  }

  onInput(event: any) {
    const inputValue = event.target.value;
    if (inputValue.length > 6) {
      event.target.value = inputValue.substring(0, 6);
    }
  }
  

  onConfigChange() {
    this.showOtpComponent = false;
    this.otp = '';
    setTimeout(() => {
      this.showOtpComponent = true;
    }, 0);
  }

  async submitOTP() {
    this.loading = true;
    if (this.otpPinForm.valid) {
      this.loading = false;
      await this.appService.verifyOTP(this.otpPinForm.value);
      this.toastr.clear();
    } else {
      this.loading = false;
      this.toastr.error("Please provide OTP sent to your email address.");
    }
  }

  resendOTP(){
      this.toastr.info("Sending new OTP");
      this.resendEnabled = false;
      this.time = this.resendOTPTime;
      this.startTimer();
      this.apiService.resendOTP().subscribe((response: any) => {
        if(response != null && response.code != null && response.code == 200){
          this.toastr.success(response.message);
        } else {
          this.toastr.error(response.message);
        }
      })
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'login-page'
    );
  }
}