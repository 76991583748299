/**
 * Route Link
 * This static class RL is a static string file that contains the link to every page links.
*/

export class RL{
    public static ROOT: string = "/";
    public static SIGN_IN: string = "login";
    public static SIGN_UP: string = "register";
    public static FORGOT_PW: string = "forgot-password";
    public static RECOVER_PW: string = "recover-password/:username/:token";
    public static CHANGE_PW: string = "change-password";
    public static OTP_PIN: string = "otp-pin";

    public static BOOKING_LI: string = "booking-list";

    public static TENANTS_LI: string = "tenants";
    public static UNITS: string = "units";
    public static USERS: string = "users";
    public static ROLE: string = "role";

    public static RENT_INFO: string = "rent-information/:id";
    public static RENT_POSTING: string = "rent-posting/:id";
    public static RENT_RECEIVED: string = "rent-received/:id";
    public static DOCUMENT: string = "document/:id";
    public static BILL_MATCHING: string = "bill-matching";
    public static LEDGER: string = "ledger";
    public static COLLECTION_REPORT: string = "collection-report";
    public static RENT_STATUS: string = "rent-status";

    public static PAY_METHOD: string = "payment-method";

    public static PROP_INFO_LI: string = "property";
    public static PROP_INFO_ADD: string = "property-create";
    public static PROP_INFO_EDT: string = "property-edit/:id";
    
    public static ROOM_TYPE_GET: string = "room-type/:id";
    public static RATE_TYPE_GET: string = "rate-type/:id";
    public static MEAL_PLAN_GET: string = "meal-plan/:id";
    public static MKT_SEGMENT_GET: string = "market-segment/:id";
    public static PAY_MODE_GET: string = "payment-mode/:id";
    public static CM_LI: string = "channel-manager";

    public static EMAIL_TEMP_LI: string = "email-template-list";
    public static EMAIL_TEMP_ADD: string = "email-template-create";
    public static EMAIL_TEMP_EDT: string = "email-template-edit/:id";

    public static PROFILE: string = "profile";
    public static USR_MGMT_LI: string = "user-management-list";
    public static USR_MGMT_ADD: string = "user-management-create";
    public static USR_MGMT_EDT: string = "user-management-edit/:id";
    public static VIEW_USR: string = "view-user-info/:id";
    public static APP_NOTIFY: string = "app-notification";
    public static COUNTRY_LI: string = "country-list";
    public static ROLE_LI: string = "role-list";
    public static CM_ROLE_LI_GET: string = "cm-user-role/:id";
    public static CM_PROP_LI_GET: string = "cm-user-property/:id";
}