<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Payment Mode Mapping</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item"><a><i class="fa fa-hotel"></i>
                            Property List</a></li>
                    <li class="breadcrumb-item active"><i class="fa fa-address-card"></i> Payment Mode Mapping</li>
                </ol>
            </div>
        </div>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="card">
            <no-data [data]="channelManagers" message="Add channel mannager first"></no-data>
            <form [formGroup]="paymentModeForm" (ngSubmit)="saveNewMarketSegmentMapping()">
                <div class="card-body">
                    <ngb-accordion [closeOthers]="true" activeIds="static-1">
                        <ngb-panel *ngFor="let channelManager of channelManagers; index as i"
                            title="{{channelManager.name}}" id="{{ i == 0 ? 'static-1' : i }}">
                            <ng-template ngbPanelContent>
                                <table id="dataTable" class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th class="text-center" scope="col">S.N.</th>
                                            <th scope="col">Channel Manager Payment Mode ID</th>
                                            <th scope="col">Channel Manager Payment Mode Name</th>
                                            <th scope="col">Property Payment Mode Id</th>
                                            <th scope="col">Property Payment Mode Name</th>
                                            <th class="text-center" scope="col">Is Default</th>
                                            <th class="text-center" scope="col">Status</th>
                                            <th class="text-center" scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr
                                            *ngIf="channelManager.paymentModeMapping == null || channelManager.paymentModeMapping.length == 0">
                                            <td colspan="7">
                                                <h4 class="text-center p-4">No Data</h4>
                                            </td>
                                        </tr>

                                        <tr *ngFor="let paymentModeMap of channelManager.paymentModeMapping; index as j">
                                            <th class="text-center" scope="row">{{ j + 1 }}</th>
                                            <td><input type="text" class="form-control" name=""
                                                    value="{{ paymentModeMap.channelManagerPaymentModeId }}" (keyup)="changeThisData(channelManager.channelManagerId, j, 'channelManagerPaymentModeId', $event.target.value)"></td>
                                            <td><input type="text" class="form-control" name=""
                                                    value="{{ paymentModeMap.channelManagerPaymentModeName }}" (keyup)="changeThisData(channelManager.channelManagerId, j, 'channelManagerPaymentModeName', $event.target.value)"></td>
                                            <td><input type="text" class="form-control" name=""
                                                    value="{{ paymentModeMap.propertyPaymentModeId }}" (keyup)="changeThisData(channelManager.channelManagerId, j, 'propertyPaymentModeId', $event.target.value)"></td>
                                            <td><input type="text" class="form-control" name=""
                                                    value="{{ paymentModeMap.propertyPaymentModeName }}" (keyup)="changeThisData(channelManager.channelManagerId, j, 'propertyPaymentModeName', $event.target.value)"></td>
                                            <td class="text-center">
                                                <select class="form-control" name="isDefault"
                                                    [value]="paymentModeMap.isDefault"
                                                    (change)="changeThisData(channelManager.channelManagerId, j, 'isDefault', $event.target.value)">
                                                    <option value="Y">Yes</option>
                                                    <option value="N">No</option>
                                                </select>
                                            </td>
                                            <td class="text-center">
                                                <select class="form-control" name="isActive"
                                                    [value]="paymentModeMap.isActive">
                                                    <option value="Y">Active</option>
                                                    <option value="N">Inactive</option>
                                                </select>
                                            </td>
                                            <td class="text-center">
                                                <div *ngIf="paymentModeMap.isCustom">
                                                    <button type="button" class="btn text-danger pstitle-danger"
                                                        pstitle="Delete meal plan {{ paymentModeMap.propertyPaymentModeName }}"
                                                        (click)="removeItem(i, j)"><i class="fa fa-trash"></i></button>
                                                </div>
                                                <div *ngIf="!paymentModeMap.isCustom">
                                                    <button type="button" class="btn text-secondary pstitle-secondary"
                                                        pstitle="You cannot take any action to {{ paymentModeMap.propertyPaymentModeName }}"><i
                                                            class="fa fa-ban"></i></button>
                                                </div>
                                            </td>
                                        </tr>

                                        <!-- To add new row in the table -->
                                        <ng-container [formGroup]="newPaymentModeForm">
                                            <tr>
                                                <td colspan="8"><label>Add new payment mode mapping for
                                                        {{channelManager.name}}</label></td>
                                            </tr>
                                            <tr>
                                                <td class="text-center">#</td>
                                                <td>
                                                    <input type="text" class="form-control"
                                                        name="channelManagerPaymentModeId"
                                                        formControlName="channelManagerPaymentModeId"
                                                        placeholder="Channel manager payment mode id" />
                                                    <div *ngIf="newPaymentModeForm.controls['channelManagerPaymentModeId'].dirty || newPaymentModeForm.controls['channelManagerPaymentModeId'].touched"
                                                        class="text-danger">
                                                        <span
                                                            *ngIf="newPaymentModeForm.controls['channelManagerPaymentModeId'].errors?.['required']">*Channel
                                                            manager id is required</span>
                                                    </div>
                                                </td>

                                                <td>
                                                    <input type="text" class="form-control"
                                                        name="channelManagerPaymentModeName"
                                                        formControlName="channelManagerPaymentModeName"
                                                        placeholder="Channel manager payment mode name" />
                                                    <div *ngIf="newPaymentModeForm.controls['channelManagerPaymentModeName'].dirty || newPaymentModeForm.controls['channelManagerPaymentModeName'].touched"
                                                        class="text-danger">
                                                        <span
                                                            *ngIf="newPaymentModeForm.controls['channelManagerPaymentModeName'].errors?.['required']">*Channel
                                                            manager payment mode id is required</span>
                                                    </div>
                                                </td>

                                                <td>
                                                    <input type="text" class="form-control" name="propertyPaymentModeId"
                                                        formControlName="propertyPaymentModeId"
                                                        placeholder="Property payment mode id" />
                                                    <div *ngIf="newPaymentModeForm.controls['propertyPaymentModeId'].dirty || newPaymentModeForm.controls['propertyPaymentModeId'].touched"
                                                        class="text-danger">
                                                        <span
                                                            *ngIf="newPaymentModeForm.controls['propertyPaymentModeId'].errors?.['required']">*Property
                                                            payment mode id is required</span>
                                                    </div>
                                                </td>

                                                <td>
                                                    <input type="text" class="form-control" name="propertyPaymentModeName"
                                                        formControlName="propertyPaymentModeName"
                                                        placeholder="Property payment mode name" />
                                                    <div *ngIf="newPaymentModeForm.controls['propertyPaymentModeName'].dirty || newPaymentModeForm.controls['propertyPaymentModeName'].touched"
                                                        class="text-danger">
                                                        <span
                                                            *ngIf="newPaymentModeForm.controls['propertyPaymentModeName'].errors?.['required']">*Property
                                                            payment mode name is required</span>
                                                    </div>
                                                </td>

                                                <td class="text-center">
                                                    <select class="form-control" name="isDefault"
                                                        formControlName="isDefault">
                                                        <option value="Y">Yes</option>
                                                        <option value="N">No</option>
                                                    </select>
                                                </td>

                                                <td class="text-center">
                                                    <select class="form-control" name="isActive"
                                                        formControlName="isActive">
                                                        <option value="Y">Active</option>
                                                        <option value="N">Inactive</option>
                                                    </select>
                                                </td>
                                                <td class="text-center">
                                                    <button class="btn btn-success" type="button"
                                                        (click)="addNewRow(channelManager.channelManagerId, i)">
                                                        <i class="fa fa-plus"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
                <div class="card-footer">
                    <button class="btn btn-success" type="submit" *ngIf="isDataChanged"><i class="fa fa-save"></i>
                        Save</button>
                    <button class="btn btn-danger ml-2" type="button" (click)="onBackPressed()"><i
                            class="fa fa-times"></i>
                        Cancel</button>
                </div>
            </form>
        </div>
    </div>
</section>