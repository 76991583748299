<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6 d-flex">
                <h1>Country List</h1>
                <button class="btn btn-primary ml-4" type="button" (click)="add(content)"><i class="fa fa-plus"></i>
                    Add</button>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item active"><i class="fa fa-list"></i> Country List</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <div class="container-fluid">
                    <div class="row">
                        <div>
                            <button class="btn text-{{ hideSearch ? '' : 'primary' }}" (click)="hideOrShowSearch()"
                                title="{{ hideSearch ? 'Show' : 'Hide' }} search fields"><i
                                    class="fa fa-filter"></i></button>
                        </div>
                        <div class="col">
                            <div class="row" *ngIf="!hideSearch">
                                <label for="table-complete-search" class="col-xs-3 col-sm-auto col-form-label">Search</label>
                                <div class="col-xs-3 col-sm-auto">
                                    <input id="table-complete-search" type="text" placeholder="Search..." class="form-control"
                                        name="searchTerm" [(ngModel)]="pagination.searchTerm" />
                                </div>
        
                                <label for="status" class="col-xs-3 col-sm-auto col-form-label">Status</label>
                                <select class="form-control w-2h" name="status" [(ngModel)]="pagination.isActive">
                                    <option value="">All</option>
                                    <option value="Y">Active</option>
                                    <option value="N">InActive</option>
                                </select>
                                <button class="btn btn-primary ml-4" type="submit" (click)="getCountryList()"><i
                                        class="fa fa-search"></i>
                                    Search</button>
                                <button class="btn btn-secondary ml-2" type="submit" (click)="resetSearch()"><i
                                        class="fa fa-retweet"></i>
                                    Reset</button>
                            </div>
                        </div>
                        <div class="text-right">
                            <app-table-data-exporter tableId="dataTable" fileName="country_list"></app-table-data-exporter>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div *ngIf="countryList != null && countryList.length > 0">
                    <table id="dataTable" class="table table-striped">
                        <thead>
                            <tr>
                                <th class="text-center" scope="col">S.N.</th>
                                <th class="text-center w-2h" scope="col">Code</th>
                                <th scope="col">Name</th>
                                <th class="w-2h" scope="col">Nationality</th>
                                <th class="text-center" scope="col">Status</th>
                                <th class="text-center" scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let country of countryList; index as i">
                                <th class="text-center" scope="row">{{ startingSn + i }})</th>
                                <td class="text-center w-1hp">{{ country.countryCode }}</td>
                                <td>{{ country.countryName }}</td>
                                <td>{{ country.nationality }}</td>
                                <td class="text-center text-{{ country.isActive != 'N' ? 'success' : 'danger' }}">
                                    <i class="fa fa-circle"></i> {{ country.isActive != 'N' ? 'Active' :
                                    'InActive' }}
                                </td>
                                <td class="text-center">
                                    <button class="btn btn-outline text-primary pstitle-primary-right" (click)="edit(content, country)"
                                        pstitle="Edit country {{ country.countryName }}"><i
                                            class="fa fa-edit text-primary p-1"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="d-flex justify-content-between p-2">
                        <ngb-pagination [collectionSize]="paginationRes.totalRow" [maxSize]="5"
                            [(page)]="pagination.pageNumber" (pageChange)="onPageChange($event)"
                            [pageSize]="pagination.pageSize" [boundaryLinks]="true">
                        </ngb-pagination>

                        <select class="form-control form-select" style="width: auto" name="pageSize"
                            [(ngModel)]="pagination.pageSize" (ngModelChange)="getCountryList()">
                            <option [ngValue]="5">5</option>
                            <option [ngValue]="10">10</option>
                            <option [ngValue]="50">50</option>
                            <option [ngValue]="100">100</option>
                        </select>
                    </div>
                </div>
                
                <div *ngIf="countryList == null || countryList.length == 0">
                    <h4 class="text-center p-4">No Data</h4>
                </div>
            </div>
        </div>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Country</h4>
        <i class="fa fa-times fa-2x" (click)="modal.dismiss('Cross click')"></i>
    </div>
    <form [formGroup]="countryForm" (ngSubmit)="saveCountry()">
        <div class="modal-body">
            <div class="form-group row">
                <input type="hidden" formControlName="countryId">
                <div class="col-sm-12">
                    <label for="countryName">Name</label>
                    <input type="text" class="form-control" placeholder="Name" formControlName="countryName">
                    <div *ngIf="countryForm.controls['countryName'].dirty && countryForm.controls['countryName'].touched"
                        class="text-danger">
                        <span *ngIf="countryForm.controls['countryName'].errors?.['required']">*Country name is required</span>
                    </div>
                </div>
                <div class="col-sm-12 mt-2">
                    <label for="countryCode">Code</label>
                    <input type="text" class="form-control" placeholder="Country Code" formControlName="countryCode">
                    <div *ngIf="countryForm.controls['countryCode'].dirty && countryForm.controls['countryCode'].touched"
                        class="text-danger">
                        <span *ngIf="countryForm.controls['countryCode'].errors?.['required']">*Country code is required</span>
                    </div>
                </div>
                <div class="col-sm-12 mt-2">
                    <label for="nationality">Nationality</label>
                    <input type="text" class="form-control" placeholder="Nationality" formControlName="nationality">
                    <div *ngIf="countryForm.controls['nationality'].dirty && countryForm.controls['nationality'].touched"
                        class="text-danger">
                        <span *ngIf="countryForm.controls['nationality'].errors?.['required']">*Nationality is required</span>
                    </div>
                </div>
                <div class="col-sm-12 mt-2">
                    <label for="isActive">Is Active</label>
                    <select class="form-control" placeholder="Is Active" formControlName="isActive">
                        <option value="">-- Select --</option>
                        <option value="Y">Yes</option>
                        <option value="N">No</option>
                    </select>
                    <div *ngIf="countryForm.controls['isActive'].dirty || countryForm.controls['isActive'].touched"
                        class="text-danger">
                        <span *ngIf="countryForm.controls['isActive'].errors?.['required']">*Active status is required</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-success"><i class="fa fa-save"></i> Save</button>
            <button type="button" class="btn btn-danger" (click)="modal.close('Cross click')"><i class="fa fa-times"></i> Cancel</button>
        </div>
    </form>
</ng-template>