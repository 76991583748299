import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'psmat-textarea',
  templateUrl: './psmat-textarea.component.html',
  styleUrls: ['./psmat-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PSMatTextareaComponent),
      multi: true
    }
  ]
})
export class PSMatTextareaComponent implements OnInit, ControlValueAccessor {
  @Input() controlName: string;
  @Input() label: string;
  @Input() rows: number;
  @Input() maxlength: number;
  @Input() required: boolean;
  @Input() showRequiredMsg: boolean = true;
  @Input() readonly: boolean;
  @Input() isDisabled: boolean;
  @Input() parentForm: UntypedFormGroup;
  hasValue: boolean = false;
  value: any;
  onChange: any = () => {};
  onTouched: any = () => {};

  constructor() {}

  ngOnInit(): void {
    if (this.controlName && this.parentForm) {
      const formControl = this.parentForm.get(this.controlName);
      if (formControl && formControl.value) {
        this.value = formControl.value;
        this.hasValue = true;
      } else {
        this.hasValue = this.required;
      }
    }
  }
  

  writeValue(value: any): void {
    this.value = value;
    this.hasValue = !!value;
    this.onChange(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    if (this.required) {
      this.onTouched = fn;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  
  onInput(event: any) {
    const inputValue = event.target.value;
    this.hasValue = inputValue.length > 0 || this.required;
    this.value = inputValue;
    this.onChange(inputValue);
    this.onTouched();
  }  
}