import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppService } from '@services/app.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private appService: AppService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let apiToken = "";
    const tempToken = this.appService.tempToken;
    if(tempToken != null){ apiToken = tempToken; } else { apiToken = this.appService.token; }
    request = request.clone({
      setHeaders: {
        AccessToken: `${apiToken}`,
      },
    });
    return next.handle(request);
  }
}
