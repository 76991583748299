<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6 d-flex">
                <h1>Rent Status</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item active"><i class="fa fa-list"></i> Rent Status</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <div class="row">

                                <div class="col-sm-2">
                                    <div class="ps-mat-input">
                                        <select id="rent_collector_id" name="rent_collector_id"
                                            [(ngModel)]="rentStatusFilter.rent_collector_id">
                                            <option value="0">All</option>
                                            <option *ngFor="let owner of ownerList" [value]="owner.id">{{ owner.name }}
                                            </option>
                                        </select>
                                        <label for="rent_collector_id">Unit Owner</label>
                                    </div>
                                </div>

                                <div class="col-sm-2">
                                    <div class="ps-mat-input">
                                        <select id="tenant_id" name="tenant_id" [(ngModel)]="rentStatusFilter.tenant_id" (change)="onDebtorSelected($event)">
                                            <option value="0">All</option>
                                            <option *ngFor="let tenant of tenantList" [value]="tenant.id">{{ tenant.name
                                                }}</option>
                                        </select>
                                        <label for="tenant_id">Debtor</label>
                                    </div>
                                </div>

                                <div class="col-sm-2">
                                    <div class="ps-mat-input">
                                        <select id="rent_info_id" name="rent_info_id" [(ngModel)]="rentStatusFilter.rent_info_id">
                                            <option value="0">All</option>
                                            <option *ngFor="let rentInfo of rentInfoList" [value]="rentInfo.id">{{ rentInfo.unit_name }}</option>
                                        </select>
                                        <label for="rent_info_id">Room</label>
                                    </div>
                                </div>

                                <div class="col-sm-2">
                                    <psmat-input type="date" [value]="currentDate" (change)="onFieldValueChange('start_date', $event)" label="Start Date"></psmat-input>
                                </div>

                                <div class="col-sm-2">
                                    <psmat-input type="date" [value]="currentDate" (change)="onFieldValueChange('end_date', $event)" label="End Date"></psmat-input>
                                </div>


                                <button class="btn btn-primary btn-sm ml-4" type="submit"
                                    (click)="loadLedgers()"><i class="fa fa-search"></i>
                                    Search</button>
                                <button class="btn btn-secondary btn-sm ml-2" type="submit" (click)="resetSearch()"><i
                                        class="fa fa-retweet"></i>
                                    Reset</button>
                            </div>
                        </div>
                        <div class="text-right">
                            <app-table-data-exporter tableId="dataTable"
                                fileName="rent_status"></app-table-data-exporter>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body table-responsive p-0 tbl-h">
                <div *ngIf="rentStatusList != null && rentStatusList.length > 0">
                    <table id="dataTable" class="table table-bordered table-head-fixed tbl-sm text-nowrap">
                        <thead>
                            <tr>
                                <th class="text-center w-5p" scope="col">S.N.</th>
                                <th class="w-1h5p" scope="col">Month</th>
                                <th scope="col">Debtor</th>
                                <th scope="col">Rent Collector</th>
                                <th scope="col">Unit</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Email</th>
                                <th class="text-center w-1h5p" scope="col">Rent Amount</th>
                                <th class="text-center w-1h5p" scope="col">Received Amount</th>
                                <th class="text-center w-1h5p" scope="col">Balance Amount</th>
                                <th class="text-center w-1h5p" scope="col">Rent Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let rentStatus of rentStatusList; index as i">
                                <td class="text-center">{{ startingSn + i }}</td>
                                <td scope="row">{{ rentStatus.np_month }}</td>
                                <td scope="row">{{ rentStatus.tenant_name }}</td>
                                <td scope="row">{{ rentStatus.rent_collector_name }}</td>
                                <td scope="row">{{ rentStatus.unit_name }}</td>
                                <td scope="row">{{ rentStatus.tenant_contact }}</td>
                                <td scope="row">{{ rentStatus.tenant_email }}</td>
                                <td class="text-right">{{ rentStatus.total }}</td>
                                <td class="text-right">{{ rentStatus.matched_amount }}</td>
                                <td class="text-right">{{ rentStatus.match_remain_amount }}</td>
                                
                                <td class="text-center text-{{ rentStatus.matched_amount > 0 ? (rentStatus.match_remain_amount == 0 ? 'success' : 'primary') : 'danger' }}">
                                    <i class="fa fa-{{ rentStatus.matched_amount > 0 ? (rentStatus.match_remain_amount == 0 ? 'calendar-check' : 'hourglass-half') : 'hourglass-start' }}"></i> 
                                    {{ rentStatus.matched_amount > 0 ? (rentStatus.match_remain_amount == 0 ? 'Paid' : 'Partially Paid') : 'Pending' }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="7" class="text-right"><strong>Total: </strong></td>
                                <td class="text-right">NRS.{{billTotal}}</td>
                                <td class="text-right">NRS.{{receiveTotal}}</td>
                                <td class="text-right">NRS.{{balanceTotal}}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="rentStatusList == null || rentStatusList.length == 0">
                    <h4 class="text-center p-4">No Data</h4>
                </div>
            </div>
        </div>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->