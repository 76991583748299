export class Pattern {
    static PASSWORD = /^(?=\D*\d)(?=[^a-z]*[a-z])(?=.*[$@$!%*?&])(?=[^A-Z]*[A-Z]).{8,30}$/;
    static NUM = /\d+/g;
    static UC = /[A-Z]/;
    static LC = /[a-z]/;
    static SPACE = /\s/g;
    static SPECIAL = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    static EMAIL = /^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$/;
    static USERNAME = /^[a-z0-9_.]+$/;

    public static checkPattern(value: string, pattern: any): boolean {
        return value != null ? value.match(pattern) ? true : false : false;
    }
}


export function checkPattern(value: string, pattern: string):boolean{
    return value != null ? value.match(pattern) ? true : false : false;
}