<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6 d-flex">
                <h1>Email Template List</h1>
                <button class="btn btn-primary ml-4" type="button" [routerLink]="['/email-template-create']"><i class="fa fa-plus"></i>
                    Add</button>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item active"><i class="fa fa-list"></i> Email Template List</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <div class="container-fluid">
                    <div class="row">
                        <div>
                            <button class="btn text-{{ hideSearch ? '' : 'primary' }}" (click)="hideOrShowSearch()"
                                title="{{ hideSearch ? 'Show' : 'Hide' }} search fields"><i
                                    class="fa fa-filter"></i></button>
                        </div>
                        <div class="col">
                            <div class="row" *ngIf="!hideSearch">
                                <label for="table-complete-search" class="col-xs-3 col-sm-auto col-form-label">Search</label>
                                <div class="col-xs-3 col-sm-auto">
                                    <input id="table-complete-search" type="text" placeholder="Search..." class="form-control"
                                        name="searchTerm" [(ngModel)]="pagination.searchTerm" />
                                </div>
        
                                <label for="status" class="col-xs-3 col-sm-auto col-form-label">Status</label>
                                <select class="form-control w-2h" name="status" [(ngModel)]="pagination.isActive">
                                    <option value="">All</option>
                                    <option value="Y">Active</option>
                                    <option value="N">InActive</option>
                                </select>
                                <button class="btn btn-primary ml-4" type="submit" (click)="getEmailTemplageList()"><i
                                        class="fa fa-search"></i>
                                    Search</button>
                                <button class="btn btn-secondary ml-2" type="submit" (click)="resetSearch()"><i
                                        class="fa fa-retweet"></i>
                                    Reset</button>
                                <!-- <span class="col col-form-label" *ngIf="service.loading$ | async">Loading...</span> -->
        
                            </div>
                        </div>
                        <div class="text-right">
                            <app-table-data-exporter tableId="dataTable" fileName="email-template"></app-table-data-exporter>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div *ngIf="emailTemplates != null && emailTemplates.length > 0">
                    <table id="dataTable" class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">S.N.</th>
                                <th scope="col">Template Name</th>
                                <th scope="col">Template Code</th>
                                <th scope="col">Email From</th>
                                <th scope="col">Display Name</th>
                                <th scope="col">Subject</th>
                                <th class="text-center" scope="col">Is Active</th>
                                <th class="text-center" scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let emailTemplate of emailTemplates; index as i">
                                <th scope="row">{{ emailTemplate.sn }}</th>
                                <td>{{ emailTemplate.templateName }}</td>
                                <td>{{ emailTemplate.templateCode }}</td>
                                <td>{{ emailTemplate.emailFrom }}</td>
                                <td>{{ emailTemplate.displayName }}</td>
                                <td>{{ emailTemplate.subject }}</td>
                                <td class="text-center text-{{ emailTemplate.isActive != 'N' ? 'success' : 'danger' }}">
                                    <i class="fa fa-circle"></i> {{ emailTemplate.isActive != 'N' ? 'Active' :
                                    'InActive' }}
                                </td>
                                <td class="text-center">
                                    <a [routerLink]="['/email-template-edit', emailTemplate.emailTemplateId]"><button
                                            type="button" class="btn btn-outline text-primary pstitle-primary-right"
                                            pstitle="Edit {{ emailTemplate.templateName }}'s details"><i
                                                class="fa fa-edit"></i></button></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="d-flex justify-content-between p-2">
                        <ngb-pagination [collectionSize]="paginationRes.totalRow" [maxSize]="5"
                            [(page)]="pagination.pageNumber" (pageChange)="onPageChange($event)"
                            [pageSize]="pagination.pageSize" [boundaryLinks]="true">
                        </ngb-pagination>

                        <select class="form-control form-select" style="width: auto" name="pageSize"
                            [(ngModel)]="pagination.pageSize" (ngModelChange)="getEmailTemplageList()">
                            <option [ngValue]="5">5</option>
                            <option [ngValue]="10">10</option>
                            <option [ngValue]="50">50</option>
                            <option [ngValue]="100">100</option>
                        </select>
                    </div>
                </div>
                
                <div *ngIf="emailTemplates == null || emailTemplates.length == 0">
                    <h4 class="text-center p-4">No Data</h4>
                </div>
            </div>
        </div>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->