export class ReqModelBillMatch {
    tenant_id: number;
    monthly_posting_id: number;
    received_rent_id: number;
    matched_amount: number;

    constructor(tenant_id: number, monthly_posting_id: number, received_rent_id: number, matched_amount: number) {
        if (tenant_id != null) { this.tenant_id = tenant_id; }
        if (monthly_posting_id != null) { this.monthly_posting_id = monthly_posting_id; }
        if (received_rent_id != null) { this.received_rent_id = received_rent_id; }
        if (matched_amount != null) { this.matched_amount = matched_amount; }
    }
}