<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card of-x-a p-3">
            <div class="card-header">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-6">
                            <h4>All Property <span class="text-gray-600">({{propertyCount}})</span></h4>
                        </div>
                        <div class="col-sm-6">
                            <ol class="flex float-sm-right">
                                <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i> Home</a></li>
                                <li class="breadcrumb-item active"><i class="fa fa-circle"></i> Properties</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <button type="button" class="btn btn-primary mt-3"><i class="fa fa-home"></i> Add Property</button>

                <div class="row mt-4" *ngIf="propertyList != null && propertyList.length > 0">
                    <div class="col-lg-3 col-md-6 col-12" *ngFor="let property of propertyList; index as i">
                        <div class="bg-white shadow-xl rounded-lg overflow-hidden">
                            <div class="bg-cover bg-center h-56 p-4"
                                style="background-image: url('{{property.image}}')">
                            </div>
                            <div class="p-3">
                                <div class="row">
                                    <div class="col-sm-11">
                                        <p class="uppercase tracking-wide text-sm font-bold text-gray-700">{{property.property_name}}</p>
                                    </div>
                                    <div class="col-sm-1">
                                        <div class="flex float-sm-right">
                                            <pf-dropdown hide-arrow>
                                                <div slot="button">
                                                    <a class="nav-link">
                                                        <i class="fas fa-ellipsis-v"></i>
                                                    </a>
                                                </div>
                                                <div slot="menu">
                                                    <div class="dropdown-divider"></div>
                                                    <a routerLink="/property-edit/{{property.id}}"><button class="btn text-primary w-1hper text-sm text-left"><i
                                                        class="fa fa-edit"></i> Edit</button></a>
                                                    <div class="dropdown-divider"></div>
                                                    <button class="btn text-danger w-1hper text-sm text-left"><i
                                                            class="fa fa-trash"></i> Delete</button>
                                                </div>
                                            </pf-dropdown>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <p class="text-gray-700"><i class="fas fa-map-marker"></i> {{property.address ? property.address : "N/A"}}</p>
                                </div>
                                <div class="border p-2 bg-white text-sm rounded-lg flex justify-center">
                                    <div class="flex text-center mr-2">
                                        <i class="fas fa-square mt-1"></i>
                                        <span><span class="text-gray-900 ml-2 mr-2">{{property.bedrooms}}</span> Unit</span>
                                    </div>
                                    <div class="flex text-center ml-2 mr-2">
                                        <i class="fas fa-th-large mt-1"></i>
                                        <span><span class="text-gray-900 ml-2 mr-2">{{property.bathrooms}}</span> Room</span>
                                    </div>
                                    <div class="flex text-center">
                                        <i class="fas fa-check-circle mt-1"></i>
                                        <span><span class="text-gray-900 ml-2 mr-2">{{property.available_units}}</span> Available</span>
                                    </div>
                                </div>
                                <div class="flex items-center pt-2">
                                    <button type="button" class="btn btn-block bg-primary">View Details</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="propertyList == null || propertyList.length == 0">
                    <h4 class="text-center p-4">No Data</h4>
                </div>
            </div>
        </div>
    </div>
    <!-- /.card -->
</section>